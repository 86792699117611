import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, switchMap, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Configuration } from 'app/models/config.model';
import { MessageType, Environment } from '@kiosk/microservice.common.models';
import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { LoggingService } from 'app/services/logging.service';
import { UtilityService } from 'app/services/utility.service';
import { HardwareStatus } from '../../models/hardwareStatus.model';
import { Hardware } from '../../models/hardware.model';
import { PagingBaseService } from '../paging.base.service';
import { UserSessionService } from '../user-session.service';
import { ProjectedAttributes } from 'app/models/projectedAttributes.model';
import { KioskApplication, KioskHardware } from 'app/models/business/classes';
import {
  KioskDetailCommandResponse,
  KioskDetailQueryResponse,
  KioskDetail,
  KioskDetailQuery,
  KioskDetailCommand
} from '@kiosk/microservice.kiosk.models';
import { TranslateService } from '@ngx-translate/core';
import { KioskForm } from 'app/models/kioskForm.model';

@Injectable({
  providedIn: 'root' // need this to be a singleton for caching behavior
})
export class KioskService extends PagingBaseService<KioskDetailQueryResponse> {
  private readonly kioskPageSize: number = 100;

  kioskSubject: BehaviorSubject<KioskDetail[]> = new BehaviorSubject<KioskDetail[]>(null);
  kiosks$: Observable<KioskDetail[]> = this.kioskSubject.asObservable();
  private reloadKiosks: boolean = false;

  constructor(
    protected _config: ConfigurationService,
    protected _logger: LoggingService,
    protected _aws: AWSService,
    private _sanitizer: DomSanitizer,
    private _util: UtilityService,
    private _userSession: UserSessionService,
    private _translate: TranslateService
  ) {
    super(_config, _aws, _logger);

    // get new kiosk data when customer is selected using all projected attributes needed by the portal
    this._userSession.filterByCustomerId$
      .pipe(
        filter(id => id && id !== ''),
        switchMap((customerId: string) =>
          this.getKioskData(
            customerId,
            null,
            null,
            this.kioskPageSize,
            null,
            ProjectedAttributes.KioskSummaryAttributes
          )
        ),
        map((responses: KioskDetailQueryResponse[]) => {
          let kiosks: KioskDetail[] = [];
          responses.forEach(res => kiosks.push(...res.KioskDetails));
          this.kioskSubject.next(kiosks);
        })
      )
      .subscribe();
  }

  /** @function getKioskData
   * - gets array of KioskDetail objects based on customerId
   * - optionally limit return properties using projectedAttributes
   * @param {string} customerId customer id string
   * @param {string[]} projectedAttributes
   * - optional, defaults to null
   * - use to limit properties returned
   * - => ['KioskToken', 'KioskName'] would return type {KioskToken: string, KioskName: string}[]
   * @return {Observable<IKioskDetailResponse[]>} kiosk detail responses array observable
   * */
  getKioskData(
    customerId: string,
    kioskId: string = null,
    groupId: string = null,
    pageSize: number = 100,
    customerName: string = '',
    projectedAttributes: string[] = null
  ): Observable<KioskDetailQueryResponse[]> {
    let kioskData$ = this._config.getConfiguration().pipe(
      take(1),
      switchMap((config: Configuration) => {
        let request = new KioskDetailQuery();
        request.CustomerId = customerId;
        request.Environment = config.Environment;
        request.PageSize = this.kioskPageSize;
        request.CustomerName = customerName;
        request.Page = 1;

        if (kioskId) {
          request.KioskToken = kioskId;
        }

        if (groupId) {
          request.GroupId = groupId;
        }

        if (projectedAttributes) {
          request.ProjectedAttributes = projectedAttributes;
        }

        let lambdaName = config.Lambdas.KioskQuery;
        return this.getData(request, lambdaName, pageSize);
      })
    );
    return kioskData$;
  }

  public needToReloadKiosks(): boolean {
    return this.reloadKiosks;
  }

  public setKioskReloadFlag(value: boolean): void {
    this.reloadKiosks = value;
  }

  /** @function saveKioskData
   * - saves or creates passed in KioskDetail object under specified customer
   * @param {KioskDetail} kiosk new or update KioskDetail object
   * @param {string} customerId id of customer to save kiosk under
   * @param {MessageType} msgType
   * - type of request
   * - options include MessageType.Create or MessageType.Update
   * @returns {Observable<IKioskDetailCommandResponse} Observable of KioskDetailCommandResponse
   */
  saveKioskData(kiosk: KioskDetail, msgType: MessageType): Observable<KioskDetailCommandResponse> {
    this.reloadKiosks = true;

    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let command = new KioskDetailCommand();
        command.MessageType = msgType;
        command.Environment = config.Environment;
        kiosk.ApplicationState = null;
        kiosk.HardwareState = null;
        kiosk.PerformanceCounters = null;
        kiosk.ConnectionState = null;

        command.KioskDetails = [kiosk];

        return this._aws
          .executeLambda(JSON.stringify(command), config.Lambdas.KioskCommand)
          .pipe(map(response => response as KioskDetailCommandResponse));
      })
    );
  }

  /**
   *
   * @param hardwares - The array of Hardwares to parse
   * @returns - a HardwareStatus that lists how many kiosks of each status there are
   */
  public getKioskHardwareStatus(hardwares: KioskHardware[]): HardwareStatus {
    let online = 0,
      offline = 0,
      warning = 0,
      error = 0,
      unknown = 0;
    if (hardwares.length <= 0) {
      return new HardwareStatus(online, offline, warning, error, unknown);
    }

    hardwares.forEach(hardware => {
      switch (hardware.Status.toLowerCase()) {
        case 'online':
          ++online;
          break;
        case 'offline':
          ++offline;
          break;
        case 'warning':
          ++warning;
          break;
        case 'error':
          ++error;
          break;
        default:
          ++unknown;
      }
    });
    return new HardwareStatus(online, offline, warning, error, unknown);
  }

  /**
   *
   * @param hardwares - The array of Hardwares to parse
   * @returns - a HardwareStatus that lists how many kiosks of each status there are
   */
  public getHardwareStatus(hardwares: Hardware[]): HardwareStatus {
    let online = 0,
      offline = 0,
      warning = 0,
      error = 0,
      unknown = 0;
    hardwares.forEach(hardware => {
      switch (hardware.Status.toLowerCase()) {
        case 'online':
          ++online;
          break;
        case 'offline':
          ++offline;
          break;
        case 'warning':
          ++warning;
          break;
        case 'error':
          ++error;
          break;
        default:
          ++unknown;
      }
    });
    return new HardwareStatus(online, offline, warning, error, unknown);
  }

  public getAppStatus(apps: KioskApplication[]): any {
    let running = 0,
      stopped = 0,
      unknown = 0;

    if (!apps || apps.length <= 0) {
      return { Running: running, Stopped: stopped, Unknown: unknown };
    }

    apps.forEach(app => {
      switch (app.Status) {
        case 'Running':
          ++running;
          break;
        case 'Stopped':
          ++stopped;
          break;
        default:
          ++unknown;
      }
    });

    return { Running: running, Stopped: stopped, Unknown: unknown };
  }

  /**
   *
   * @param value - The fully qualified string of the hardware type e.g "Kiosk.Component.Hardware.BillAcceptor.BillAcceptor"
   * @returns - The name of the hardware type e.g. "BillAcceptor"
   */
  public getHardwareType(value: string): string {
    let splitComponent: string[] = value.split('.');

    if (splitComponent && splitComponent.length > 0) {
      let lastElement: string = splitComponent.pop();
      return lastElement;
    } else {
      return value;
    }
  }

  public convertToKioskForm(kiosk: KioskDetail): KioskForm {
    return new KioskForm(kiosk);
  }

  public convertToKioskDetail(form: KioskForm): KioskDetail {
    let detail = new KioskDetail();

    detail.ApplicationState = form.ApplicationState;
    detail.Authorization = form.Authorization;
    detail.CashDispensed = form.CashDispensed;
    detail.CashLevels = form.CashLevels;
    detail.CashReceived = form.CashReceived;
    detail.CertificateStatus = form.CertificateStatus;
    detail.ConnectionState = form.ConnectionState;
    detail.CustomerId = form.Customer;
    detail.Demographics = form.Demographics;
    detail.GroupId = form.GroupId;
    detail.HardwareState = form.HardwareState;
    detail.Inventory = form.Inventory;
    detail.InventoryLastUpdated = form.InventoryLastUpdated;
    detail.KioskDescription = form.Description;
    detail.KioskName = form.KioskName;
    detail.KioskStage = form.Stage;
    detail.KioskToken = form.KioskToken;
    detail.Offset = form.Offset;
    detail.OriginalCashLevels = form.OriginalCashLevels;
    detail.PerformanceCounters = form.PerformanceCounters;
    detail.PolicyName = form.PolicyName;
    detail.CertificateArn = form.CertificateArn;
    detail.SerialNumber = form.SerialNumber;
    detail.TaxRates = form.TaxRates;
    detail.TemplateId = form.Template;

    return detail;
  }

  /** @member statusGridSettings - settings for kiosk even history ng2 smart grids */
  statusGridSettings = {
    actions: false,
    columns: {
      EventType: {
        title: 'Status Change',
        type: 'html',
        editable: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'connected', title: 'connected' },
              { value: 'disconnected', title: 'disconnected' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          // The filter was getting confused between connected and disconnect, so doing a more direct match
          if (cell !== search) {
            return false;
          } else {
            return true;
          }
        },
        valuePrepareFunction: value => {
          let spans = '';

          if (value && value === 'connected') {
            spans += `<span class="badge badge-pill badge-success">${value}</span>&nbsp;`;
          } else {
            spans += `<span class="badge badge-pill badge-danger">${value}</span>&nbsp;`;
          }

          return this._sanitizer.bypassSecurityTrustHtml(`<div class="text-center">${spans}</div>`);
        }
      },
      Timestamp: {
        title: 'Status Change Date',
        type: 'string',
        filter: false,
        editable: 'false',
        sortDirection: 'desc',
        compareFunction: this._util.nonCaseCompare,
        valuePrepareFunction: value => {
          let date = new Date(value);

          return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
        }
      },
      ClientInitiatedDisconnect: {
        title: 'Client Initiated Disconnect',
        type: 'string',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => (value ? 'Yes' : 'No')
      }
    }
  };

  public getStatusGridSettings(): Observable<any> {
    return new Observable(observer => {
      let newStatusGridSettings: any = {};
      Object.assign(newStatusGridSettings, this.statusGridSettings);
      this._translate
        .getTranslation(this._translate.currentLang)
        .pipe(
          take(1),
          map(result => {
            if (result && result.Kiosks && result.Kiosks.KioskApplication) {
              if (result.Kiosks.KioskApplication.EventType) {
                newStatusGridSettings.columns.EventType.title =
                  result.Kiosks.KioskApplication.EventType;
              }
              if (result.Kiosks.KioskApplication.Timestamp) {
                newStatusGridSettings.columns.Timestamp.title =
                  result.Kiosks.KioskApplication.Timestamp;
              }
              if (result.Kiosks.KioskApplication.ClientInitiatedDisconnect) {
                newStatusGridSettings.columns.ClientInitiatedDisconnect.title =
                  result.Kiosks.KioskApplication.ClientInitiatedDisconnect;
              }
            }
            observer.next(newStatusGridSettings);
          })
        )
        .subscribe();
    });
  }

  /** @member hardwareGridSettings - settings for hardware component event history ng2 smart grids */
  hardwareGridSettings = {
    actions: false,
    columns: {
      ConfigurationName: {
        title: 'Hardware Name',
        type: 'string',
        editable: false,
        filter: false,
        sort: false
      },
      Assembly: {
        title: 'Hardware Type',
        type: 'string',
        editable: false,
        filter: false,
        sort: false,
        valuePrepareFunction: this.getHardwareType
      },
      Model: {
        title: 'Model',
        type: 'string',
        editable: 'false',
        filter: false,
        compareFunction: this._util.nonCaseCompare
      },
      FirmwareVersion: {
        title: 'Firmware Version',
        type: 'string',
        editable: 'false',
        filter: false,
        compareFunction: this._util.nonCaseCompare
      },
      Status: {
        title: 'Status',
        type: 'html',
        editable: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'Online', title: 'Online' },
              { value: 'Offline', title: 'Offline' },
              { value: 'Error', title: 'Error' },
              { value: 'Warning', title: 'Warning' }
            ]
          }
        },
        valuePrepareFunction: value => {
          let spans = '';

          if (value && value === 'Online') {
            spans += `<span class="badge badge-pill badge-success">${value}</span>&nbsp;`;
          } else if (value && value === 'Offline') {
            spans += `<span class="badge badge-pill badge-default">${value}</span>&nbsp;`;
          } else if (value && value === 'Warning') {
            spans += `<span class="badge badge-pill badge-warning">${value}</span>&nbsp;`;
          } else {
            spans += `<span class="badge badge-pill badge-danger">${value}</span>&nbsp;`;
          }

          return this._sanitizer.bypassSecurityTrustHtml(`<div class="text-center">${spans}</div>`);
        }
      },
      Timestamp: {
        title: 'Status Change Date',
        type: 'string',
        filter: false,
        editable: 'false',
        sortDirection: 'desc',
        compareFunction: this._util.nonCaseCompare,
        valuePrepareFunction: value => {
          return this._util.unixTimestampToDateString(value);
        }
      }
    }
  };

  public getHardwareGridSettings(): Observable<any> {
    return new Observable(observer => {
      let newHardwareGridSettings: any = {};
      Object.assign(newHardwareGridSettings, this.hardwareGridSettings);
      this._translate
        .getTranslation(this._translate.currentLang)
        .pipe(
          take(1),
          map(result => {
            if (result && result.Kiosks && result.Kiosks.KioskHardware) {
              if (result.Kiosks.KioskHardware.ConfigurationName) {
                newHardwareGridSettings.columns.ConfigurationName.title =
                  result.Kiosks.KioskHardware.ConfigurationName;
              }
              if (result.Kiosks.KioskHardware.Assembly) {
                newHardwareGridSettings.columns.Assembly.title =
                  result.Kiosks.KioskHardware.Assembly;
              }
              if (result.Kiosks.KioskHardware.Model) {
                newHardwareGridSettings.columns.Model.title = result.Kiosks.KioskHardware.Model;
              }
              if (result.Kiosks.KioskHardware.FirmwareVersion) {
                newHardwareGridSettings.columns.FirmwareVersion.title =
                  result.Kiosks.KioskHardware.FirmwareVersion;
              }
              if (result.Kiosks.KioskHardware.Status) {
                newHardwareGridSettings.columns.Status.title = result.Kiosks.KioskHardware.Status;
              }
              if (result.Kiosks.KioskHardware.Timestamp) {
                newHardwareGridSettings.columns.Timestamp.title =
                  result.Kiosks.KioskHardware.Timestamp;
              }
            }
            observer.next(newHardwareGridSettings);
          })
        )
        .subscribe();
    });
  }
}
