import { Injectable } from '@angular/core';
import { CustomerService } from 'app/services/pages/customer.service';
import { LoggingService } from 'app/services/logging.service';
import { Observable } from 'rxjs/Observable';
import { Group, Customer, CustomersCommandResponse } from '@kiosk/microservice.customers.models';
import { MessageType } from '@kiosk/microservice.common.models';
import * as _ from 'lodash';
import { UtilityService } from 'app/services/utility.service';
import { take, switchMap, map } from 'rxjs/operators';
import { of, Subject, merge } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GroupsService {
  constructor(
    private _customerService: CustomerService,
    private _logger: LoggingService,
    private _util: UtilityService,
    private _translateService: TranslateService
  ) { }

  groupSummaryGridSettings: any = {
    actions: false,
    columns: {
      Name: {
        title: 'Name',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      GroupId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => `
                <div class="text-right">
                    <a class='table-detail-btn ion-android-open' title="details" href='#/pages/group/detail/${value}'></a>
                </div>
              `
      }
    }
  };

  public getGridSetting(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(
        result => {
          if (result.translations) {
            result = result.translations;
          }
          let newGridConfig: any = {};
          Object.assign(newGridConfig, this.groupSummaryGridSettings);
          newGridConfig.columns.Name.title = result.Groups.SmartTable.Name;
          subject.next(newGridConfig);
        }, error => {
          subject.error(error);
          subject.complete();
        });

    return subject;
  }

  getGroupsByCustomer(customerId: string): Observable<Group[]> {
    return this._customerService.getCustomerData(customerId, false, null, true).pipe(
      take(1),
      switchMap(customers => {
        if (customers.length > 0) {
          this._logger.debug(`Groups: Successfully found groups for ${customerId}`);
          this._logger.silly(' => ', customers[0].Groups);

          return of(customers[0].Groups);
        } else {
          this._logger.debug(`Groups: No groups found for ${customerId}`);

          return of([]);
        }
      })
    );
  }

  createGroup(customer: Customer, group: Group): Observable<CustomersCommandResponse> {
    this._logger.debug(`Groups: Creating group for customer ${customer.CustomerId}`);
    this._logger.silly(` => `, group);

    return this.createOrUpdateGroup(customer, group, true);
  }

  updateGroup(customer: Customer, group: Group): Observable<CustomersCommandResponse> {
    this._logger.debug(`Groups: Updating group for customer ${customer.CustomerId}`);
    this._logger.silly(` => `, group);

    return this.createOrUpdateGroup(customer, group);
  }

  private createOrUpdateGroup(
    customer: Customer,
    group: Group,
    newGroup: boolean = false
  ): Observable<CustomersCommandResponse> {
    // add or update group
    if (newGroup) {
      customer.Groups.push(group);
    } else {
      let index = customer.Groups.indexOf(_.find(customer.Groups, { GroupId: group.GroupId }));
      customer.Groups[index] = group;
    }

    return this._customerService.createOrUpdateCustomer(customer, MessageType.Update).pipe(
      take(1),
      switchMap(commandResponse => {
        if (commandResponse.Exceptions.length > 0) {
          this._logger.err(
            `Groups: Error ${newGroup ? 'Creating' : 'Updating'} group for customer ${
            customer.CustomerId
            }`,
            commandResponse.Exceptions
          );

          return of();
        } else {
          this._logger.debug(
            `Groups: Successfully ${newGroup ? 'Created' : 'Updated'} group for customer ${
            customer.CustomerId
            }`
          );

          return of(commandResponse);
        }
      })
    );
  }
}
