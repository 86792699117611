import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AsyncSubject } from 'rxjs/AsyncSubject';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from 'aws-sdk';
import { ConfigurationService } from 'app/services/configuration.service';
import { AuthCallback, AuthenticationService } from 'app/services/authentication.service';
import { LoggingService } from 'app/services/logging.service'; // commented out logging lines as it is undefined at registration time for some reason
import { NewPasswordUser } from '../models/newPassword.model';
import { Configuration } from '../models/config.model';
import { take, map } from 'rxjs/operators';

@Injectable()
export class RegistrationService {

    constructor(private _configService: ConfigurationService, 
                private _logger: LoggingService,
                private _auth: AuthenticationService) { }

    newPassword(user: NewPasswordUser): Promise<AuthCallback> {
        let authCallback = new AuthCallback();

        let authenticationData = {
            Username: user.username,
            Password: user.existingPassword
        };

        let self = this;
        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                // set credentials to website-clerk
                this.setAWSConfigToClerk(config);

                let authenticationDetails = new AuthenticationDetails(authenticationData);
                let poolData = {
                    UserPoolId: config.UserPoolId,
                    ClientId: config.AppClientId
                };

                let userPool = new CognitoUserPool(poolData);

                let userData = {
                    Username: user.username,
                    Pool: userPool
                };
                let cognitoUser = new CognitoUser(userData);
                cognitoUser.authenticateUser(authenticationDetails, {
                    newPasswordRequired: (userAttributes, requiredAttributes) => {
                        cognitoUser.completeNewPasswordChallenge(user.password, requiredAttributes, {
                            onSuccess: result => {
                                self._logger.debug('Registration: Successfully set new password');
                                authCallback.result = userAttributes;
                                resolve(authCallback);
                            },
                            onFailure: err => {
                                self._logger.err('Registration: Error setting new password', err);
                                reject(err);
                            }
                        });
                    },
                    onSuccess: result => {
                        self._logger.debug('Registration: Successfully authenticated cognito user while setting new password');
                        authCallback.result = result;
                        resolve(authCallback);
                    },
                    onFailure: err => {
                        self._logger.err('Registration: Error authenticating cognito user while setting new password', err);
                        reject(err);
                    }
                });
            });
        });
    }

    public changePassword(user: NewPasswordUser): Promise<AuthCallback> {
        let callback: AuthCallback = new AuthCallback();
        // Get the Cognito the AWS config for settings...
        let provider = new AWS.CognitoIdentityServiceProvider(AWS.config);

        return new Promise((resolve, reject) => {
            this._auth.getCurrentSession().pipe(take(1), map((current: any) => {
               return {
                    AccessToken: current.session.getAccessToken().getJwtToken(),
                    ProposedPassword: user.confirmPassword,
                    PreviousPassword: user.existingPassword
                };
            })).subscribe(passwordData => {
                provider.changePassword(passwordData, (err, data) => {
                    if (err) {
                        console.log('ERROR: ' + err);
                        reject(err);
                    } else if (data) {
                        console.log('SUCCESS: ' + data);
                        callback.result = data;
                        resolve(callback);
                    }
                });
            });
        });
      }

    updateUserEmailPhoneAttrs(username: string, email: string, phone?: string): Observable<AuthCallback> {
        let subject = new AsyncSubject<AuthCallback>();
        let authCallback = new AuthCallback();
        let provider = new AWS.CognitoIdentityServiceProvider();

        this._configService.getConfiguration()
            .subscribe(config => {
                let params = {
                    UserPoolId: config.UserPoolId, /* required */
                    Username: username, /* required */
                    UserAttributes: [
                        {
                            Name: 'email', /* required */
                            Value: email
                        },
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        }
                    ]
                };

                if (phone) {
                    params.UserAttributes.push({
                        Name: 'phone',
                        Value: phone
                    });

                    params.UserAttributes.push({
                        Name: 'phone_verified',
                        Value: 'true'
                    });
                }

                provider.adminUpdateUserAttributes(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error setting user email and phone attributes', err);
                        subject.error(err.code);
                    } else {
                        this._logger.debug('Registration: Successfully set user email and phone attributes');

                        authCallback.result = data;
                        subject.next(authCallback);
                    }

                    subject.complete();
                });
            });

        return subject.asObservable();
    }

    confirmUser(user: NewPasswordUser): Promise<AuthCallback> {
        let authCallback = new AuthCallback();

        let self = this;
        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                // set credentials to website-clerk
                this.setAWSConfigToClerk(config);

                let poolData = {
                    UserPoolId: config.UserPoolId,
                    ClientId: config.AppClientId
                };

                let userPool = new CognitoUserPool(poolData);

                let userData = {
                    Username: user.username,
                    Pool: userPool
                };
                let cognitoUser = new CognitoUser(userData);

                cognitoUser.confirmPassword(user.verificationCode, user.password, {
                    onSuccess: () => {
                        self._logger.debug('Registration: Successfully confirmed cognito user');
                        // console.debug('Registration: Successfully confirmed cognito user');

                        authCallback.message = "UserConfirmed";
                        resolve(authCallback);
                    },
                    onFailure: (err) => {
                        self._logger.err('Registration: Error confirming cognito user', err);
                        // console.error('Registration: Error confirming cognito user', err);
                        reject(err.message);
                    }
                });
            });
        });
    }

    adminCreateUser(username: string, email: string, customerId: string): Promise<AuthCallback> {
        let authCallback = new AuthCallback();
        let provider = new AWS.CognitoIdentityServiceProvider();
        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                let params = {
                    UserPoolId: config.UserPoolId, /* required */
                    Username: username, /* required */
                    DesiredDeliveryMediums: ['EMAIL'],
                    ForceAliasCreation: false,
                    UserAttributes: [
                        {
                            Name: 'email', /* required */
                            Value: email
                        },
                        //Left commented out for now, this was used before but is now causing an issue with forgot password were we haven't verified the email at all on the user
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        },
                        {
                            Name: 'custom:customer_id',
                            Value: customerId
                        }
                    ]
                };

                provider.adminCreateUser(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error creating user', err);
                        // console.error('Registration: Error creating user', err);
                        reject(err.code);
                    }

                    this._logger.debug('Registration: Successfully created user');
                    // console.debug('Registration: Successfully created user');

                    authCallback.result = data;
                    resolve(authCallback);
                });
            });
        });
    }

    public getUserStatus(username: string): Promise<string> {
        let callback = new AuthCallback();

        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {                

                let provider = new AWS.CognitoIdentityServiceProvider();

                let params = {
                    UserPoolId: config.UserPoolId,
                    Username: username
                };

                provider.adminGetUser(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error getting user status', err);
                        reject(err);
                        return;
                    }

                    this._logger.debug('Registration: Successfully got user status');

                    callback.result = data.UserStatus;
                    resolve(data.UserStatus);
                });
            });
        });
    }

    resetPassword(username: string): Promise<AuthCallback> {
        let callback = new AuthCallback();

        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                // set credentials to website-clerk
                this.setAWSConfigToClerk(config);

                let provider = new AWS.CognitoIdentityServiceProvider();

                let params = {
                    UserPoolId: config.UserPoolId,
                    Username: username
                };

                provider.adminResetUserPassword(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error resetting password', err);
                        reject(err);
                        return;
                    }

                    this._logger.debug('Registration: Successfully reset password');

                    callback.result = data;
                    resolve(callback);
                });
            });
        });
    }

    setAWSConfigToClerk(config: Configuration): void {
        AWS.config.update({ region: config.Region, credentials: { accessKeyId: config.ClerkAK, secretAccessKey: config.ClerkSK } });
    }

    // For creating users that are self-registered (Not admin functionality)
    // createUser(user: RegistrationUser, callback: AuthCallback) {
    //     let attributeList = [];
    //     let emailAttribute = {
    //         Name: 'email',
    //         Value: user.email
    //     };

    //     let attributeEmail = new CognitoUserAttribute(emailAttribute);

    //     attributeList.push(attributeEmail);

    //     let poolData = {
    //         UserPoolId : environment.userPoolId,
    //         ClientId: environment.clientId
    //     };
    //     let userPool = new CognitoUserPool(poolData);
    //     let cognitoUser;

    //     userPool.signUp(user.name, user.password, attributeList, null, function (err, result) {
    //         if (err) {
    //             callback.authCallback(err.message, null);
    //         } else {
    //             console.log("UserRegistrationService: registered user is " + result);
    //             callback.authCallback(null, result.user);
    //         }
    //     });
    // }

    adminEnableUser(username: string): Promise<AuthCallback> {
        let callback = new AuthCallback();

        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                let provider = new AWS.CognitoIdentityServiceProvider();

                let params = {
                    UserPoolId: config.UserPoolId,
                    Username: username
                };

                provider.adminEnableUser(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error enabling user', err);
                        reject(err);
                        return;
                    }

                    this._logger.debug('Registration: Successfully enabled user');

                    callback.result = data;
                    resolve(callback);
                });
            });
        });
    }

    adminDisableUser(username: string): Promise<AuthCallback> {
        let callback = new AuthCallback();

        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                let provider = new AWS.CognitoIdentityServiceProvider();

                let params = {
                    UserPoolId: config.UserPoolId,
                    Username: username
                };

                provider.adminDisableUser(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error disabling user', err);
                        reject(err);
                        return;
                    }

                    this._logger.debug('Registration: Successfully disabled user');

                    callback.result = data;
                    resolve(callback);
                });
            });
        });
    }

    resendVerificationEmail(userName: string, email: string, customerId: string): Promise<AuthCallback> {
        let authCallback = new AuthCallback();
        let provider = new AWS.CognitoIdentityServiceProvider();
        let self = this;

        return new Promise((resolve, reject) => {
            self._configService.getConfiguration().subscribe(config => {
                let params = {
                    UserPoolId: config.UserPoolId, /* required */
                    Username: userName, /* required */
                    MessageAction: 'RESEND',
                    DesiredDeliveryMediums: ['EMAIL'],
                    ForceAliasCreation: false,
                    UserAttributes: [
                        {
                            Name: 'email', /* required */
                            Value: email
                        },
                        {
                            Name: 'custom:customer_id',
                            Value: customerId
                        }
                    ]
                };

                provider.adminCreateUser(params, (err, data) => {
                    if (err) {
                        this._logger.err('Registration: Error creating user', err);
                        reject(err.code);
                    }

                    this._logger.debug('Registration: Successfully created user');

                    authCallback.result = data;
                    resolve(authCallback);
                });
            });
        });
    }


}
