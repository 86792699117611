import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'knect-authorization',
    template: `
    <router-outlet></router-outlet>
    `
})
export class AuthComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {

      // TODO - Remove this - nebular isn't loading correctly in this module so need to manually switch the styling for now
      $('#nb-global-spinner').css('display', 'none');
      $('body').addClass('nb-theme-knect');
    }
}