import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
    transform(value: any[], filterKey: string, filterValue: string, deepFilterKey: string, deepFilterValue: string): any[] {
        let results = _.filter(value, [filterKey, filterValue]);

        if (deepFilterKey)
            results = _.filter(results, [deepFilterKey, deepFilterValue]);

        return results;
    }
}