import { Injectable } from '@angular/core';
import {
  KioskDetailEventHistoryQueryResponse,
  KioskDetailEventHistoryQuery
} from '@kiosk/microservice.kiosk.models';
import { Observable } from 'rxjs/Observable';
import { Configuration } from 'app/models/config.model';
import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { LoggingService } from 'app/services/logging.service';
import * as _ from 'lodash';
import { take, switchMap } from 'rxjs/operators';
import { PagingBaseService } from './paging.base.service';

@Injectable()
export class KioskEventService extends PagingBaseService<KioskDetailEventHistoryQueryResponse> {
  private readonly pageSize: number = 100;

  constructor(
    protected _config: ConfigurationService,
    protected _logger: LoggingService,
    protected _aws: AWSService
  ) {
    super(_config, _aws, _logger);
  }

  /**
   * Gets Kiosk Event History Data
   * @param request Kiosk Detail Event History request
   */
  getKioskEventHistory(
    request: KioskDetailEventHistoryQuery
  ): Observable<KioskDetailEventHistoryQueryResponse[]> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap((config: Configuration) => {
        request.Environment = config.Environment;
        let lambdaName = config.Lambdas.KioskEventHistoryQuery;
        return this.getData(request, lambdaName, this.pageSize);
      })
    );
  }
}
