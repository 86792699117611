import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { MessageType } from '@kiosk/microservice.common.models';
import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { LoggingService } from 'app/services/logging.service';
import { UtilityService } from 'app/services/utility.service';
import { switchMap, take, map } from 'rxjs/operators';
import { of, Subject, merge } from 'rxjs';
import { KioskTemplate, KioskTemplateQuery, KioskTemplateQueryResponse, KioskTemplateCommand, KioskTemplateCommandResponse } from '@kiosk/microservice.kiosktemplate.models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TemplateService {
  constructor(
    private _aws: AWSService,
    private _configService: ConfigurationService,
    private _logger: LoggingService,
    private _util: UtilityService,
    private _sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) { }

  templateGridSettings: any = {
    actions: false,
    columns: {
      TemplateName: {
        title: 'Template Name',
        type: 'string',
        editable: false,
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      TemplateId: {
        title: '',
        type: 'html',
        filter: false,
        editable: false,
        sort: false,
        valuePrepareFunction: (value, row) =>
          this._sanitizer.bypassSecurityTrustHtml(`
            <div class="text-right" *ngIf="userPermissions?.has_template_view">
              <a class="table-detail-btn ion-android-open" title="details" href="#/pages/template/detail/${value}/${
            row.CustomerId
            }"></a>
            </div>
          `)
      }
    }
  };

  public getTemplateGridSettings(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations;
        }
        let newGridConfig: any = {};
        Object.assign(newGridConfig, this.templateGridSettings);
        if (result && result.Templates && result.Templates.TemplateSummary) {
          if (result.Templates.TemplateSummary.TemplateName) {
            newGridConfig.columns.TemplateName.title = result.Templates.TemplateSummary.TemplateName;
          }
        }
        subject.next(newGridConfig);
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;
  }

  public getTemplateList(customerId: string): Observable<KioskTemplate[]> {
    return this.getTemplateOrListOfTemplates(null, customerId, true) as Observable<KioskTemplate[]>;
  }

  public getTemplate(templateId: string, customerId: string): Observable<KioskTemplate> {
    return this.getTemplateOrListOfTemplates(templateId, customerId, false) as Observable<KioskTemplate>;
  }

  private getTemplateOrListOfTemplates(templateId: string, customerId: string,
    isList: boolean): Observable<KioskTemplate | KioskTemplate[]> {
    let request = new KioskTemplateQuery();
    request.TemplateId = templateId;
    request.CustomerId = customerId;

    return this._configService.getConfiguration().pipe(
      switchMap(config => {
        request.Environment = config.Environment;

        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.KioskTemplateQuery);
      }),
      switchMap(response => {
        let data = response as KioskTemplateQueryResponse;

        if (data.Exceptions.length > 0) {
          this._logger.err('Template: Error getting template data', data.Exceptions);
          return of(isList ? [] : null);
        } else {
          let returnValue = isList ? data.KioskTemplates : data.KioskTemplates[0];

          this._logger.debug('Template: Successfully retrieved template data');
          this._logger.silly(' => ', returnValue);

          return of(returnValue);
        }
      }
      ));
  }

  getTemplateSelectOptions(customerId: string): Observable<{ id: string; name: string }[]> {
    return this.getTemplateList(customerId).pipe(
      take(1),
      switchMap(templates => {
        let options: { id: string; name: string }[] = [];
        for (const template of templates) {
          options.push({
            id: template.TemplateId,
            name: template.TemplateName
          });
        }

        options.sort((a, b) => this._util.nonCaseStringSort(a.name, b.name));
        return of(options);
      }
      ));
  }

  saveTemplate(template: KioskTemplate, newTemplate: boolean = false): Observable<boolean> {
    let request = new KioskTemplateCommand();

    return this._configService.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        request.KioskTemplate = template;
        request.Environment = config.Environment;

        if (newTemplate) {
          request.MessageType = MessageType.Create;
        }

        return this._aws
          .executeLambda(JSON.stringify(request), config.Lambdas.KioskTemplateCommand);
      }),
      switchMap(response => {
        let data = response as KioskTemplateCommandResponse;

        if (data.Exceptions.length > 0) {
          this._logger.err('Template: Error saving template', data.Exceptions);
          return of();
        } else {
          this._logger.debug('Template: Successfully saved template');
          this._logger.silly(' => ', data);

          return of(data.UpdateSuccess);
        }
      }
      ));
  }
}
