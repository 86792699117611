import { Component, OnInit } from '@angular/core';
import { NewPasswordUser } from '../../../models/newPassword.model';
import { Router, ActivatedRoute } from "@angular/router";
import { RegistrationService } from 'app/services/registration.service';

@Component({
  selector: 'knect-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  user: NewPasswordUser = new NewPasswordUser();
  errors: string = '';
  saveInProcess: boolean = false;
  newPassSuccess: boolean = false;
  specialCharacters: string = "(^ $ * . [ ] { } ( ) ? - \" ! @ # % / \ , >  ' : ; | _ ~ `)";
  isChangePassword: boolean = false;

  constructor(
    private _registration: RegistrationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      let user = params['user'];
      let isChange = params['change'];
       if (user != null) {
         this.user.username = user.toString();
       }
       if(isChange) {
         this.isChangePassword = true;
       }
    });
  }

  registerUser(): void {
    this.saveInProcess = true;

    if (this.isChangePassword) {
      this._registration.changePassword(this.user)
      .then((result) => {
        this.saveInProcess = false;
        this.newPassSuccess = true;

        this.authCallback();
      })
      .catch(err => {
        this.saveInProcess = false;
        this.errors = err.toString();
      });
    } else {
      this._registration.newPassword(this.user)
      .then((result) => {
        this.saveInProcess = false;
        this.newPassSuccess = true;

        this.authCallback();
      })
      .catch(err => {
        this.saveInProcess = false;

        this.errors = err.toString();
      });
    }
  }

  authCallback(): void {
    this._router.navigate(['./pages/dashboard']);
  }
}
