import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { Observable } from 'rxjs/Observable';
import { AsyncSubject } from 'rxjs/AsyncSubject';
import { Subject } from 'rxjs/Subject';
import { ConfigurationService } from './configuration.service';
import * as AWS from 'aws-sdk';
import { Configuration } from 'app/models/config.model';
import { Message } from '@kiosk/microservice.common.models';
import { take } from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable()
export class AWSService {
  constructor(
    private _config: ConfigurationService,
    private _router: Router,
    private _auth: AuthenticationService,
    private _logger: LoggingService
  ) {}

  executeLambda(payload: string, lambdaName: string): Observable<Message> {
    const callback = new Subject<Message>();

    const lambdaParams: AWS.Lambda.InvocationRequest = {
      FunctionName: lambdaName,
      InvocationType: 'RequestResponse',
      Payload: payload,
      LogType: 'None'
    };

    this._config
      .getConfiguration()
      .pipe(take(1))
      .subscribe((config: Configuration) => {
        const lambda = new AWS.Lambda({ region: config.Region });

        lambda.invoke(lambdaParams, (error, data) => {
          if (error) {
            if (error.code === 'CredentialsError') {
              this._auth.logout();
              this._router.navigate(['./auth']);
            }

            callback.error(error);
          } else if(!data) {
            this._logger.err('Error on return data. data object returned is null');
            callback.error('Error on return data. data object returned is null');
            return;
          } else if (data['FunctionError']) {
            this._logger.err('Error on return data. Contained an function error message: ' + data['FunctionError']);
            callback.error(data['Payload']);
          }         
         
          // Works for lambda call's where no object is returned, just a task
          if(data['Payload'] === '') {
            data['Payload'] = '{}';
          }

          let msgResponse = JSON.parse(data['Payload'] as string) as Message;

          if (msgResponse.Exceptions && msgResponse.Exceptions.length > 0)
            callback.error(msgResponse.Exceptions);
          else {
            callback.next(msgResponse);
            callback.complete();
          }
        });
      });

    return callback.asObservable();
  }

  s3ListContents(
    bucket: string,
    continuationToken?: string
  ): Observable<AWS.S3.ListObjectsV2Output> {
    let s3 = new AWS.S3(),
      request = { Bucket: bucket } as AWS.S3.ListObjectsV2Request,
      subject = new AsyncSubject<AWS.S3.ListObjectsV2Output>();

    if (continuationToken) {
      request.ContinuationToken = continuationToken;
    }

    s3.listObjectsV2(request, (err, data) => {
      if (err) {
        subject.error(err);
      } else {
        subject.next(data);
      }

      subject.complete();
    });

    return subject.asObservable();
  }

  s3GetObject(bucket: string, key: string): Observable<AWS.S3.GetObjectOutput> {
    let s3 = new AWS.S3(),
      request = { Bucket: bucket, Key: key } as AWS.S3.GetObjectRequest,
      subject = new AsyncSubject<AWS.S3.GetObjectOutput>();

    s3.getObject(request, (err, data) => {
      if (err) {
        subject.error(err);
      } else {
        subject.next(data);
      }

      subject.complete();
    });

    return subject.asObservable();
  }

  // Keeping in case we need to reach dynamo someday, for now all traffic goes to lambdas - pja 10/31/17
  // getDynamoItem(params) {
  //     const dynamoCallback = new AsyncSubject<{data: any, error: any}>();

  //     const dynamo = new AWS.DynamoDB({ region: configuration.awsRegion });

  //     dynamo.getItem(params, (error: any, data: Object) => {
  //         dynamoCallback.next({ data, error });
  //         dynamoCallback.complete();
  //      });

  //     return dynamoCallback.asObservable();
  // }
}
