import { Injectable } from '@angular/core';
import { AWSService } from './aws.service';
import { ConfigurationService } from './configuration.service';
import { LoggingService } from './logging.service';
import { Observable, of, forkJoin, merge } from 'rxjs';
import { take, map, switchMap, filter } from 'rxjs/operators';
import { Message, Query } from '@kiosk/microservice.common.models';

@Injectable()
export abstract class PagingBaseService<TResponse extends Message> {
  private readonly defaultPageSize: number = 100;

  constructor(
    protected _config: ConfigurationService,
    protected _aws: AWSService,
    protected _logger: LoggingService
  ) {}

  public getData(request: Query, lambdaName: string, pageSize: number): Observable<TResponse[]> {
    request.PageSize = pageSize ? pageSize : this.defaultPageSize;
    request.Page = request.Page < 1 ? 1 : request.Page;

    let singlePageResponse$ = this._aws
      .executeLambda(JSON.stringify(request), lambdaName)
      .pipe(
        take(1),
        filter(response => {
          return (response as any).TotalRecords <= pageSize; }),
        switchMap(response => {
           return of([response as TResponse]); }
           ));

    let multiPageResponse$ = this._aws
      .executeLambda(JSON.stringify(request), lambdaName)
      .pipe(
        take(1),
        filter(response => {
          return (response as any).TotalRecords > pageSize; }
        ),
        switchMap(response => {
          let totalPages = [];

          // Start at page 2 since we already page the first page of data, build an array of pages to iterate over;
          for (let n = 2; n <= (response as any).NumberOfPages; n++) {
            totalPages.push(n);
          }

          // Create a list of observers that will execute to load data in the background
          let dataPages: Observable<TResponse>[] = new Array<Observable<TResponse>>();
          dataPages.push(of(response as TResponse));

          for (let page of totalPages) {
            // need any cast because Page doesn't exist on Query
            (request as any).Page = page;

            // This is the equivalent of creating a list of Tasks that we will process below
            dataPages.push(
              this._aws
                .executeLambda(JSON.stringify(request), lambdaName)
                .pipe(map(innerRes => innerRes as TResponse))
            );
          }

          // Execute the page queries simultaneously and return when finished.
          // You could also do these sequentially with a concat, however this process runs significantly slower.
          // This might be useful in the future if we want to gradually load data and adjust the callers to load in the background
          return forkJoin(...dataPages)
            .pipe(take(1),
            switchMap(
              responses => {
                this._logger.debug(`PagingBaseService: Successfully retrieved data`);

                return of(responses);
              }
            ));
        }));

     return merge(singlePageResponse$, multiPageResponse$);
  }

  
}
