import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { Configuration } from '../models/config.model';
import { ConfigurationService } from 'app/services/configuration.service';
import { User } from '@kiosk/microservice.user.models';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import * as AWS from "aws-sdk";
import { CognitoIdentityCredentials } from 'aws-sdk/lib/credentials/cognito_identity_credentials';
import { LoggingService } from 'app/services/logging.service';
import { AdminUpdateUserAttributesRequest } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { take, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

export class AuthCallback {
    message: any;
    result: any;
}

@Injectable()
export class AuthenticationService {
    currentUser: CognitoUserSession;
    cognitoCreds: AWS.CognitoIdentityCredentials;
    AwsCredsSet: boolean = false;
    private configuration: Configuration;

    loggingOut$: Subject<boolean> = new Subject<boolean>();

    constructor(private _config: ConfigurationService,
                private _logger: LoggingService) { }

    /**@function getCurrentUser - gets currently authenticated AWS Cognito user
     * @returns {Observable<CognitoUser>} Observable of CognitoUser
     */
    getCurrentUser(): Observable<CognitoUser> {
      const self = this;
      return Observable.create(observer => {
        self._config.getConfiguration()
          .pipe(
            take(1),
            map(config => {
              self.configuration = config;
              return new CognitoUserPool(
                {UserPoolId: config.UserPoolId, ClientId: config.AppClientId}).getCurrentUser();
          })).subscribe((currentUser: CognitoUser) => {
            if (currentUser) {
              self._logger.debug(`Authentication: user ${currentUser.getUsername()} retrieved`);
              observer.next(currentUser);
            } else {
              self._logger.warn('Error loading current user.');
              observer.next(null);
            }
          });
        });
    }

    /**@function getCurrentSession - gets current cognito session
     * @returns {Observable<{session: any, user: CognitoUser}>} observable containing Cognito session and Cognito user
     */
    getCurrentSession(): Observable<{session: any, user: CognitoUser}> {
      const self = this;

      return Observable.create(observer => {
        self.getCurrentUser()
          .pipe(take(1))
          .subscribe(currentUser => {
            
            // When switching connections and windows, the current user and session can get lost and return a null object. When that happens, error out so we get the login page...
            if(!currentUser) {
              observer.error("No current user was found");
              return;
            }

            currentUser.getSession((err, session) => {
              // don't log error during normal session expiration flow
              if (err && err !== 'Error: Access token has expired') {
                self._logger.err('Authentication: Error retrieving current user session', err);
                observer.error(err);
              } else {
                self.buildCognitoCreds(session.getIdToken().getJwtToken(), self.configuration);

                self._logger.debug('Authentication: current user session successfully retrieved');

                observer.next({session: session, user: currentUser});
              }
            });
          });
      });
    }

    /**@function isSesssionValid - checks if current session is still authenticated and valid
     * @returns {Observable<boolean>} Observable of truthy which indicates if session is valid or not
     */
    isSessionValid(): Observable<boolean> {
      const self = this;

      return Observable.create(observer => {
        self.getCurrentSession()
          .pipe(take(1))
          .subscribe(
            result => {
              if (!result.session.isValid()) observer.error(false);

              // checks if we are still authorized for cognito admin functionality
              // (expiration between this and regular cognito logic are different, this accounts for that)
              let provider = new AWS.CognitoIdentityServiceProvider();
              provider.adminUpdateUserAttributes({
                UserPoolId: self.configuration.UserPoolId,
                Username: result.user.getUsername(),
                UserAttributes: []
              } as AdminUpdateUserAttributesRequest, (err, results) => {
                if (err) observer.error(false);
                else observer.next(true);
              });
            },
            err => {
                observer.error(false);
            }
          );
      });
    }

    /**@function getUserAttributes - Gets User Attributes stored in Cognito
     * @returns {Observable<User>} Observable of User object populated with users attributes
     */
    getUserAttributes(): Observable<User> {
      const self = this;
      return Observable.create(observer => {
        self.getCurrentSession()
          .pipe(take(1))
          .subscribe(
            results => {
              if (results.user != null) {
                results.user.getUserAttributes((attrErrs, result) => {
                  if (attrErrs) self._logger.err('Authentication: Error getting user attributes', attrErrs);

                  const userAttrs = new User();
                  const sub = result.filter(x => x.getName() === 'sub')[0],
                        customer = result.filter(x => x.getName() === 'custom:customer_id')[0],
                        email = result.filter(x => x.getValue() === 'email')[0],
                        phone = result.filter(x => x.getValue() === 'phone')[0];

                  if (sub != null) userAttrs.UserId = sub.getValue();
                  if (customer != null) userAttrs.CustomerId = customer.getValue();
                  if (email != null) userAttrs.Email = email.getValue();
                  if (phone != null) userAttrs.Phone = phone.getValue();

                  self._logger.debug('Authentication: Retrieved user attributes successfully');

                  observer.next(userAttrs);
                }
              );
            } else {
              self._logger.err('Authentication: Error getting user attributes. No Users are Logged in');
              observer.error('No Users are Logged in');
            }
          }
        );
      });
    }

    /**@function logout
     * - clears session storage cache
     * - clears Cognito credentials
     * - signs out Cognito user
     */
    logout(): void {
      const currentUser$ = this.getCurrentUser().pipe(take(1));
      const config$ = this._config.getConfiguration().pipe(take(1));
      forkJoin(currentUser$, config$)
        .subscribe(results => {
          const user = results[0];
          const config = results[1];

          if (user) user.signOut();
          localStorage.clear();
          this.loggingOut$.next(true);

          // clear Cognito credentials and set new fresh ones
          const cognitoParams = { IdentityPoolId: config.IdentityPoolId };

          if (this.cognitoCreds) this.cognitoCreds.clearCachedId();
          this.cognitoCreds = new AWS.CognitoIdentityCredentials(cognitoParams);

          AWS.config.credentials = this.cognitoCreds;
        },
        err => {
              this._logger.warn(`Error logging out: ${err}`);
          });

    }

    /**@function setCognitoCreds
     * - sets current cognito creds to the 'cognitoCreds' property of this service
     * - AWS Stores Credentials in many ways, and with TypeScript this means that
     * getting the base credentials we authenticated with from the AWS globals gets really murky,
     * having to get around both class extension and unions. Therefore, we're going to give
     * developers direct access to the raw, unadulterated CognitoIdentityCredentials
     * object at all times.
     * @param {CognitoIdentityCredentials} creds Cognito credentials to use
     */
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials): void {
      AWS.config.update({region: this.configuration.Region, credentials: creds});

      this.cognitoCreds = creds;
      this.AwsCredsSet = true;
    }

    /**@function buildCognitoCreds
     * - This method takes in a raw jwtToken and uses the global AWS config options to build a
     * CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
     * to avoid unnecessary calls to setCognitoCreds.
     *
     * @param {string} idTokenJwt Cognito JwtToken
     * @param {Configuration} config kiosk configuration object
     * @returns {CognitoIdentityCredentials} Cognito identity credentials
     */
    buildCognitoCreds(idTokenJwt: string, config: Configuration): AWS.CognitoIdentityCredentials {
        this.configuration = config;

        const url = 'cognito-idp.' + config.Region + '.amazonaws.com/' + config.UserPoolId;

        const logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;

        const params: CognitoIdentityCredentials.CognitoIdentityOptions = {
            IdentityPoolId: config.IdentityPoolId, /* required */
            Logins: logins
        };

        const creds = new AWS.CognitoIdentityCredentials(params, {});
        this.setCognitoCreds(creds);
        return creds;
    }
}
