import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  catchError,
  takeUntil,
  filter,
  tap,
  switchMap,
  concatMap,
  map,
  take,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import { StateService } from '../../../@core/data/state.service';
import { Subscription, of, Subject } from 'rxjs';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';
import * as _ from 'lodash';

import { cacheSuffixes } from 'app/enums/cacheKeys.enum';
import { KioskService } from 'app/services/pages/kiosk.service';
import { CacheService } from 'app/services/cache.service';
import { UserSessionService } from 'app/services/user-session.service';
import { LoggingService } from 'app/services/logging.service';
import { GroupsService } from 'app/services/pages/groups.service';
import { KioskDetail } from '@kiosk/microservice.kiosk.models';
import { KioskStateMenuItem } from 'app/models/kioskStateMenuItem.model';
import { Group } from '@kiosk/microservice.customers.models';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  templateUrl: './sample.layout.html'
})
export class SampleLayoutComponent implements OnDestroy, OnInit {
  customerId: string = '';

  kioskGroups$: Subject<Group[]> = new Subject<Group[]>();
  kioskStatus$: Subject<KioskStateMenuItem[]> = new Subject<KioskStateMenuItem[]>();
  kioskStatusDisplay: KioskStateMenuItem[] = [];

  subMenu: NbMenuItem[] = [
    {
      title: 'PAGE LEVEL MENU',
      group: true
    },
    {
      title: 'Buttons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/buttons'
    },
    {
      title: 'Grid',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/grid'
    },
    {
      title: 'Icons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/icons'
    },
    {
      title: 'Modals',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/modals'
    },
    {
      title: 'Typography',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/typography'
    },
    {
      title: 'Animated Searches',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/search-fields'
    },
    {
      title: 'Tabs',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/tabs'
    }
  ];
  layout: any = {};
  sidebar: any = {};

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  protected layoutState$: Subscription;
  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;

  userPermissions: any;

  constructor(
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    private _userSession: UserSessionService,
    private _cache: CacheService,
    private _kioskService: KioskService,
    private _logger: LoggingService,
    private _groupService: GroupsService
  ) {
    this.layoutState$ = this.stateService.onLayoutState().subscribe((layout: string) => {
      this.layout = layout;
    });

    this.sidebarState$ = this.stateService.onSidebarState().subscribe((sidebar: string) => {
      this.sidebar = sidebar;
    });

    const isBp = this.bpService.getByName('is');

    this.menuClick$ = this.menuService
      .onItemSelect()
      .pipe(
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20)
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }

  ngOnInit(): void {
    const cachedSiteFilter = this._cache.get(cacheSuffixes.currentCustomerId);
    if (cachedSiteFilter) {
      this._userSession.setSiteFilter(cachedSiteFilter);
    } else {
      this._userSession
        .populateUser()
        .pipe(take(1))
        .subscribe(user => this._userSession.setSiteFilter(user.CustomerId));
    }

    // get all the kiosk display data for the sidebar
    this._userSession
      .getUserPermissions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(permissions => (this.userPermissions = permissions));

    // subscription to display kiosks on sidebar
    this.kioskStatus$.subscribe(stateList =>
      this.kioskStatusDisplay = stateList);

    this._userSession.filterByCustomerId$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(id => id && id !== ''),
        tap(selectedCustomerId => (this.customerId = selectedCustomerId)),
        switchMap(() =>
          this._kioskService.kiosks$
            // if the service call errors default to an empty array for kiosk display
            .pipe(
              catchError(err => {
                this._logger.warn(
                  `Layout: Error getting customer kiosks for ${this.customerId}`,
                  err
                );
                return of([]);
              })
            )
        ),
        // create a list of which kiosks to display in the sidebar; filter out groups with no kiosks
        concatMap((kiosks: KioskDetail[]) => {
          let kioskStateItems: KioskStateMenuItem[] = [];
          return this._groupService.getGroupsByCustomer(this.customerId).pipe(
            map((groups: Group[]) => {
              const groupsWithKiosks = _.filter(groups, (g: Group) =>
                _.find(kiosks, (k: KioskDetail) => k.GroupId === g.GroupId)
              );

              this.kioskGroups$.next(groupsWithKiosks);

              kioskStateItems = _.map(kiosks, (kiosk: KioskDetail) => {
                if (!kiosk.ConnectionState) kiosk.ConnectionState = JSON.stringify({});

                const stateData = new KioskStateMenuItem();
                const awsData = JSON.parse(kiosk.ConnectionState);
                stateData.ClientInitiatedDisconnect = awsData.ClientInitiatedDisconnect
                    ? awsData.ClientInitiatedDisconnect
                    : false;

                stateData.KioskToken = kiosk.KioskToken;
                stateData.KioskName = kiosk.KioskName;
                stateData.State = awsData.EventType ? awsData.EventType : 'never';

                const foundGroup: Group = _.find(groupsWithKiosks, { GroupId: kiosk.GroupId });
                if (foundGroup) {
                  stateData.GroupId = foundGroup.GroupId;
                  stateData.GroupName = foundGroup.Name;
                }
                return stateData;
              });

              return kioskStateItems;
            })
          );
        })
      )
      .subscribe(
        value => {
          this.kioskStatus$.next(value);
        },
        err => {
          this._logger.warn('Layout: Error displaying kiosk data', err);
          this.kioskStatus$.next([]);
        }
      );
  }

  ngOnDestroy(): void {
    this.layoutState$.unsubscribe();
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // packageConnectionData(data: any, connectionState: string): KioskStateMenuItem {
  //   let awsData = JSON.parse(connectionState),
  //       clientDisconnected = awsData.ClientInitiatedDisconnect ? awsData.ClientInitiatedDisconnect.S : 'False';

  //   data.State = awsData.State.S ? awsData.State.S : 'never';
  //   data.ClientInitiatedDisconnect = clientDisconnected && clientDisconnected === 'True' ? true : false;

  //   return data;
  // }

  // private refreshDataCache(customerId: string): void {
  //   if (this.userRights.has_kiosk_view) this._kioskService.getKioskData(customerId);
  //   if (this.userRights.has_customer_view) this._customerService.getCustomerData(customerId, true);
  //   if (this.userRights.has_templateConfiguration_view) this._templateService.getTemplateSelectOptions(customerId);
  //   if (this.userRights.has_user_view) this._userService.getUserList(customerId);
  //   if (this.userRights.has_group_view) this._groupService.getGroupsByCustomer(customerId);
  // }
}
