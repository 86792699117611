import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewPasswordUser } from '../../../models/newPassword.model';
import { Router, ActivatedRoute } from "@angular/router";
import { RegistrationService } from 'app/services/registration.service';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'knect-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  user: NewPasswordUser = new NewPasswordUser();
  errors: string = '';
  resetInProcess: boolean = false;
  resetSuccess: boolean = false;
  specialCharacters: string = "(^ $ * . [ ] { } ( ) ? - \" ! @ # % / \ , >  ' : ; | _ ~ `)";

  constructor(
    private _regService: RegistrationService,
    private _activateRoute: ActivatedRoute,
    private _router: Router) { }

  ngOnInit(): void {
    this._activateRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      let user = params['user'];
      if (user != null) {
        this.user.username = user.toString();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  resetPassword(): void {
    this.resetInProcess = true;

    this._regService.confirmUser(this.user).then(result => {
      this.resetInProcess = false;
      this.resetSuccess = true;
      this._router.navigate(['./auth']);
    }).catch(err => {
      this.resetInProcess = false;
      this.errors = err.toString();
    });
  }
}
