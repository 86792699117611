import { Injectable } from '@angular/core';
import { LoggingService } from 'app/services/logging.service';
import { HttpClient } from '@angular/common/http';
import { take, map } from 'rxjs/operators';

@Injectable()
export class DownloaderService {

    constructor(private _logger: LoggingService,
        private angularHttp: HttpClient) {
    }

    /**
     * this method will download a local file that is being stored in the Angular app's file directory
     * @param directory - the complete path to the file's folder
     * @param fileNameWithExtension - the complete name of the file (with the extension)
     * @returns void
     */
    public downloadLocalFile(directory: string, fileNameWithExtension: string): void {

        if (!directory || !fileNameWithExtension) {
            this._logger.err('DownloaderService: Attempt to download file failed, passed in directory or filename was missing.', null);
            return;
        }

        if (fileNameWithExtension.indexOf('.') === -1) {
            this._logger.err('DownloaderService: Attempt to download file failed, file name was missing file type extension.', null);
            return;
        }

        let fullDirectoryString = directory + '/' + fileNameWithExtension;

        this.angularHttp.get(fullDirectoryString, {
            responseType: 'blob'
        }).pipe(take(1), map(fileResponse => {

            if (!fileResponse) {
                return {
                    filename: 'unknown',
                    data: null
                };
            }

            return {
                filename: fileNameWithExtension,
                data: fileResponse
            };
        }))
            .subscribe(fileResponse => {

                if (!fileResponse.data) {
                    this._logger.err('DownloaderService: Attempt to download file failed, file was not found.', null);
                    return;
                }

                this.createATagAndDownload(fileResponse.data, fileResponse.filename);

            }, (error) => {
                this._logger.err('DownloaderService: Attempt to download file failed, please see error for more details.', error);
            }, () => {
                this._logger.debug('DownloaderService: File download complete');
            });
    }

    /**
     * this method will download a csv string with the given filename
     * @param csv - the string with the csv file contents
     * @param filename - the name of the downloaded file
     */
    public downloadCsv(csv: string, filename: string): void {
        let blob = new Blob([csv]);
        this.createATagAndDownload(blob, filename);
    }

    private createATagAndDownload(data: Blob, filename: string): void {
        // generate a url based on the file response
        let documentUrl: string = window.URL.createObjectURL(data);

        // generate and setup a hidden html a tag
        let hiddenHtmlATag: HTMLAnchorElement = document.createElement('a');
        hiddenHtmlATag.setAttribute('style', 'display: none');
        hiddenHtmlATag.href = documentUrl;
        hiddenHtmlATag.download = filename;

        // add to the DOM and execute
        document.body.appendChild(hiddenHtmlATag);
        hiddenHtmlATag.click();

        // removing the url reference from the DOM
        window.URL.revokeObjectURL(documentUrl);

        // remove the a tag from the DOM
        hiddenHtmlATag.remove();
    }
}