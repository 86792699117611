import { Product } from '@kiosk/microservice.product.models';
import { InventoryType } from '@kiosk/microservice.inventory.models';

export class InventoryModel {
  LocationId: string;
  UnitId: number;
  LastUpdated: Date;
  InventoryType: InventoryType;
  IsEnabled: boolean;
  Product: Product;
}
