import { AsyncSubject } from 'rxjs/AsyncSubject';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/services/configuration.service';
import { LogEntry, LogLevel } from 'app/models/logEntry.model';
import * as _ from 'lodash';
import * as AWS from 'aws-sdk';
import { Observable } from 'rxjs/Observable';

// Log levels are err = 0, warn = 1, info = 2, debug = 3, silly = 4

@Injectable()
export class LoggingService {
  constructor(private _config: ConfigurationService) { }

  err(msg: string, stacktrace?: any): void {
    this.logToTransports(msg, LogLevel.err, stacktrace || '');
  }

  warn(msg: string, stacktrace?: any): void {
    this.logToTransports(msg, LogLevel.warn, stacktrace || '');
  }

  info(msg: string, stacktrace?: any): void {
    this.logToTransports(msg, LogLevel.info, stacktrace || '');
  }

  debug(msg: string, stacktrace?: any): void {
    this.logToTransports(msg, LogLevel.debug, stacktrace || '');
  }

  silly(msg: string, stacktrace?: any): void {
    this.logToTransports(msg, LogLevel.silly, stacktrace || '');
  }

  private logToTransports(msg: string, level: LogLevel, stacktrace?: any): void {
    this._config.getConfiguration().subscribe(config => {
      // restrict to desired log level
      if (level <= config.LogLevel) {
        let log = new LogEntry(msg, level, stacktrace);

        // add or remove function calls below
        // to turn transports on or off
        if (config.LogToS3) this.logToS3(log);
        if (config.LogToConsole) this.logToConsole(log);
      }
    });
  }

  // console transport function
  private logToConsole(log: LogEntry): void {
    switch (log.level) {
      case LogLevel[LogLevel.err]:
        console.warn(log.msg, log.stacktrace);
        break;

      case LogLevel[LogLevel.warn]:
        console.warn(log.msg, log.stacktrace);
        break;

      case LogLevel[LogLevel.info]:
        console.info(log.msg, log.stacktrace);
        break;

      case LogLevel[LogLevel.debug]:
        console.debug(log.msg, log.stacktrace);
        break;

      case LogLevel[LogLevel.silly]:
        console.trace(log.msg, log.stacktrace);
        break;
    }
  }

  // s3 transport function
  private logToS3(log: LogEntry): void {
    this._config.getConfiguration().subscribe(config => {

      this.uploadToS3(config.S3LogBucket, `${Date.now()} - ${log.level}`, log, true).subscribe(
        done => {
          // console.log('Logging: successfully sent log to s3');
        },
        err => {
          console.error('Logging: error sending log to s3', err);
        }
      );
    });
  }

  uploadToS3(bucket: string, key: string, data: any, isLog = false): Observable<boolean> {
    let subject = new AsyncSubject<boolean>();

    this._config.getConfiguration().subscribe(config => {
      if (isLog && !AWS.config.credentials) {
        // let creds = (AWS.config.credentials as Credentials).get(err => console.error('error getting credentials => ', err));

        AWS.config.update({
            region: config.Region,
            accessKeyId: config.ClerkAK,
            secretAccessKey: config.ClerkSK
          });
      }

      let s3 = new AWS.S3(),
          dataStr = JSON.stringify(data);

      s3.putObject({
        Body: dataStr,
        Bucket: bucket,
        Key: key
      },
      (err, s3Data: AWS.S3.PutObjectOutput) => {
        if (err) {
          subject.error(err);
        } else {
          subject.next(true);
        }

        subject.complete();
      });
    });

    return subject.asObservable();
  }
}
