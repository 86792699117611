import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { NbJSThemeOptions } from '@nebular/theme/services/js-themes/theme.options';

@Component({
  selector: 'ngx-theme-switcher',
  styleUrls: ['./theme-switcher.component.scss'],
  template: `
    <label class="theme-switch">
      <span class="light">K-NECT</span>
      <div class="switch">
        <input type="checkbox" [checked]="currentBoolTheme()" (change)="toggleTheme(theme.checked)" #theme>
        <span class="slider"></span>
      </div>
      <span class="cosmic">T-Mobile</span>
    </label>
  `,
})
export class ThemeSwitcherComponent implements OnInit {
  theme: NbJSThemeOptions;

  constructor(private themeService: NbThemeService) {
  }

  ngOnInit(): void {
    this.themeService.getJsTheme()
      .subscribe((theme: NbJSThemeOptions) => this.theme = theme);
  }

  toggleTheme(theme: boolean): void {
    const boolTheme = this.boolToTheme(theme);
    this.themeService.changeTheme(boolTheme);
  }

  currentBoolTheme(): boolean {
    return this.themeToBool(this.theme);
  }

  private themeToBool(theme: NbJSThemeOptions): boolean {
    return theme.name === 'dark';
  }

  private boolToTheme(theme: boolean): string {
    return theme ? 'dark' : 'knect';
  }
}
