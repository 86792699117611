import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { User, UserCommandType, UserQuery, UserQueryResponse, UserCommand, UserCommandResponse } from '@kiosk/microservice.user.models';
import { Observable } from 'rxjs/Observable';
import { MessageType } from '@kiosk/microservice.common.models';
import { ConfigurationService } from 'app/services/configuration.service';
import { AWSService } from 'app/services/aws.service';
import { LoggingService } from 'app/services/logging.service';
import { UtilityService } from 'app/services/utility.service';
import { switchMap, take, map } from 'rxjs/operators';
import { of, Subject, merge } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserService {
  constructor(
    private _config: ConfigurationService,
    private _aws: AWSService,
    private _logger: LoggingService,
    private _util: UtilityService,
    private _sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) { }

  // User Grid Settings
  gridSettings: any = {
    actions: false,
    columns: {
      FirstName: {
        title: 'First Name',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      LastName: {
        title: 'Last Name',
        type: 'string',
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      UserName: {
        title: 'User Name',
        type: 'string',
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      Email: {
        title: 'Email Address',
        type: 'string',
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      IsEnabled: {
        title: 'Enabled',
        type: 'string',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => (value ? 'Yes' : 'No')
      },
      UserId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: (value, row) =>
          this._sanitizer.bypassSecurityTrustHtml(`
              <div class="text-right" *ngIf="userPermissions?.has_user_view">
                <a class="table-detail-btn ion-android-open" title="details" href="#/pages/user/detail/${value}"></a>
                <a class="table-detail-btn fa fa-cog fa-2x fa-pulse" title="user config" href="#/pages/user/config/${value}"></a>
              </div>
          `)
      }
    }
  };

  public getGridSettings(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations;
        }
        let newGridConfig: any = {};
        Object.assign(newGridConfig, this.gridSettings);
        if (result && result.Users && result.Users.UserSummary) {
          if (result.Users.UserSummary.FirstName) {
            newGridConfig.columns.FirstName.title = result.Users.UserSummary.FirstName;
          }
          if (result.Users.UserSummary.LastName) {
            newGridConfig.columns.LastName.title = result.Users.UserSummary.LastName;
          }
          if (result.Users.UserSummary.UserName) {
            newGridConfig.columns.UserName.title = result.Users.UserSummary.UserName;
          }
          if (result.Users.UserSummary.EmailAddress) {
            newGridConfig.columns.Email.title = result.Users.UserSummary.EmailAddress;
          }
          if (result.Users.UserSummary.Enabled) {
            newGridConfig.columns.IsEnabled.title = result.Users.UserSummary.Enabled;
          }
        }
        subject.next(newGridConfig);
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;
  }

  public getUserList(customerId: string, projectedAttributes: string[] = null): Observable<User[]> {
    return this.getUserOrUserList(null, customerId, projectedAttributes, true) as Observable<User[]>;
  }

  public getUser(userId: string, customerId: string): Observable<User> {
    return this.getUserOrUserList(userId, customerId, null, false) as Observable<User>;
  }

  private getUserOrUserList(userId: string, customerId: string, projectedAttributes: string[],
    isList: boolean): Observable<User | User[]> {
    let request = new UserQuery();
    if (userId) request.UserId = userId;
    if (projectedAttributes) request.ProjectedAttributes = projectedAttributes;
    request.CustomerId = customerId;

    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        request.Environment = config.Environment;

        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.UserQuery);
      }),
      map(response => response as UserQueryResponse),
      switchMap(response => {
        if (response.Exceptions.length > 0) {
          this._logger.err('User: Error retrieving user', response.Exceptions);
          return of(isList ? [] : null);
        } else if (response.Users.length === 0) {
          this._logger.err('User: Error retrieving user, user not found');
          return of(isList ? [] : null);
        } else {
          let returnValue = isList ? response.Users : response.Users[0];

          // Remove this code, when languages are ready for testing again
          if (!isList) {
            (returnValue as User).PortalSettings.DefaultLanguage = 'en';
          }

          this._logger.debug('User: Retrieved user data successfully');
          this._logger.silly(' => ', returnValue);

          return of(returnValue);
        }
      }
      ));
  }

  addOrUpdateAlertRules(user: User): Observable<UserCommand> {
    const command = new UserCommand();

    command.Users = [user];
    command.CommandType = UserCommandType.AddOrUpdateRules;

    return this.sendUserCommand(command);
  }

  saveUser(user: User, newUser: boolean = false): Observable<UserCommand> {
    const command = new UserCommand();
    command.Users = [user];

    if (newUser) {
      this._logger.debug('User: Creating new user');
      this._logger.silly(' => ', newUser);

      command.MessageType = MessageType.Create;
    } else {
      this._logger.debug('User: Updating user');
      this._logger.silly(' => ', newUser);

      command.MessageType = MessageType.Update;
    }

    return this.sendUserCommand(command);
  }

  resetAlertRules(user: User, resetToParent: boolean = false): Observable<UserCommandResponse> {
    const command = new UserCommand();

    command.Users = [user];
    command.CommandType = resetToParent
      ? UserCommandType.ResetToParent
      : UserCommandType.ResetToDefaults;

    return this.sendUserCommand(command);
  }

  enableUser(user: User): Observable<UserCommandResponse> {
    const command = new UserCommand();

    command.Users = [user];
    command.CommandType = UserCommandType.EnableUser;

    return this.sendUserCommand(command);
  }

  disableUser(user: User): Observable<UserCommandResponse> {
    const command = new UserCommand();

    command.Users = [user];
    command.CommandType = UserCommandType.DisableUser;

    return this.sendUserCommand(command);
  }

  private sendUserCommand(command: UserCommand): Observable<UserCommandResponse> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        command.Environment = config.Environment;
        return this._aws.executeLambda(JSON.stringify(command), config.Lambdas.UserCommand);
      }),
      switchMap(response => {
        let data = response as UserCommandResponse;

        if (data.Exceptions.length > 0) {
          this._logger.err('User: Error sending user command', data.Exceptions);
          return of();
        } else {
          this._logger.debug('User: User command successful');
          this._logger.silly(' => ', data);

          return of(data);
        }
      }
      ));
  }
}
