import { Injectable } from "@angular/core";

@Injectable()
export class CacheService {

    constructor() {}

    // Don't use this! there is currently no api to query how much data is stored in sessionStorage
    // and how much space is remaining, if sessionStorage fills up it will throw
    // an error and the page just won't load

    public get(key: string): any {
        let itemStr = sessionStorage.getItem(key);

        if (itemStr && itemStr !== 'undefined') return JSON.parse(itemStr);
        return null;
    }

    public set(key: string, data: any): void {
       sessionStorage.setItem(key, JSON.stringify(data));
    }    

    public remove(key: string): void {
        sessionStorage.removeItem(key);
    }
}
