import { HardwareEvent } from '@kiosk/microservice.systemmetrics.models';
import * as moment from 'moment';
import { QueryFilter } from "@kiosk/microservice.common.models";
import { Message, Query } from '@kiosk/microservice.common.models';

export class WebPortalBusinessQuery implements Query {
    Filters: { [index: string]: QueryFilter[]; };
    SortByAscending: { [index: string]: boolean; };
    Offset: number;
    SkipQueue: boolean;
    CustomerId: string;    
    CustomerName: string;
    GroupId: string;
    KioskToken: string;
    PageSizeInBytes: number;
    ProjectedAttributes: string[];
    LastEvaluatedKey: string;
    ExclusiveStartKey: string;
    QueryType: string;
    PageSize: number;
    Page: number;
    StartDateTime: number;
    EndDateTime: number;
    Exceptions: string[];
    ApiVersion: number;
    Environment;
    MessageType;
    Timestamp: number;
    ConnectionId: string;
    ResponseTopic: string;
}

export class WebPortalBusinessQueryResponse implements Message {
    Offset: number;
    SkipQueue: boolean;
    ResponsePayload: any[];    
    SinglePayload: any;
    Exceptions: string[];
    ApiVersion: number;
    Environment;
    MessageType;
    Timestamp: number;
    ConnectionId: string;
    ResponseTopic: string;
}

export class ApplicationStatistics {
    ApplicationsRunning: number;    
    ApplicationsStopped: number;
    ApplicationsUnknown: number;
}

export class HardwareStatistics {
    HardwareOnline: number;    
    HardwareError: number;
    HardwareWarning: number;
    HardwareOffline: number;
    HardwareUnknown: number;
}

export class DashboardData {
    FirstKiosks: KioskGridData[];
    TotalKiosks: number;    
    KiosksOnline: number;
    KiosksOffline: number;
    KiosksYetToConnect: number;
    KioskHardware: HardwareStatistics;
    KioskApplication: ApplicationStatistics;    
}

export class KioskGridData {
	KioskName: string;
	KioskDescription: string;
	KioskToken: string;
	SerialNumber: string;
	UrlRedirect: string;
	Address: string;
	ConnectionData: ConnectionData;
	SystemMetrics: PerformanceCounter[];
	KioskApplication: KioskApplication[];
	KioskHardware: KioskHardware[];
	GroupName: string;
	CertificateStatus: string;
	Stage: string;
	Authorization: string;
	Offset: number;
	CertificateArn: string;
	PreSignedUrl: string;
	GroupId: string;
	HardwareState: string;
  ApplicationState: string;

    constructor() {        
	}
	
	public initialize(): void {
		if(this.HardwareState) {
            this.KioskHardware = this.formatHardwareState(this.HardwareState);
        }
	}

	public formatHardwareState(hardwareState: string): KioskHardware[] {
		let formattedHardware = new Array<KioskHardware>();

		if (hardwareState) {
			let kioskHardware: HardwareEvent[] = JSON.parse(hardwareState) as HardwareEvent[];

			kioskHardware.forEach(hardware => {
				if (hardware.ConfigurationName === 'NA') {
					return;
				}

				let metadata;

				if (hardware.MetaData && hardware.MetaData !== '{}' && hardware.MetaData !== 'null') {
					metadata = JSON.parse(hardware.MetaData);

					if (metadata.Status) metadata.Status = metadata.Status.replace(/ /gi, ', ');
				}

				let newHardware = new KioskHardware();
				newHardware.InstanceName = hardware.ConfigurationName;
				newHardware.MetricName = hardware.Assembly ? hardware.Assembly : '';
				newHardware.Model = hardware.Model ? hardware.Model : '';
				newHardware.AssemblyVersion = hardware.AssemblyVersion ? hardware.AssemblyVersion : '';
				newHardware.FirmwareVersion = hardware.FirmwareVersion ? hardware.FirmwareVersion : '';
				newHardware.Status = hardware.Status ? hardware.Status : '';
				newHardware.Timestamp = hardware.Timestamp ? hardware.Timestamp : null;
				newHardware.FormattedTimestamp = this.formatTimeStamp(newHardware.Timestamp);
				newHardware.LastRfid = (metadata && metadata.Rfid) ? metadata.Rfid : '';
				newHardware.NodeStatusString = (metadata && metadata.Status) ? metadata.Status : '';

				formattedHardware.push(newHardware);
			});
		}

		return formattedHardware;
	}

	public formatTimeStamp(unixMsTime: number): string {
		let seconds = unixMsTime / 1000;
		return moment.unix(Math.floor(seconds)).format('MM/DD/YY h:mm a');
	}
}

export class KioskHardware {
	InstanceName: string;
	MetricName: string;
	AssemblyVersion: string;
	FirmwareVersion: string;
	Model: string;
	Status: string;
	Timestamp: number;
	FormattedTimestamp: string;
	NodeStatusString: string = '';
	LastRfid: string = '';
}

export class KioskApplication {
	Name: string;
	ProcessedTimestamp: number;
	FormattedTimestamp: string;
	Status: string;
	MetricImage: string;
	SafeImages: any;
}

export class ConnectionData {
	State: string;
	Timestamp: number;
	FormattedTimestamp: string;
	IsConnected: boolean;
	ClientInitiatedDisconnect: boolean;
}

export class PerformanceCounter {
	Name: string;
	Timestamp: number;
	FormattedTimeStamp: string;
	Value: number;
	DisplayValue: string;
	IsDanger: boolean;
}