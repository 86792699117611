import { NgModule } from '@angular/core';

import { SubmitIfValidDirective } from './submit-if-valid.directive';
import { EqualValidatorDirective } from './equal-validator.directive';

@NgModule({
  declarations: [
    SubmitIfValidDirective,
    EqualValidatorDirective
  ],
  exports: [
    SubmitIfValidDirective,
    EqualValidatorDirective
  ]
})
export class FormHelpersModule { }
