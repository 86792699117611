import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnInit } from '@angular/core';
import { Observable, AsyncSubject, merge, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { take, map } from 'rxjs/operators';
import { KioskService } from 'app/services/pages/kiosk.service';
import { UtilityService } from 'app/services/utility.service';

@Injectable()
export class SmartTableService {
  private kioskGridSettings: any = {
    actions: false,
    noDataMessage: '',
    columns: {
      KioskName: {
        title: 'Kiosk Name',
        type: 'string',
        editable: false,
        sort: true,
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      // KioskToken: {
      //   title: 'Search by Kiosk Token',
      //   type: 'html',
      //   editable: false,
      //   sort: false,
      //   filterFunction: (query, originValue) => {
      //     return query === originValue;
      //   },
      //   valuePrepareFunction: value => {
      //     return this._sanitizer.bypassSecurityTrustHtml(
      //       `<div></div>`
      //     );
      //   }
      // },
      SerialNumber: {
        title: 'Serial',
        type: 'string',
        editable: false,
        sort: false
      },
      GroupName: {
        title: 'Group',
        type: 'string',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: []
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search || cell === search) return true;
          return false;
        }
      },
      ConnectionData: {
        title: 'Current Status',
        type: 'html',
        editable: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'connected', title: 'connected' },
              { value: 'disconnected', title: 'disconnected' },
              { value: 'clientInit', title: 'disconnected (client initiated only)' },
              { value: 'never', title: 'yet to connect' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (cell.ClientInitiatedDisconnect && search === 'clientInit') return true;
          if (cell.State.toLowerCase() === search || !search) return true;
          return false;
        },
        compareFunction: this._util.kioskConnectionCompare,
        valuePrepareFunction: value => {
          let spans = '';

          if (value.State && value.State === 'never')
            spans +=
              '<span class="badge badge-pill badge-default"><small><i>yet to connect...</i></small></span>';
          else if (value.State && value.State === 'connected')
            spans += `<span class="badge badge-pill badge-success">${
              value.State
            }</span>&nbsp;<small><i>(${value.FormattedTimeStamp})</i></small>`;
          else
            spans += `<span class="badge badge-pill badge-danger">${
              value.State
            }</span>&nbsp;<small><i>(${value.FormattedTimeStamp})</i></small>`;

          if (value.ClientInitiatedDisconnect)
            spans += '&nbsp;<small><i>(client initiated)</i></small>';

          return this._sanitizer.bypassSecurityTrustHtml(`<div class="text-center">${spans}</div>`);
        }
      },
      Hardware: {
        title: 'H/W status',
        type: 'html',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'noHw', title: 'no hardware' },
              { value: 'unknown', title: 'all unknown' },
              { value: 'error', title: 'errors present' },
              { value: 'warning', title: 'warnings present' },
              { value: 'allgood', title: 'no warnings, no errors' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search) return true;
          let hardwareStatus = this._kiosk.getKioskHardwareStatus(cell);
          // if the hardware status object coming back does not have any hardware on the filter
          // and the search is for 'allgood' then no need to show it, as it doesn't have any hardware
          if (
            hardwareStatus.ErrorCount === 0 &&
            hardwareStatus.OfflineCount === 0 &&
            hardwareStatus.WarningCount === 0 &&
            hardwareStatus.OnlineCount === 0 &&
            hardwareStatus.UnknownCount === 0 &&
            search === 'allgood'
          )
            return false;

          let online = hardwareStatus.OnlineCount,
            offline = hardwareStatus.OfflineCount,
            warning = hardwareStatus.WarningCount,
            error = hardwareStatus.ErrorCount,
            unknown = hardwareStatus.UnknownCount;

          if (
            online === 0 &&
            offline === 0 &&
            warning === 0 &&
            error === 0 &&
            unknown === 0 &&
            search === 'noHw'
          )
            return true;
          if ((error > 0 || offline > 0) && search === 'error') return true;
          if (warning > 0 && search === 'warning') return true;
          if (error === 0 && warning === 0 && offline === 0 && unknown === 0 && search === 'allgood') return true;
          if (unknown > 0 && online === 0 && search === 'unknown') return true;

          return false;
        },
        valuePrepareFunction: value => {
          let hardwareStatus = this._kiosk.getKioskHardwareStatus(value);
          let spans = '',
            online = hardwareStatus.OnlineCount,
            offline = hardwareStatus.OfflineCount,
            warning = hardwareStatus.WarningCount,
            error = hardwareStatus.ErrorCount,
            unknown = hardwareStatus.UnknownCount;

          if (online > 0)
            spans += `<span class="badge badge-pill badge-success" title="${online} online">&nbsp${online}&nbsp</span>`;
          if (offline > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-dark" title="${offline} offline">&nbsp${offline}&nbsp</span>`;
          if (warning > 0 && unknown === 0)
            spans += `&nbsp;<span class="badge badge-pill badge-warning" title="${warning} warning(s)">&nbsp${warning}&nbsp</span>`;
          if (error > 0 && unknown === 0)
            spans += `&nbsp;<span class="badge badge-pill badge-danger" title="${error} error(s)">&nbsp${error}&nbsp</span>`;
          if (unknown > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-secondary" title="${unknown} unknown">&nbsp;${unknown}&nbsp</span>`;

          return this._sanitizer.bypassSecurityTrustHtml(
            `<div class="col-12 text-center">${spans}</div>`
          );
        }
      },
      Applications: {
        title: 'App Status',
        type: 'html',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'stopped', title: 'stopped' },
              { value: 'running', title: 'running' },
              { value: 'unknown', title: 'unknown' },
              { value: 'noApp', title: 'no apps' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search) return true;
          let appStatus = this._kiosk.getAppStatus(cell);

          if (
            appStatus.Running === 0 &&
            appStatus.Stopped === 0 &&
            appStatus.Unknown === 0 &&
            search === 'noApp'
          )
            return true;

          // if the app status object coming back does not have any apps on the filter
          // then no need to show it, as it doesn't have any Applications
          if (appStatus.Running === 0 && appStatus.Stopped === 0 && appStatus.Unknown === 0)
            return false;

          let running = appStatus.Running,
            stopped = appStatus.Stopped,
            unknown = appStatus.Unknown;

          if (running > 0 && search === 'running') return true;
          if (stopped > 0 && search === 'stopped') return true;
          if (unknown > 0 && running === 0 && search === 'unknown') return true;

          return false;
        },
        valuePrepareFunction: value => {
          let appStatus = this._kiosk.getAppStatus(value);
          let spans = '';

          if (appStatus.Running > 0)
            spans += `<span class="badge badge-pill badge-success" title="${
              appStatus.Running
            } running">&nbsp${appStatus.Running}&nbsp</span>`;
          if (appStatus.Stopped > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-danger" title="${
              appStatus.Stopped
            } stopped">&nbsp${appStatus.Stopped}&nbsp</span>`;
          if (appStatus.Unknown > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-secondary" title="${
              appStatus.Unknown
            } unknown">&nbsp${appStatus.Unknown}&nbsp</span>`;

          return this._sanitizer.bypassSecurityTrustHtml(
            `<div class="col-12 text-center">${spans}</div>`
          );
        }
      },
      KioskTokenLink: {
        title: '',
        type: 'html',
        filter: false,
        editable: false,
        valuePrepareFunction: value => {
          const foundKiosk = this._kiosk.kioskSubject.getValue().find(k => k.KioskToken === value);
          const hasInventory =
            foundKiosk && foundKiosk.InventoryLastUpdated && foundKiosk.InventoryLastUpdated > 0 && foundKiosk.Inventory !== null && foundKiosk.Inventory.length > 0;

          if (hasInventory) {
            return this._sanitizer.bypassSecurityTrustHtml(`
                <div class="text-right" *ngIf="userPermissions.has_kiosk_view">
                  <a class="table-detail-btn ion-android-open" title="details" href="#/pages/kiosk/detail/${value}"></a>
                  <a class="table-detail-btn ion-android-list" title="inventory" href="#/pages/kiosk/inventory/${value}"></a>
                </div>
              `);
          } else {
            return this._sanitizer.bypassSecurityTrustHtml(`
                <div class="text-right" *ngIf="userPermissions.has_kiosk_view">
                  <a class="table-detail-btn ion-android-open" title="details" href="#/pages/kiosk/detail/${value}"></a>
                </div>
              `);
          }
        }
      }
    }
  };

  public defaultKioskGridSettings: any = {
    actions: false,
    noDataMessage: '',
    columns: {
      KioskName: {
        title: 'Kiosk Name',
        type: 'string',
        editable: false,
        sort: true,
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      // KioskToken: {
      //   title: 'Search by Kiosk Token',
      //   type: 'html',
      //   editable: false,
      //   sort: false,
      //   filterFunction: (query, originValue) => {
      //     return query === originValue;
      //   },
      //   valuePrepareFunction: value => {
      //     return this._sanitizer.bypassSecurityTrustHtml(
      //       `<div></div>`
      //     );
      //   }
      // },
      SerialNumber: {
        title: 'Serial',
        type: 'string',
        editable: false,
        sort: false
      },
      GroupName: {
        title: 'Group',
        type: 'string',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: []
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search || cell === search) return true;
          return false;
        }
      },
      ConnectionData: {
        title: 'Current Status',
        type: 'html',
        editable: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'connected', title: 'connected' },
              { value: 'disconnected', title: 'disconnected' },
              { value: 'clientInit', title: 'disconnected (client initiated only)' },
              { value: 'never', title: 'yet to connect' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (cell.ClientInitiatedDisconnect && search === 'clientInit') return true;
          if (cell.State.toLowerCase() === search || !search) return true;
          return false;
        },
        compareFunction: this._util.kioskConnectionCompare,
        valuePrepareFunction: value => {
          let spans = '';

          if (value.State && value.State === 'never')
            spans +=
              '<span class="badge badge-pill badge-default"><small><i>yet to connect...</i></small></span>';
          else if (value.State && value.State === 'connected')
            spans += `<span class="badge badge-pill badge-success">${
              value.State
            }</span>&nbsp;<small><i>(${value.FormattedTimeStamp})</i></small>`;
          else
            spans += `<span class="badge badge-pill badge-danger">${
              value.State
            }</span>&nbsp;<small><i>(${value.FormattedTimeStamp})</i></small>`;

          if (value.ClientInitiatedDisconnect)
            spans += '&nbsp;<small><i>(client initiated)</i></small>';

          return this._sanitizer.bypassSecurityTrustHtml(`<div class="text-center">${spans}</div>`);
        }
      },
      Hardware: {
        title: 'H/W status',
        type: 'html',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'noHw', title: 'no hardware' },
              { value: 'unknown', title: 'all unknown' },
              { value: 'error', title: 'errors present' },
              { value: 'warning', title: 'warnings present' },
              { value: 'allgood', title: 'no warnings, no errors' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search) return true;
          let hardwareStatus = this._kiosk.getKioskHardwareStatus(cell);
          // if the hardware status object coming back does not have any hardware on the filter
          // and the search is for 'allgood' then no need to show it, as it doesn't have any hardware
          if (
            hardwareStatus.ErrorCount === 0 &&
            hardwareStatus.OfflineCount === 0 &&
            hardwareStatus.WarningCount === 0 &&
            hardwareStatus.OnlineCount === 0 &&
            hardwareStatus.UnknownCount === 0 &&
            search === 'allgood'
          )
            return false;

          let online = hardwareStatus.OnlineCount,
            offline = hardwareStatus.OfflineCount,
            warning = hardwareStatus.WarningCount,
            error = hardwareStatus.ErrorCount,
            unknown = hardwareStatus.UnknownCount;

          if (
            online === 0 &&
            offline === 0 &&
            warning === 0 &&
            error === 0 &&
            unknown === 0 &&
            search === 'noHw'
          )
            return true;
          if ((error > 0 || offline > 0) && search === 'error') return true;
          if (warning > 0 && search === 'warning') return true;
          if (error === 0 && warning === 0 && offline === 0 && unknown === 0 && search === 'allgood') return true;
          if (unknown > 0 && online === 0 && search === 'unknown') return true;

          return false;
        },
        valuePrepareFunction: value => {
          let hardwareStatus = this._kiosk.getKioskHardwareStatus(value);
          let spans = '',
            online = hardwareStatus.OnlineCount,
            offline = hardwareStatus.OfflineCount,
            warning = hardwareStatus.WarningCount,
            error = hardwareStatus.ErrorCount,
            unknown = hardwareStatus.UnknownCount;

          if (online > 0)
            spans += `<span class="badge badge-pill badge-success" title="${online} online">&nbsp${online}&nbsp</span>`;
          if (offline > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-dark" title="${offline} offline">&nbsp${offline}&nbsp</span>`;
          if (warning > 0 && unknown === 0)
            spans += `&nbsp;<span class="badge badge-pill badge-warning" title="${warning} warning(s)">&nbsp${warning}&nbsp</span>`;
          if (error > 0 && unknown === 0)
            spans += `&nbsp;<span class="badge badge-pill badge-danger" title="${error} error(s)">&nbsp${error}&nbsp</span>`;
          if (unknown > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-secondary" title="${unknown} unknown">&nbsp;${unknown}&nbsp</span>`;

          return this._sanitizer.bypassSecurityTrustHtml(
            `<div class="col-12 text-center">${spans}</div>`
          );
        }
      },
      Applications: {
        title: 'App Status',
        type: 'html',
        editable: false,
        sort: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [
              { value: 'stopped', title: 'stopped' },
              { value: 'running', title: 'running' },
              { value: 'unknown', title: 'unknown' },
              { value: 'noApp', title: 'no apps' }
            ]
          }
        },
        filterFunction: (cell: any, search?: string) => {
          if (!search) return true;
          let appStatus = this._kiosk.getAppStatus(cell);

          if (
            appStatus.Running === 0 &&
            appStatus.Stopped === 0 &&
            appStatus.Unknown === 0 &&
            search === 'noApp'
          )
            return true;

          // if the app status object coming back does not have any apps on the filter
          // then no need to show it, as it doesn't have any Applications
          if (appStatus.Running === 0 && appStatus.Stopped === 0 && appStatus.Unknown === 0)
            return false;

          let running = appStatus.Running,
            stopped = appStatus.Stopped,
            unknown = appStatus.Unknown;

          if (running > 0 && search === 'running') return true;
          if (stopped > 0 && search === 'stopped') return true;
          if (unknown > 0 && running === 0 && search === 'unknown') return true;

          return false;
        },
        valuePrepareFunction: value => {
          let appStatus = this._kiosk.getAppStatus(value);
          let spans = '';

          if (appStatus.Running > 0)
            spans += `<span class="badge badge-pill badge-success" title="${
              appStatus.Running
            } running">&nbsp${appStatus.Running}&nbsp</span>`;
          if (appStatus.Stopped > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-danger" title="${
              appStatus.Stopped
            } stopped">&nbsp${appStatus.Stopped}&nbsp</span>`;
          if (appStatus.Unknown > 0)
            spans += `&nbsp;<span class="badge badge-pill badge-secondary" title="${
              appStatus.Unknown
            } unknown">&nbsp${appStatus.Unknown}&nbsp</span>`;

          return this._sanitizer.bypassSecurityTrustHtml(
            `<div class="col-12 text-center">${spans}</div>`
          );
        }
      },
      KioskTokenLink: {
        title: '',
        type: 'html',
        filter: false,
        editable: false,
        valuePrepareFunction: value => {
          const foundKiosk = this._kiosk.kioskSubject.getValue().find(k => k.KioskToken === value);
          const hasInventory =
            foundKiosk && foundKiosk.InventoryLastUpdated && foundKiosk.InventoryLastUpdated > 0 && foundKiosk.Inventory !== null && foundKiosk.Inventory.length > 0;

          if (hasInventory) {
            return this._sanitizer.bypassSecurityTrustHtml(`
              <div class="text-right" *ngIf="userPermissions.has_kiosk_view">
                <a class="table-detail-btn ion-android-open" title="details" href="#/pages/kiosk/detail/${value}"></a>
                <a class="table-detail-btn ion-android-list" title="inventory" href="#/pages/kiosk/inventory/${value}"></a>
              </div>
            `);
          } else {
            return this._sanitizer.bypassSecurityTrustHtml(`
              <div class="text-right" *ngIf="userPermissions.has_kiosk_view">
                <a class="table-detail-btn ion-android-open" title="details" href="#/pages/kiosk/detail/${value}"></a>
              </div>
            `);
          }
        }
      }
    }
  };

  constructor(
    private _translate: TranslateService,
    private _kiosk: KioskService,
    private _util: UtilityService,
    private _sanitizer: DomSanitizer
  ) {}

  initializeService(): void {
    this._translate
      .getTranslation(this._translate.currentLang)
      .pipe(
        take(1),
        map(result => {
          if (result && result.SmartTable && result.SmartTable.Kiosks) {
            this.kioskGridSettings.columns.KioskName.title =
              result.SmartTable.Kiosks.KioskNameHeader;
            this.kioskGridSettings.columns.SerialNumber.title =
              result.SmartTable.Kiosks.SerialHeader;
            this.kioskGridSettings.columns.GroupName.title = result.SmartTable.Kiosks.GroupHeader;
            this.kioskGridSettings.columns.ConnectionData.title =
              result.SmartTable.Kiosks.CurrentStatusHeader;
            this.kioskGridSettings.columns.Hardware.title =
              result.SmartTable.Kiosks.HardwareStatusHeader;
            this.kioskGridSettings.columns.Applications.title =
              result.SmartTable.Kiosks.ApplicationStatusHeader;
          }
        })
      )
      .subscribe();
  }

  public getKioskGridSettings(): Observable<any> {
    let gridSubject: Subject<any> = new Subject();

    merge(
      this._translate.onLangChange.asObservable(),
      this._translate.getTranslation(this._translate.currentLang)
    )
      .pipe(
        map(
          result => {
            // any time a user changes the language, we will get a translations object to update the table and push it...
            if (
              result.translations &&
              result.translations.SmartTable &&
              result.translations.SmartTable.Kiosks
            ) {
              this.kioskGridSettings.columns.KioskName.title =
                result.translations.SmartTable.Kiosks.KioskNameHeader;
              // this.kioskGridSettings.columns.KioskToken.title =
              //   result.translations.SmartTable.Kiosks.SearchByKioskToken;
              this.kioskGridSettings.columns.SerialNumber.title =
                result.translations.SmartTable.Kiosks.SerialHeader;
              this.kioskGridSettings.columns.GroupName.title =
                result.translations.SmartTable.Kiosks.GroupHeader;
              this.kioskGridSettings.columns.ConnectionData.title =
                result.translations.SmartTable.Kiosks.CurrentStatusHeader;
              this.kioskGridSettings.columns.Hardware.title =
                result.translations.SmartTable.Kiosks.HardwareStatusHeader;
              this.kioskGridSettings.columns.Applications.title =
                result.translations.SmartTable.Kiosks.ApplicationStatusHeader;
              this.kioskGridSettings.columns.GroupName.filter.config.selectText =
                result.translations.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.ConnectionData.filter.config.selectText =
                result.translations.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.Hardware.filter.config.selectText =
                result.translations.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.Applications.filter.config.selectText =
                result.translations.SmartTable.Kiosks.ShowAllFilter;
            }

            // this is the initialization of getTranslation we need when this is first requested
            if (result && result.SmartTable && result.SmartTable.Kiosks) {
              this.kioskGridSettings.columns.KioskName.title =
                result.SmartTable.Kiosks.KioskNameHeader;
              // this.kioskGridSettings.columns.KioskToken.title =
              //   result.SmartTable.Kiosks.SearchByKioskToken;
              this.kioskGridSettings.columns.SerialNumber.title =
                result.SmartTable.Kiosks.SerialHeader;
              this.kioskGridSettings.columns.GroupName.title = result.SmartTable.Kiosks.GroupHeader;
              this.kioskGridSettings.columns.ConnectionData.title =
                result.SmartTable.Kiosks.CurrentStatusHeader;
              this.kioskGridSettings.columns.Hardware.title =
                result.SmartTable.Kiosks.HardwareStatusHeader;
              this.kioskGridSettings.columns.Applications.title =
                result.SmartTable.Kiosks.ApplicationStatusHeader;
              this.kioskGridSettings.columns.GroupName.filter.config.selectText =
                result.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.ConnectionData.filter.config.selectText =
                result.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.Hardware.filter.config.selectText =
                result.SmartTable.Kiosks.ShowAllFilter;
              this.kioskGridSettings.columns.Applications.filter.config.selectText =
                result.SmartTable.Kiosks.ShowAllFilter;
            }

            gridSubject.next(this.kioskGridSettings);
          },
          error => {
            gridSubject.error(error);
            gridSubject.complete();
          }
        )
      )
      .subscribe();

    return gridSubject.asObservable();
  }
}
