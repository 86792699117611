import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { KioskService } from 'app/services/pages/kiosk.service';
import { UserSessionService } from 'app/services/user-session.service';
import { Subject } from 'rxjs/Subject';
import { take, takeUntil, filter, find, switchMap } from 'rxjs/operators';
import { KioskDetailGridData } from '../../../models/kioskGridData.model';
import { GroupsService } from '../../../services/pages/groups.service';
import { forkJoin, of, from, Subscription } from 'rxjs';
import { KioskDetail } from '@kiosk/microservice.kiosk.models';
import { CsvService } from '../../../services/csv.service';
import { DownloaderService } from '../../../services/downloader.service';
import * as _ from 'lodash';
import { SmartTableService } from 'app/@core/data/smart-table.service';
import { KnectKioskSummaryTableSearch } from 'app/constants/knect-kiosk-summary-table.search';

@Component({
  selector: 'knect-kiosk-summary-table',
  templateUrl: './knect-kiosk-summary-table.component.html'
})
export class KnectKioskSummaryTableComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  source: LocalDataSource = new LocalDataSource();
  sourceChangeSubscription: Subscription;
  loaded: boolean = false;
  gridSettings: any = this._tableService.defaultKioskGridSettings;
  notFoundNotByKioskToken: boolean = false;
  notFoundByKioskToken: boolean = false;

  private readonly csvFileName: string = 'kioskSummaryData.csv';

  constructor(
    private _kioskService: KioskService,
    private _userSession: UserSessionService,
    private _groupsService: GroupsService,
    private _csv: CsvService,
    private _downloader: DownloaderService, 
    private _tableService: SmartTableService
  ) {
    _userSession.startSpinner(true, null);    
  }

  ngOnInit(): void {
    this._tableService.getKioskGridSettings()
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(settings => {
        if(this.gridSettings.columns) {
          if(this.gridSettings.columns.GroupName.filter.config.list) {
            settings.columns.GroupName.filter.config.list = this.gridSettings.columns.GroupName.filter.config.list;
          }
        }

        this.gridSettings = Object.assign({}, settings);
      });
    
    this.sourceChangeSubscription = this.source.onChanged()
    .pipe(
      filter(res => res.action === 'filter')
    )
    .subscribe(res => {
      if (res.elements.length === 0) {
        this.notFoundByKioskToken = false;
        res.filter.filters.forEach(element => {
          if (element.field === 'KioskToken' && element.search !== '') {
            this.notFoundByKioskToken = true;
          }
        });
        this.notFoundNotByKioskToken = !this.notFoundByKioskToken;
      } else {
        this.notFoundNotByKioskToken = this.notFoundByKioskToken = false;
      }
    });
  }

  @Input()
  customerId: string;

  @Input()
  set kiosks(kiosks: KioskDetail[]) {
    if (this.customerId) {
      if (this._kioskService.needToReloadKiosks()) {
        this._kioskService.setKioskReloadFlag(false);
        this._kioskService.getKioskData(this.customerId).pipe(take(1))
        .subscribe(responses => {
          kiosks = new Array<KioskDetail>();
          responses.forEach(response => {
            kiosks.push(...response.KioskDetails);
          });
          this.loadKioskData(this.customerId, kiosks);
        });
      } else {
        if (kiosks !== null) {
          this.loadKioskData(this.customerId, kiosks);
        }
      }
    }
  }

  loadKioskData(customerId: string, kiosks: KioskDetail[]): void {
    this._userSession.startSpinner(true, null);

    forkJoin(this._groupsService.getGroupsByCustomer(customerId), of(kiosks))
      .pipe(take(1))
      .subscribe(
        results => {
          // load groups for selections in group dropdown
          let groupResponse = results[0];
          let groupFilterList = [];
          groupResponse.map(group =>
            groupFilterList.push({ value: group.Name, title: group.Name })
          );
          let sortedGroups = _.orderBy(
            groupFilterList,
            [group => group.value.toLowerCase()],
            ['value']
          );
          this.gridSettings.columns.GroupName.filter.config.list = sortedGroups;
          // smart table only reloads the changes if the whole settings property is changed, need this
          // to refresh the group dropdown values
          this.gridSettings = Object.assign({}, this.gridSettings);

          // load kiosk data
          let kioskResponse = results[1];
          let kioskGridItems: KioskDetailGridData[] = new Array<KioskDetailGridData>();
          for (let kiosk of kioskResponse) {
            let kioskGridItem = new KioskDetailGridData(kiosk);
            let group = groupResponse.find(x => x.GroupId === kiosk.GroupId);
            if (group) {
              kioskGridItem.GroupName = groupResponse.find(x => x.GroupId === kiosk.GroupId).Name;
            }

            for (let hardware of kioskGridItem.Hardware) {
              hardware.Status = hardware.Status && !kioskGridItem.ConnectionData.IsConnected ? 'Unknown' : hardware.Status;
            }

            for(let app of kioskGridItem.Applications) {
              app.Status = app.Status && !kioskGridItem.ConnectionData.IsConnected ? 'Unknown' : app.Status;
            }

            kioskGridItems.push(kioskGridItem);
          }

          this.source.empty();
          this.source.onChanged().pipe(takeUntil(this.ngUnsubscribe), filter(value => {
            return value.action === 'load';
          })).subscribe(value => { 
            if(value.action === 'load') {
              this._userSession.stopSpinner(true, null);
            }
          });
          this.source.load(kioskGridItems);
          this.loaded = true;
        },
        err => {
          this.source.empty();
          this.source.load([]);

          this.loaded = true;
          this._userSession.stopSpinner(true, null);
        }
      );
  }

  exportToCsv(): void {
    this.source.getFilteredAndSorted().then(data => {
      let csvStr = this._csv.kioskGridDataToCsv(data);
      this._downloader.downloadCsv(csvStr, this.csvFileName);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.sourceChangeSubscription.unsubscribe();
  }
}
