import { Injectable } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbThemeService,
  NbSidebarService,
  NbMediaBreakpoint,
  NbMenuService
} from '@nebular/theme';

@Injectable()
export class SidebarService {
  isCollapsed = false;
  shouldBeCollapsed = false; // if user has toggled menu to compact mode intentionally

  constructor(
    private _nbBreakpoints: NbMediaBreakpointsService,
    private _nbTheme: NbThemeService,
    private _nbSidebarService: NbSidebarService,
    private _nbMenuService: NbMenuService
  ) {
    const lgBp = this._nbBreakpoints.getByName('lg');

    /** handles tracking when the sidebar is compacted and
     * also when it should be based on users actions and acting accordingly */
    this._nbTheme
      .onMediaQueryChange()
      .subscribe(([bpFrom, bpTo]: [NbMediaBreakpoint, NbMediaBreakpoint]) => {
        if (bpTo.width) this.isCollapsed = bpTo.width <= lgBp.width;
      });

    this._nbMenuService.onItemSelect().subscribe(() => (this.isCollapsed = false));
    this._nbSidebarService.onExpand().subscribe(() => (this.isCollapsed = false));
    this._nbSidebarService
      .onToggle()
      .subscribe(() => (this.isCollapsed = this.shouldBeCollapsed ? true : !this.isCollapsed));
  }

  collapse(force: boolean = false): void {
    if (!this.isCollapsed || force) {
      this._nbSidebarService.toggle(true, 'menu-sidebar');
      this.isCollapsed = true;
    }
  }

  expand(force: boolean = false): void {
    if (this.isCollapsed || force) this._nbSidebarService.expand('menu-sidebar');
  }
}
