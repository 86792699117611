import {
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter
  } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[submitIfValid]'
})
export class SubmitIfValidDirective {

  @Input('submitIfValid') formRef: FormGroup;
  @Output() valid = new EventEmitter<void>();

  constructor() { }

  @HostListener('click')
  handleClick(): void {
    this.markFieldsAsDirty();
    this.emitIfValid();
  }

  private markFieldsAsDirty(): void {
    Object.keys(this.formRef.controls)
      .forEach(fieldName =>
        this.formRef.controls[fieldName].markAsTouched()
      );
  }

  private emitIfValid(): void {
    if (this.formRef.valid) {
      this.valid.emit();
    }
  }
}
