import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductImage } from '@kiosk/microservice.product.models';

@Injectable()
export class KnectImageUploadService {

  private image: Subject<ProductImage> = new Subject<ProductImage>();
  private errorMessage: Subject<string> = new Subject<string>();

  image$ = this.image.asObservable();
  errorMessage$ = this.errorMessage.asObservable();

  setImage(image: ProductImage): void {
    this.image.next(image);
  }

  setError(error: string): void {
    this.errorMessage.next(error);
  }
}