import { Injectable } from "@angular/core";
import { EChartOptions } from 'echarts';
import { NbThemeService } from "@nebular/theme";
import { take } from "rxjs/operators";


@Injectable()
export class ChartingService {

  private color1: any = 'rgba(175, 189, 33, .5)';
  private color2: any = 'rgba(150, 35, 35, .5)';
  private color3: any = 'rgba(227, 114, 34, .5)';
  private color4: any = 'rgba(76, 108, 150, .5)';
  private color5: any = 'rgba(200, 217, 111, .5)';
  private color6: any = 'rgba(41, 51, 92, .5)';
  private color7: any = 'rgba(243, 167, 18, .5)';
  private color8: any = 'rgba(102, 155, 188, .5)';
  private color9: any = 'rgba(228, 87, 46, .5)';
  private color10: any = 'rgba(140, 160, 215, .5)';
  private color11: any = 'rgba(145, 196, 242, .5)';

  private solidColor1: any = 'rgb(175, 189, 33)';
  private solidColor2: any = 'rgb(150, 35, 35)';
  private solidColor3: any = 'rgb(227, 114, 34)';
  private solidColor4: any = 'rgb(76, 108, 150)';

  private _barchartEOptionsData: any;

  private _piechartEOptionsData: any;

  constructor(private _theme: NbThemeService) {
    this.buildPieChartOptions();
    this.buildBarChartOptions();
  }

  /**
   * returns the EChartOptions for a pie chart
   * @param pieChartSeriesData the data series for the pie slices
   * @param pieChartLegendData legend for the data series
   * @param pieChartToolTipFormat tooltop format for when hovering over the chart
   * @param pieChartSeriesLabelFormat format of the label for the pie data on the chart (not hovering)
   */
  public getPieChartOptions(pieChartSeriesData: any, pieChartLegendData: any = null, pieChartToolTipFormat: any = null,
    pieChartSeriesLabelFormat: any = null): EChartOptions {

    let placeHolder: any = this._piechartEOptionsData;

    placeHolder.series[0].data = pieChartSeriesData;
    placeHolder.series[0].label.normal.formatter = pieChartSeriesLabelFormat;
    placeHolder.tooltip.formatter = pieChartToolTipFormat;
    placeHolder.legend.data = pieChartLegendData;

    return placeHolder;
  }

  /**
   * returns the EChartOptions for a pie chart
   * @param barChartSeriesData the data series for the bars
   * @param barChartLegendData legend for the data series
   * @param xAxis X-Axis for category vs value
   * @param yAxis Y-Axis for category vs value
   * @param tooltip tooltip format for when hovering over the chart
   */
  public getBarChartOptions(barChartSeriesData: any[], barChartLegendData: any = null,
    xAxis: any = null, yAxis: any = null, tooltip: any = null): EChartOptions {

    this._barchartEOptionsData.series = barChartSeriesData;
    this._barchartEOptionsData.legend = barChartLegendData;
    this._barchartEOptionsData.xAxis = xAxis;
    this._barchartEOptionsData.yAxis = yAxis;
    this._barchartEOptionsData.tooltip = tooltip;

    this._barchartEOptionsData.grid = { left: 'center', right: '5%' };

    return this._barchartEOptionsData;
  }

  private buildPieChartOptions(): void {
    this._theme.getJsTheme()
      .pipe(take(1))
      .subscribe(config => {
        let echartsConfig: any = config.variables.echarts;
        this._piechartEOptionsData = {
          backgroundColor: echartsConfig.bg,
          color: [
            this.solidColor1,
            this.solidColor2,
            this.solidColor3,
            this.solidColor4
          ],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            textStyle: {
              color: echartsConfig.textColor
            }
          },
          series: [
            {
              name: 'Pie Series',
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: echartsConfig.itemHoverShadowColor
                }
              },
              label: {
                normal: {
                  textStyle: {
                    color: echartsConfig.textColor
                  }
                }
              },
              labelLine: {
                normal: {
                  lineStyle: {
                    color: echartsConfig.axisLineColor
                  }
                }
              }
            }]
        };
      });
  }

  private buildBarChartOptions(): void {
    this._theme.getJsTheme()
      .pipe(take(1))
      .subscribe(config => {
        let echartsConfig: any = config.variables.echarts;
        this._barchartEOptionsData = {
          backgroundColor: echartsConfig.bg,
          color: [
            this.color1,
            this.color2,
            this.color3,
            this.color4,
            this.color5,
            this.color6,
            this.color7,
            this.color8,
            this.color9,
            this.color10,
            this.color11
          ],
          dataZoom: [{
            type: 'inside'
          }, {
            type: 'slider'
          }],
        };
      });
  }
}
