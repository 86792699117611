export class ProjectedAttributes {
  public static readonly CustomerDetailAttributes: string[] = [
    'CustomerId',
    'Name',
    'SalesContact',
    'IsEnabled',
    'Demographics',
    'NotificationEmail',
    'NotificationEmailStatus',
    'MaxKioskLicenses',
    'LicensedKiosks',
    'Groups'
  ];

  // Note: For Kiosk* attributes that have "Current" at the beginning of the name, the actual
  // property that is populated by the service layer is the name without "Current" at the beginning
  // e.g. we pass "CurrentApplicationState" as a projected attribute
  // but "ApplicationState" will be populated by the service layer
  // Attributes requested for Kiosk Summary Grids
  public static readonly KioskSummaryAttributes: string[] = [
    'Authorization',
    'ApplicationState',
    'CurrentHardwareState',
    'CurrentConnectionState',
    'CustomerId',
    'Demographics',
    'GroupId',
    'KioskDescription',
    'KioskName',
    'KioskToken',
    'Offset',
    'SerialNumber',
    'TemplateId',
    'Inventory',
    'InventoryLastUpdated'
  ];

  // Attributes requested for Kiosk Sidebar
  public static readonly KioskSidebarAttributes: string[] = [
    'Authorization',
    'CurrentHardwareState',
    'CurrentConnectionState',
    'CustomerId',
    'GroupId',
    'KioskName',
    'KioskToken'
  ];

  // Attributes requested for User Summary
  public static readonly UserSummaryAttributes: string[] = [
    'CustomerId',
    'Email',
    'FirstName',
    'Groups',
    'LastName',
    'ParentUserId',
    'Phone',
    'UserId',
    'UserName',
    'IsEnabled'
  ];

  // Attributes for kiosk licenses
  public static readonly KioskLicenseAttributes: string[] = [
    'MaxKioskLicenses',
    'LicensedKiosks',
    'CertificateStatus'
  ];

  // Cash report attributes
  public static readonly CashLevelReportAttributes: string[] = [
    'KioskName',
    'SerialNumber',
    'CashLevels'
  ];
  public static readonly CashAcceptedReportAttributes: string[] = [
    'KioskName',
    'SerialNumber',
    'CashReceived'
  ];
  public static readonly CashDispensedReportAttributes: string[] = [
    'KioskName',
    'SerialNumber',
    'CashDispensed'
  ];

  public static readonly AdminActivityReportAttributes: string[] = [
    'KioskToken',
    'StartTimestamp',
    'KioskName',
    'Screens'
  ];

  public static readonly StrollerRemovalReportAttributes: string[] = [
    'KioskName',
    'CustomAnalyticData'
  ];
  public static readonly HeatmapReportAttributes: string[] = ['Screens'];
  public static readonly UptimeReportAttributes: string[] = [
    'Kiosk',
    'KioskName',
    'SerialNumber',
    'Address',
    'City',
    'State',
    'PercentOnline',
    'PercentOffline',
    'OnlineTotal',
    'OfflineTotal'
  ];
  public static readonly ScreenReportAttributes: string[] = [
    'Screens',
    'KioskName',
    'StartTimestamp',
    'ScreenTime',
    'SessionType'
  ];
}
