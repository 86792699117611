import { Injectable } from '@angular/core';
import { AWSService } from './aws.service';
import {
  AnalyticSessionType,
  AnalyticsQuery,
  AnalyticsQueryResponse
} from '@kiosk/microservice.analytics.models';
import { ConfigurationService } from './configuration.service';
import { LoggingService } from './logging.service';
import { Observable } from 'rxjs';
import { Analytics } from '../models/analytics.model';
import { PagingBaseService } from './paging.base.service';
import { take, switchMap } from 'rxjs/operators';
import { Configuration } from '../models/config.model';

@Injectable()
export class AnalyticsService extends PagingBaseService<AnalyticsQueryResponse> {
  public readonly errorMessageForNoDataFound = 'No Analytics Matching Criteria Found';

  constructor(
    protected _config: ConfigurationService,
    protected _aws: AWSService,
    protected _logger: LoggingService
  ) {
    super(_config, _aws, _logger);
  }

  public getAnalyticsDataByGroup(
    groupId: string,
    startDateTime: number,
    endDateTime: number,
    analyticSessionType: AnalyticSessionType = null,
    pageSize: number = 100,
    projectedAttributes: string[] = null
  ): Observable<AnalyticsQueryResponse[]> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap((config: Configuration) => {
        let request: AnalyticsQuery = new AnalyticsQuery();
        request.GroupId = groupId;
        request.Environment = config.Environment;
        request.StartDateTime = startDateTime;
        request.EndDateTime = endDateTime;
        if (projectedAttributes) {
          request.ProjectedAttributes = projectedAttributes;
        }
        if (analyticSessionType) {
          request.AnalyticSessionType = analyticSessionType;
        }
        request.QueryType = Analytics.AnalyticsByGroupQuery;
        let lambdaName = config.Lambdas.AnalyticsQuery;
        return this.getData(request, lambdaName, pageSize);
      })
    );
  }
}
