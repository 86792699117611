import { Injectable } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { Environment } from '@kiosk/microservice.common.models';
import { AWSService } from '../aws.service';
import { UserSessionService } from '../user-session.service';
import { DashboardData, WebPortalBusinessQuery, WebPortalBusinessQueryResponse } from 'app/models/business/classes';
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class DashboardService {
  constructor(private _aws: AWSService,
    private _userSession: UserSessionService,
    private _config: ConfigurationService,
    private _translateService: TranslateService) { }

  getAppStatusGraphConfig(
    echarts: any,
    counts: { running: number; stopped: number; unknown: number }
  ): Observable<any> {

    return this._translateService.getTranslation(this._translateService.currentLang)
      .pipe(take(1), map(translate => {
        return {
          backgroundColor: echarts.bg,
          // color: ['rgb(76, 150, 85)', 'rgba(150, 35, 35, 1)', 'rgb(76, 108, 150)'],
          color: ['rgba(175, 189, 33, 1)', 'rgba(150, 35, 35, 1)', 'rgb(71, 71, 71)'],

          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: [translate.Dashboard.Running
              , translate.Dashboard.Stopped,
            translate.Dashboard.Unknown],
            textStyle: {
              color: echarts.textColor
            }
          },
          series: [
            {
              name: translate.Dashboard.Applications,
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              data: [
                { value: counts.running, name: translate.Dashboard.Running },
                { value: counts.stopped, name: translate.Dashboard.Stopped },
                { value: counts.unknown, name: translate.Dashboard.Unknown }
              ],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: echarts.itemHoverShadowColor
                }
              },
              label: {
                normal: {
                  textStyle: {
                    color: echarts.textColor
                  }
                }
              },
              labelLine: {
                normal: {
                  lineStyle: {
                    color: echarts.axisLineColor
                  }
                }
              }
            }
          ]
        };
      }));
  }

  getHardwareStatusGraphConfig(
    echarts: any,
    counts?: { online: number; offline: number; warning: number; error: number; unknown: number }
  ): Observable<any> {

    let onlineComponentCount = counts.online;
    let offlineComponentCount = counts.offline;
    let warningComponentCount = counts.warning;
    let errorComponentCount = counts.error;
    let unknownComponentCount = counts.unknown;

    return this._translateService.getTranslation(this._translateService.currentLang)
      .pipe(take(1), map(translate => {
        return {
          backgroundColor: echarts.bg,
          // color: ['rgb(76, 150, 85)', 'rgba(150, 35, 35, 1)', 'rgb(227, 114, 34)', 'rgb(76, 108, 150)', 'rgb(71, 71, 71)'],
          color: [
            'rgba(175, 189, 33, 1)',
            'rgba(150, 35, 35, 1)',
            'rgb(227, 114, 34)',
            'rgb(76, 108, 150)',
            'rgb(71, 71, 71)'
          ],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: [translate.Dashboard.Online,
            translate.Dashboard.Error,
            translate.Dashboard.Warning,
            translate.Dashboard.Offline,
            translate.Dashboard.Unknown],
            textStyle: {
              color: echarts.textColor
            }
          },
          series: [
            {
              name: translate.Dashboard.Hardware,
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              data: [
                { value: onlineComponentCount, name: translate.Dashboard.Online },
                { value: errorComponentCount, name: translate.Dashboard.Error },
                { value: warningComponentCount, name: translate.Dashboard.Warning },
                { value: offlineComponentCount, name: translate.Dashboard.Offline },
                { value: unknownComponentCount, name: translate.Dashboard.Unknown }
              ],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: echarts.itemHoverShadowColor
                }
              },
              label: {
                normal: {
                  textStyle: {
                    color: echarts.textColor
                  }
                }
              },
              labelLine: {
                normal: {
                  lineStyle: {
                    color: echarts.axisLineColor
                  }
                }
              }
            }
          ]
        };
      }));
  }

  public getDashboardData(customerId: string, customerName: string, groupId: string = null, kioskToken: string = null): Observable<DashboardData> {

    let subject = new AsyncSubject<DashboardData>();

    if (!customerId && !groupId && !kioskToken) {
      subject.error("There was no supploed CustomerId, groupId, or KioskToken for filtering the data.");
      return subject.asObservable();
    }

    this._config.getConfiguration()
      .pipe(take(1),
        switchMap(config => {
          let query: WebPortalBusinessQuery = new WebPortalBusinessQuery();
          query.CustomerId = customerId;
          query.QueryType = "DashboardData";
          query.CustomerName = customerName;
          
          query.Environment = config.Environment;
          return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.PortalQuery);
        }))
      .subscribe(res => {
        const response = (res as WebPortalBusinessQueryResponse);
        if (response && response.SinglePayload) {
          subject.next(response.SinglePayload as DashboardData);
          subject.complete();
        } else {
          subject.error("Error with getting Dashboard Data");
        }
      },error => {
        subject.error(error);
      });

    return subject.asObservable();
  }
}
