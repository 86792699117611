import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeSpecialChars'
})
export class RemoveSpecialCharsPipe implements PipeTransform {
    transform(value: string): string {
        let result = value.replace(/[ .,!@#$%^&*()-]/gi, '');

        return result;
    }
}