import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { interval, merge, Observable, Subscription, of } from 'rxjs';
import { map, scan, take, switchMap, filter, takeUntil } from 'rxjs/operators';
import { User } from '@kiosk/microservice.user.models';
import { AuthenticationService } from 'app/services/authentication.service';
import { LoggingService } from 'app/services/logging.service';
import { UserService } from './pages/user.service';
import { CacheService } from './cache.service';
import { cacheSuffixes } from '../enums';
import { CurrentUserModel } from 'app/models/currentUser.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserSessionService implements OnDestroy {
  private cancellationToken: Subject<any> = new Subject();
  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  currentUser: CurrentUserModel = new CurrentUserModel();
  filterByCustomerId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private refreshDataInterval: number = 60; // seconds
  private resetRefreshDataTimer: Subject<number> = new Subject<number>();
  private refreshDataTimerSubscription: Subscription;

  refreshDataNow: Subject<boolean> = new Subject<boolean>();
  refreshingData: BehaviorSubject<[boolean, boolean]> = new BehaviorSubject<[boolean, boolean]>([
    false,
    false
  ]);
  private permissionSectionOptions: string[] = [
    'customer',
    'groups',
    'kiosk',
    'products',
    'product sets',
    'product category',
    'product batch upload',
    'reports',
    'template',
    'transaction',
    'user',
    'admin'
  ];

  constructor(
    private _auth: AuthenticationService,
    private _logger: LoggingService,
    private _userService: UserService,
    private _cache: CacheService,
    private _translateService: TranslateService
  ) {
    /** resettable observable interval
     * which determines when data should be
     * automatically refreshed across the site */
    this.refreshDataTimerSubscription = merge(
      interval(1000).pipe(map(() => 1)),
      this.resetRefreshDataTimer.pipe(
        map(() => 0),
        scan((acc, n) => (n === 0 ? 0 : acc + n))
      )
    ).subscribe(count => {
      if (count === this.refreshDataInterval) {
        // console.log('refresh data NOW');
        this.refreshDataNow.next();
        this.resetRefreshTimer();
      }
    });

    /** reset session data when logging out */
    _auth.loggingOut$.subscribe(loggingOut => {
      if (loggingOut) {
        this.currentUser$.next(undefined);
        this.setSiteFilter('');
      }
    });
  }

  ngOnDestroy(): void {
    this.cancellationToken.next();
    this.cancellationToken.complete();
    this.refreshDataTimerSubscription.unsubscribe();
  }

  /**@function startSpinner starts any combination of main and refresh button spinners
   * @param {boolean} spinMain
   * - determines whether main spinner should start spinning
   * - pass null to leave main spinner alone
   * - defaults to true
   * @param {boolean} spinCustRefresh
   * - determines whether customer select spinner should start spinning
   * - pass null to leave customer select spinner alone
   * - defaults to true
   */
  startSpinner(spinMain: boolean, spinCustRefresh: boolean): void {
    this.refreshingData.next([spinMain, spinCustRefresh]);
  }

  /**@function stopSpinner stops any combination of main and refresh button spinners
   * @param {boolean} spinMain
   * - determines whether main spinner should stop spinning
   * - pass null to leave main spinner alone
   * - defaults to true
   * @param {boolean} spinCustRefresh
   * - determines whether customer select spinner should stop spinning
   * - pass null to leave customer select spinner alone
   * - defaults to true
   */
  stopSpinner(stopSpinMain: boolean, stopSpinCustRefresh: boolean): void {
    this.refreshingData.next([
      stopSpinMain === null ? stopSpinMain : !stopSpinMain,
      stopSpinCustRefresh === null ? stopSpinCustRefresh : !stopSpinCustRefresh
    ]);
  }

  /**@function setSiteFilter sets customer id which the entire site filters by
   * @param {string} customerId customer id to filter by
   */
  setSiteFilter(customerId: string): void {
    this._cache.set(cacheSuffixes.currentCustomerId, customerId);
    this.filterByCustomerId$.next(customerId);
  }

  /**@function resetRefreshTimer resets timer which determines when data should be automatically refreshed */
  resetRefreshTimer(): void {
    this.resetRefreshDataTimer.next();
  }

  populateUser(): Observable<User> {
    return this._auth
      .getUserAttributes()
      .pipe(
        take(1),
        switchMap(attrs => this._userService.getUser(attrs.UserId, attrs.CustomerId)),
        switchMap(user => {
          this._logger.debug('UserSession: Found user for session');
          this.currentUser$.next(user);
          return of(user);
        }
      ));
  }

  private formatUserPermissions(userPermissions: any): any {
    const hasPermissions = {};

    for (let key in userPermissions) {
      if (userPermissions[key]) {
        let hasView = userPermissions[key].indexOf('View') >= 0,
          hasCreate = userPermissions[key].indexOf('Create') >= 0,
          hasUpdate = userPermissions[key].indexOf('Update') >= 0,
          hasDelete = userPermissions[key].indexOf('Delete') >= 0,
          hasAdmin = userPermissions[key].indexOf('Admin') >= 0;

        hasPermissions[`has_${key}_view`] = hasView;
        hasPermissions[`has_${key}_create`] = hasCreate;
        hasPermissions[`has_${key}_update`] = hasUpdate;
        hasPermissions[`has_${key}_delete`] = hasDelete;
        hasPermissions[`has_${key}_admin`] = hasAdmin;
      }
    }

    return hasPermissions;
  }

  getUserPermissions(): Observable<any> {
    return this.currentUser$.pipe(
      takeUntil(this.cancellationToken),
      filter(cUser => cUser !== undefined),
      map(currentUser => {
        if (currentUser) {
          this.currentUser.UserId = currentUser.UserId;
          this.currentUser.UserPermissions = this.formatUserPermissions(
            currentUser.Permissions.WebsitePermissions
          );
          return this.currentUser.UserPermissions;
        }
        return null;
      })
    );
  }

  getKioskPermissions(): Observable<string[]> {
    return this.currentUser$.pipe(
      take(1),
      filter(cUser => cUser !== undefined),
      map(currentUser => {
        const isUserDataCached =
          this.currentUser.KioskPermissions && currentUser.UserId === this.currentUser.UserId;

        if (!isUserDataCached) {
          this.currentUser.UserId = currentUser.UserId;
          this.currentUser.KioskPermissions = currentUser.Permissions.KioskPermissions;
        }

        return this.currentUser.KioskPermissions;
      })
    );
  }

  getWebsitePermissionsSections(): Observable<string[]> {
    return this._translateService.getTranslation(this._translateService.currentLang)
    .pipe(take(1),
      map(translate => {
        let newOption: string[] = [
          translate.Users.UserDetail.WebsitePermissionsOptions.Customer,
          translate.Users.UserDetail.WebsitePermissionsOptions.Groups,
          translate.Users.UserDetail.WebsitePermissionsOptions.Kiosk,
          translate.Users.UserDetail.WebsitePermissionsOptions.Products,
          translate.Users.UserDetail.WebsitePermissionsOptions.ProductSets,
          translate.Users.UserDetail.WebsitePermissionsOptions.ProductCategory,
          translate.Users.UserDetail.WebsitePermissionsOptions.ProductBatchUpload,
          translate.Users.UserDetail.WebsitePermissionsOptions.Reports,
          translate.Users.UserDetail.WebsitePermissionsOptions.Template,
          translate.Users.UserDetail.WebsitePermissionsOptions.Transaction,
          translate.Users.UserDetail.WebsitePermissionsOptions.User,
          translate.Users.UserDetail.WebsitePermissionsOptions.Admin,
        ];
        return newOption;
      }));
  }

  // get origin value of permissionSectionPotions
  getPermissionValue(permission: string): Observable<string> {
    return this._translateService.getTranslation(this._translateService.currentLang)
    .pipe(take(1),
      map(translate => {
        switch (permission) {
          case translate.Users.UserDetail.WebsitePermissionsOptions.Customer:
            return this.permissionSectionOptions[0];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Groups:
            return this.permissionSectionOptions[1];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Kiosk:
            return this.permissionSectionOptions[2];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Products:
            return this.permissionSectionOptions[3];
          case translate.Users.UserDetail.WebsitePermissionsOptions.ProductSets:
            return this.permissionSectionOptions[4];
          case translate.Users.UserDetail.WebsitePermissionsOptions.ProductCategory:
            return this.permissionSectionOptions[5];
          case translate.Users.UserDetail.WebsitePermissionsOptions.ProductBatchUpload:
            return this.permissionSectionOptions[6];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Reports:
            return this.permissionSectionOptions[7];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Template:
            return this.permissionSectionOptions[8];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Transaction:
            return this.permissionSectionOptions[9];
          case translate.Users.UserDetail.WebsitePermissionsOptions.User:
            return this.permissionSectionOptions[10];
          case translate.Users.UserDetail.WebsitePermissionsOptions.Admi:
            return this.permissionSectionOptions[11];
        }
        return '';
      }));
  }
}
