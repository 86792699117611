import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './authorization.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [{
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
      }, {
      path: 'login',
      component: LoginComponent,
      }, {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      }, {
        path: 'new-password/:user',
        component: NewPasswordComponent
      }, {
        path: 'new-password/:user/:change',
        component: NewPasswordComponent
      }, {
        path: 'reset-password',
        component: ResetPasswordComponent
      }, {
        path: 'reset-password/:user',
        component: ResetPasswordComponent
      }]
  }
];

export const routing = RouterModule.forChild(routes);