export class LogEntry {
    constructor(msg: string, level: LogLevel, stacktrace?: string) {
        let now = Date.now();

        this.timestamp = `${new Date(now).toLocaleDateString()} ${new Date(now).toLocaleTimeString()}`;
        this.msg = msg;
        this.level = LogLevel[level];
        this.stacktrace = stacktrace;
    }

    timestamp: string;
    msg: string;
    level: string;

    // any information to specify where issue / log occurred
    stacktrace: string;
}

export enum LogLevel {
    err = 0,
    warn = 1,
    info = 2,
    debug = 3,
    silly = 4
}