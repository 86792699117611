import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageCode } from "app/models/language-code.model";
import { Observable } from "rxjs";
import { CacheService } from "app/services/cache.service";
import { CultureService } from 'app/services/culture.service';


@Component({
    templateUrl: './language-select.component.html',
    selector: 'knect-language-select'
})
export class LanguageSelectComponent {

    langs$: Observable<LanguageCode[]> = this._culture.getLanguages();

    constructor(private _translate: TranslateService, private _culture: CultureService, private _cache: CacheService) {
    }

    setLanguage(lang: string): void {
        this._translate.use(lang);
        this._cache.set("UserLanguageSelection", lang);
    }

    getCurrentLang(): string {
        return this._translate.currentLang;
    }
}