import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { tap, mergeMap, filter, switchMap, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';

import { AuthenticationService } from 'app/services/authentication.service';
import { RegistrationService } from 'app/services/registration.service';
import { CustomerService } from 'app/services/pages/customer.service';
import { UserSessionService } from 'app/services/user-session.service';
import { CacheService } from 'app/services/cache.service';
import { cacheSuffixes } from 'app/enums/cacheKeys.enum';
import { User } from '@kiosk/microservice.user.models';
import { SidebarService } from 'app/services/sidebar.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input()
  position: string = 'normal';

  isLoggedIn: boolean = false;
  username: string;
  userMenu: { title: string; target: string }[];

  gettingCustomers: boolean = true;

  /** populate customer drop down options whenever customer data is changed */
  customerOptions$: Observable<
    { id: string; name: string }[]
  > = this._customerService.customerDataUpdated$.pipe(
    tap(() => this._userSessionService.startSpinner(null, true)),
    mergeMap(updated =>
      this._userSessionService.currentUser$.pipe(
        filter(cUser => cUser !== undefined),
        switchMap((currentUser: User) =>
          this._customerService
            .getCustomerData(currentUser.CustomerId, true, null, true)
            .pipe(switchMap(customers => this._customerService.customerOptions$))
        ),
        tap(() => this._userSessionService.stopSpinner(null, true))
      )
    )
  );

  /** based on current customer id, get current customer name from options list */
  currentCustomerName$: Observable<string> = this._userSessionService.filterByCustomerId$.pipe(
    filter(id => id && id !== ''),
    switchMap(id =>
      this.customerOptions$.pipe(map(options => _.filter(options, { id: id })[0].name))
    )
  );

  isCustSelectLoading$: Observable<boolean> = this._userSessionService.refreshingData.pipe(
    filter(spinnerBools => spinnerBools && spinnerBools[1] !== null),
    map(spinnerStates => spinnerStates[1])
  );

  isPageReloading: boolean = true;

  constructor(
    private _router: Router,
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private _auth: AuthenticationService,
    private _regService: RegistrationService,
    private _customerService: CustomerService,
    private _userSessionService: UserSessionService,
    private _cache: CacheService,
    public sidebar: SidebarService
  ) {}

  ngOnInit(): void {
    this._auth
      .getCurrentSession()
      .pipe(take(1))
      .subscribe(results => {
        this.isLoggedIn = true;
        this.username = results.user.getUsername();
        this.userMenu = [
          { title: 'Profile', target: '/pages/user/detail/current' },
          { title: 'Configuration', target: '/pages/user/config/current' },
          { title: 'Change password', target: `/auth/new-password` },
          { title: 'Log out', target: 'toggleLogin' }
        ];

        this.isPageReloading = false;
      });
  }

  getCurrentlyCachedCustomerId(): Observable<string> {
    return this._userSessionService.filterByCustomerId$.pipe(
      filter(id => id && id !== ''),
      take(1),
      switchMap(selectedCustomerId =>
        of((this._cache.get(cacheSuffixes.currentCustomerId) as string) || selectedCustomerId)
      )
    );
  }

  /** allows setting of the customer filter from the template */
  setCustomerFilter(customerId: string): void {
    this._userSessionService.setSiteFilter(customerId);
  }

  /** handle logic and navigation based on user menu click */
  onMenuItemClick(event: any): void {
    if (event.target === 'toggleLogin') {
      this.toggleLogin();
    } else if (event.target.indexOf('/auth/new-password') === 0) {
      this._router.navigate([event.target + '/' + this.username + '/change']);
    } else {
      this._router.navigate([event.target]);
    }
  }

  reloadPage(): void {
    if (!this.isPageReloading) {
      this.isPageReloading = true;
      window.location.reload();
    }
  }

  toggleLogin(): void {
    if (this.isLoggedIn) this._auth.logout();

    this._router.navigate(['./auth']);
  }

  toggleSidebar(): boolean {
    if (this.sidebar.isCollapsed) {
      this.sidebar.expand();
      this.sidebar.shouldBeCollapsed = false;
    } else {
      this.sidebar.collapse();
      this.sidebar.shouldBeCollapsed = true;
    }
    return false;
  }

  goToHome(): void {
    this.menuService.navigateHome();
  }
}
