import { Injectable } from '@angular/core';
import { KioskDetailGridData } from '../models/kioskGridData.model';
import { Hardware } from '../models/hardware.model';
import { ConnectionData } from '../models/connectionData.model';
import { User } from '@kiosk/microservice.user.models';
import { TransactionGridItem } from '../models/transactionGrid.model';
import { Product, ProductSet } from '@kiosk/microservice.product.models';
import { CategoryForm } from '../models/productCategory.model';
import * as _ from 'lodash';
import { KioskService } from './pages/kiosk.service';
import { ConnectionEvent, HardwareEvent } from '@kiosk/microservice.kiosk.models';
import { UtilityService } from './utility.service';
import { ScreenAnalysis } from '../pages/reporting/screen-analysis/screenAnalysis.model';
import { StrollerRemovalRow } from '../pages/reporting/customer-specific/stroller-removal/stroller-removal.model';
import { KioskUpTimeReport } from '@kiosk/microservice.reporting.models';
import { CashLevel } from '../pages/reporting/cashReports/cash-level.model';
import { AdminAnalytics } from 'app/pages/reporting/admin-analytics/admin-analytics.model';
import { ScreenUsage } from 'app/pages/reporting/screen-usage/screen-usage.model';
import { EventHistory } from 'app/pages/reporting/event-history/event-history.model';
import { KioskInventorySummaryModel } from 'app/models/kioskInventorySummary.model';
import { InventoryReportModel } from 'app/models/inventoryReport.model';
import { EmailRecord } from '@kiosk/microservice.emailrecords.models';
import { EmailHistory } from 'app/pages/reporting/email-report/email-history.model';
import { KioskApplication } from 'app/models/business/classes';

@Injectable()
export class CsvService {

  private readonly _delimiter: string = ',';
  private readonly _lineDelimiter: string = '\r\n';

  constructor(private readonly _kioskService: KioskService,
    private readonly _util: UtilityService) {
  }

  /**
   *
   * @param kiosks - the array of KioskGridData items to convert to csv
   * @returns - a string with the csv file contents
   */
  public kioskGridDataToCsv(kiosks: KioskDetailGridData[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,SerialNumber,Group,CurrentStatus,H/WStatus,AppStatus');
    // body of csv
    kiosks.forEach(data => {
      let line: string[] = [];
      line.push(this.formatString(data.KioskName));
      line.push(this.formatString(data.SerialNumber));
      line.push(this.formatString(data.GroupName));
      line.push(this.formatConnectionData(data.ConnectionData));
      line.push(this.formatHardware(data.Hardware));
      line.push(this.formatApplications(data.Applications));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param users - the array of User items to convert to csv
   * @returns - a string with the csv file contents
   */
  public userToCsv(users: User[]): string {
    let lines: string[] = [];
    // header row
    lines.push('FirstName,LastName,UserName,Email');
    // body of csv
    users.forEach(user => {
      let line: string[] = [];
      line.push(this.formatString(user.FirstName));
      line.push(this.formatString(user.LastName));
      line.push(this.formatString(user.UserName));
      line.push(this.formatString(user.Email));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param transactions - the array of TransactionGridItem items to convert to csv
   * @returns - a string with the csv file contents
   */
  public transactionToCsv(transactions: TransactionGridItem[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,TotalAmount,Currency,AccountId,TransactionType,TransactionDate,TimeZone');
    // body of csv
    transactions.forEach(transaction => {
      let line: string[] = [];
      line.push(this.formatString(transaction.KioskName));
      // need this if block because TotalAmount is a Number
      if (transaction.TotalAmount) {
        line.push(this.formatString(transaction.TotalAmount.toString()));
      } else {
        line.push('');
      }
      line.push(this.formatString(transaction.Currency));
      line.push(this.formatString(transaction.AccountId));
      line.push(this.formatString(transaction.Type));
      line.push(this.formatNumberToDateTime(transaction.TransactionDate));
      line.push(this.formatString(transaction.TimeZone));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param products - the array of IProduct items to convert to csv
   * @returns - a string with the csv file contents
   */
  public productToCsv(products: Product[]): string {
    let lines: string[] = [];
    // header row
    lines.push('ProductName,Vendor,UPC,Barcode,Active');
    // body of csv
    products.forEach(product => {
      let line: string[] = [];
      line.push(this.formatString(product.DisplayName));
      line.push(this.formatString(product.Vendor));
      line.push(this.formatString(product.UPC));
      line.push(this.formatString(product.Barcode));
      line.push(this.formatYesNoBool(product.IsActive));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param productSets - the array of IProductSet items to convert to csv
   * @returns - a string with the csv file contents
   */
  public productSetToCsv(productSets: ProductSet[]): string {
    let lines: string[] = [];
    // header row
    lines.push('ProductSetName,Description,Active');
    // body of csv
    productSets.forEach(productSet => {
      let line: string[] = [];
      line.push(this.formatString(productSet.DisplayName));
      line.push(this.formatString(productSet.SetDescription));
      line.push(this.formatYesNoBool(productSet.IsActive));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
 *
 * @param productCategories - the array of CategoryForm items to convert to csv
 * @returns - a string with the csv file contents
 */
  public productCategoryToCsv(categories: CategoryForm[]): string {
    let lines: string[] = [];
    // header row
    lines.push('Identifier,Name,Description');
    // body of csv
    categories.forEach(category => {
      let line: string[] = [];
      line.push(this.formatString(category.displayName));
      line.push(this.formatString(category.defaultName));
      line.push(this.formatString(category.defaultDescription));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param connEvents - the ConnectionEvents to be exported to csv
   * @returns - a string with the csv file contents
   */
  public kioskHealthToCsv(connEvents: ConnectionEvent[]): string {
    let lines: string[] = [];
    // header row
    lines.push('StatusChange,StatusChangeDate,ClientInitiatedDisconnect');
    // body of csv
    connEvents.forEach(connEvent => {
      let line: string[] = [];
      line.push(this.formatString(connEvent.EventType));
      line.push(this.formatNumberToDateTime(connEvent.Timestamp));
      line.push(this.formatYesNoBool(connEvent.ClientInitiatedDisconnect));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
 *
 * @param connEvents - the HardwareEvents to be exported to csv
 * @returns - a string with the csv file contents
 */
  public kioskHardwareToCsv(connEvents: HardwareEvent[]): string {
    let lines: string[] = [];
    // header row
    lines.push('HardwareName,HardwareType,Model,FirmwareVersion,Status,StatusChangeDate');
    // body of csv
    connEvents.forEach(hardwareEvent => {
      let line: string[] = [];
      line.push(this.formatString(hardwareEvent.ConfigurationName));
      line.push(this._kioskService.getHardwareType(hardwareEvent.Assembly).replace("'", ""));
      line.push(this.formatString(hardwareEvent.Model));
      line.push(this.formatString(hardwareEvent.FirmwareVersion));
      line.push(this.formatString(hardwareEvent.Status));
      line.push(this.formatNumberToDateTime(hardwareEvent.Timestamp));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param screenAnalyses - the AnalyticsDuration items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public screenAnalysisToCsv(screenAnalyses: ScreenAnalysis[]): string {
    let lines: string[] = [];
    // header row
    lines.push('StartTime,KioskName,SessionResult,EndTime,TotalTime,ScreenName,ScreenTime');
    // body of csv
    screenAnalyses.forEach(screen => {
      let line: string[] = [];
      line.push(this.formatDate(screen.StartTime));
      line.push(this.formatString(screen.KioskName));
      line.push(this.formatString(screen.SessionResult));
      line.push(this.formatDate(screen.EndTime));
      line.push(this.formatString(screen.TotalTime.toString()));
      line.push(this.formatString(screen.ScreenName));
      line.push(this.formatString(screen.ScreenTime.toString()));

      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  public screenUsageToCsv(screenUsages: ScreenUsage[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,SerialNumber,SessionsStarted,SessionsCanceled,SessionsCompleted,SessionErrors,SessionTimeouts');
    // body of csv
    screenUsages.forEach(screen => {
      let line: string[] = [];
      line.push(this.formatString(screen.KioskName));
      line.push(this.formatString(screen.SerialNumber));
      line.push(this.formatNumber(screen.SessionsStarted));
      line.push(this.formatNumber(screen.SessionsCanceled));
      line.push(this.formatNumber(screen.SessionsCompleted));
      line.push(this.formatNumber(screen.SessionsErrors));
      line.push(this.formatNumber(screen.SessionsTimeout));

      lines.push(line.join(this._delimiter));
    });

    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param strollerRemovalItems - the StrollerRemovalRow items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public strollerRemovalToCsv(strollerRemovalItems: StrollerRemovalRow[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,UserId,Reason,Type,Time,LockId,RFID');
    // body of csv
    strollerRemovalItems.forEach(srr => {
      let line: string[] = [];
      line.push(this.formatString(srr.KioskName));
      line.push(this.formatString(srr.UserId));
      line.push(this.formatString(srr.Reason));
      line.push(this.formatString(srr.Type));
      line.push(this.formatNumber(srr.Timestamp));
      line.push(this.formatString(srr.LockId));
      line.push(this.formatString(srr.RFID));

      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param cashLevels - the CashLevel items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public cashLevelToCsv(cashLevels: CashLevel[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,SerialNumber,$0.01,$0.05,$0.10,$0.25,$0.50,' +
      '$1.00,$5.00,$10.00,$20.00,$50.00,$100.00,TotalValue');
    // body of csv
    cashLevels.forEach(cashLevel => {
      let line: string[] = [];
      line.push(this.formatString(cashLevel.KioskName));
      line.push(this.formatString(cashLevel.SerialNumber));
      line.push(this.formatNumber(cashLevel.PennyTotalValue));
      line.push(this.formatNumber(cashLevel.NickelTotalValue));
      line.push(this.formatNumber(cashLevel.DimeTotalValue));
      line.push(this.formatNumber(cashLevel.QuarterTotalValue));
      line.push(this.formatNumber(cashLevel.FiftyCentTotalValue));
      line.push(this.formatNumber(cashLevel.DollarTotalValue));
      line.push(this.formatNumber(cashLevel.FiveDollarTotalValue));
      line.push(this.formatNumber(cashLevel.TenDollarTotalValue));
      line.push(this.formatNumber(cashLevel.TwentyDollarTotalValue));
      line.push(this.formatNumber(cashLevel.FiftyCentTotalValue));
      line.push(this.formatNumber(cashLevel.HundredDollarTotalValue));
      line.push(this.formatNumber(cashLevel.TotalValue));

      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param uptimes - the KioskUptimeReport items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public kioskUptimeToCsv(uptimes: KioskUpTimeReport[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,SerialNumber,Address,City,State,PercentOnline,PercentOffline,' +
      'OnlineTotal,OfflineTotal');
    // body of csv
    uptimes.forEach(uptime => {
      let line: string[] = [];
      line.push(this.formatString(uptime.KioskName));
      line.push(this.formatString(uptime.SerialNumber));
      line.push(this.formatString(uptime.Address));
      line.push(this.formatString(uptime.City));
      line.push(this.formatString(uptime.State));
      line.push(this.formatNumber(uptime.PercentOnline));
      line.push(this.formatNumber(uptime.PercentOffline));
      line.push(this.formatString(uptime.OnlineTotal));
      line.push(this.formatString(uptime.OfflineTotal));

      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
 *
 * @param adminActivities - the AdminActivity items to be exported to csv
 * @returns - a string with the csv file contents
 */
  public adminActivityToCsv(adminActivities: AdminAnalytics[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,SerialNumber,Username,Timestamp,ActionPerformed');
    // body of csv
    adminActivities.forEach(activity => {
      let line: string[] = [];
      line.push(this.formatString(activity.KioskName));
      line.push(this.formatString(activity.SerialNumber));
      line.push(this.formatString(activity.Username));
      line.push(this.formatString(activity.Timestamp));
      line.push(this.formatString(activity.ActionPerformed));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param events - the EventHistory items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public eventHistoryToCsv(events: EventHistory[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,Date,Type,Status,Component,Metadata');
    // body of csv
    events.forEach(event => {
      let line: string[] = [];
      line.push(this.formatString(event.Name));
      line.push(this.formatDate(event.Date));
      line.push(this.formatString(event.Type));
      line.push(this.formatString(event.Status));
      line.push(this.formatString(event.Component));
      line.push(this.formatString(event.Metadata));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  public emailRecordToCsv(emailHistorys: EmailHistory[]): string {
    let lines: string[] = [];
    // header row
    lines.push('EmailAddress,BouncedDate,EmailSubject');
    // body of csv
    emailHistorys.forEach(emailRecord => {
      let line: string[] = [];
      line.push(this.formatString(emailRecord.RecipientEmail));
      line.push(this.formatDate(emailRecord.Date));
      line.push(this.formatString(emailRecord.EmailSubject));
      lines.push(line.join(this._delimiter));
    });

    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param summaryModels - the KioskInventorySummaryModel items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public inventorySummaryToCsv(summaryModels: KioskInventorySummaryModel[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,Location,LockerCounts');
    // body of csv
    summaryModels.forEach(inventorySummaryModel => {
      let line: string[] = [];
      line.push(this.formatString(inventorySummaryModel.KioskName));
      line.push(this.formatString(inventorySummaryModel.Address));
      let summary = this.formatString(
        this._util.removeUlAndLiElementsFromText(inventorySummaryModel.LockerCountSummary));
      line.push(summary);
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param inventoryModels - the InventoryReportModel items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public inventoryReportToCsv(inventoryModels: InventoryReportModel[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,Location,Product,StockDate,LockerNumber,LockerMode,LockerSize');
    // body of csv
    inventoryModels.forEach(inventoryReportModel => {
      let line: string[] = [];
      line.push(this.formatString(inventoryReportModel.KioskName));
      line.push(this.formatString(inventoryReportModel.Address));
      line.push(this.formatString(inventoryReportModel.ProductIdName));
      line.push(this.formatDate(inventoryReportModel.LastUpdated));
      line.push(this.formatNumber(inventoryReportModel.UnitId));
      line.push(this.formatString(inventoryReportModel.LockerMode));
      line.push(this.formatString(inventoryReportModel.InventoryType.Name));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param emptyInventoryModels - the InventoryReportModel items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public emptyInventoryToCsv(emptyInventoryModels: InventoryReportModel[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,Location,LastInventoryDate,LockerMode,LockerSize');
    // body of csv
    emptyInventoryModels.forEach(inventoryReportModel => {
      let line: string[] = [];
      line.push(this.formatString(inventoryReportModel.KioskName));
      line.push(this.formatString(inventoryReportModel.Address));
      line.push(this.formatDate(inventoryReportModel.LastUpdated));
      line.push(this.formatString(inventoryReportModel.LockerMode));
      line.push(this.formatString(inventoryReportModel.InventoryType.Name));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  /**
   *
   * @param disableInventoryModels - the InventoryReportModel items to be exported to csv
   * @returns - a string with the csv file contents
   */
  public disabledInventoryToCsv(disabledInventoryModels: InventoryReportModel[]): string {
    let lines: string[] = [];
    // header row
    lines.push('KioskName,Location,DateDisabled,LockerMode,LockerSize');
    // body of csv
    disabledInventoryModels.forEach(inventoryReportModel => {
      let line: string[] = [];
      line.push(this.formatString(inventoryReportModel.KioskName));
      line.push(this.formatString(inventoryReportModel.Address));
      line.push(this.formatDate(inventoryReportModel.LastUpdated));
      line.push(this.formatString(inventoryReportModel.LockerMode));
      line.push(this.formatString(inventoryReportModel.InventoryType.Name));
      lines.push(line.join(this._delimiter));
    });
    return lines.join(this._lineDelimiter);
  }

  // private methods

  /**
   * This method will return empty string for undefined records and
   * replace commas in strings with empty string
   * @param str - a record in a csv file
   * @returns - the formatted string for the record
   */
  private formatString(str: string): string {
    if (!str) return '';
    return str.replace(/,/gi, '').replace(/\r/gi, '').replace(/\n/gi, '').trim();
  }

  /**
   * Returns empty string for undefined numbers, otherwise calls this.formatString on the number
   * @param num - the number to format
   */
  private formatNumber(num: number): string {
    if (!num) return '';
    return this.formatString(num.toString());
  }

  /**
   *
   * @param bool - the nullable bool to format
   * @returns - empty string is bool is undefined, or 'Yes'/'No'
   */
  private formatYesNoBool(bool: boolean): string {
    if (bool !== undefined) {
      return bool ? 'Yes' : 'No';
    } else {
      return '';
    }
  }

  /**
   * @param date - the date to format
   * @returns - a formatted string of the date
   */
  private formatDate(date: Date): string {
    return this.formatString(this._util.dateToString(date));
  }

  /**
   *
   * @param num - the number to format
   * @returns - a string in the same format as the _util method but with the comma removed
   */
  private formatNumberToDateTime(num: number): string {
    if (num !== undefined) {
      return this._util.unixTimestampToDateString(num).replace(",", '');
    } else {
      return '';
    }
  }

  /**
   *
   * @param connectionData - the ConnectionData to convert to a string for export to csv
   * @returns - a string for export to csv
   */
  private formatConnectionData(connectionData: ConnectionData): string {
    if (!connectionData || !connectionData.State) return '';
    if (connectionData.State !== "never") {
      return this.formatString(connectionData.State) + ' ' + this.formatString(connectionData.FormattedTimeStamp);
    } else {
      return 'yet to connect...';
    }
  }

  /**
   *
   * @param hardwares - the Hardware[] to convert to a string for export to csv
   * @returns - a string for export to csv
   */
  private formatHardware(hardwares: Hardware[]): string {
    let items: string[] = [];
    items.push('');
    let hwStatus = this._kioskService.getHardwareStatus(hardwares);
    let onlineCount = hwStatus.OnlineCount,
      offlineCount = hwStatus.OfflineCount,
      warningCount = hwStatus.WarningCount,
      errorCount = hwStatus.ErrorCount,
      unknownCount = hwStatus.UnknownCount;
    if (onlineCount > 0) items.push(onlineCount.toString() + ' online');
    if (offlineCount > 0) items.push(offlineCount.toString() + ' offline');
    if (warningCount > 0) items.push(warningCount.toString() + ' warning(s)');
    if (errorCount > 0) items.push(errorCount.toString() + ' error(s)');
    if (unknownCount > 0) items.push(unknownCount.toString() + ' unknown');
    return items.join(' ').trim();
  }

  /**
   *
   * @param applications - the KioskApplication[] to convert to a string for export to csv
   * @returns - a string for export to csv
   */
  private formatApplications(applications: KioskApplication[]): string {
    let items: string[] = [];
    items.push('');
    let appStatus = this._kioskService.getAppStatus(applications);
    //Running: running, Stopped: stopped, Unknown: unknown
    let runningCount = appStatus.Running,
      stoppedCount = appStatus.Stopped,
      unknownCount = appStatus.Unknown;
    if (runningCount > 0) items.push(runningCount.toString() + ' running');
    if (stoppedCount > 0) items.push(stoppedCount.toString() + ' stopped');
    if (unknownCount > 0) items.push(unknownCount.toString() + ' unknown');
    return items.join(' ').trim();
  }
}
