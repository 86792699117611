import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { LanguageCode } from '../models/language-code.model';
import { RegionLanguageCode } from '../models/region-language-code.model';
import { CurrencyCode } from '../models/currency-code.model';

@Injectable()
export class CultureService {

    constructor(private _http: HttpClient) {
    }

    public getLanguages(): Observable<LanguageCode[]> {
        return this._http.get<LanguageCode[]>('../../assets/json/language-codes.json');
    }

    public getRegionLanguages(): Observable<RegionLanguageCode[]> {
        return this._http.get<RegionLanguageCode[]>('../../assets/json/region-language-codes.json');
    }

    public getCurrencies(): Observable<CurrencyCode[]> {
        return this._http.get<CurrencyCode[]>('../../assets/json/currency-codes.json');
    }
}