import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { KioskInventory, InventoryLocation, InventoryQuery, InventoryQueryResponse } from '@kiosk/microservice.inventory.models';
import { KioskDetail, KioskDetailQuery, KioskDetailQueryResponse, Demographics } from '@kiosk/microservice.kiosk.models';
import {
  BaseReport,
  AggregateReportQuery,
  AggregateReportResponse
} from '@kiosk/microservice.reporting.models';
import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { LoggingService } from 'app/services/logging.service';
import { InventoryModel } from 'app/models/inventory.model';
import { ProductService } from './product.service';
import { UserSessionService } from '../user-session.service';
import { KioskInventorySummaryModel } from 'app/models/kioskInventorySummary.model';
import { InventoryReportModel } from 'app/models/inventoryReport.model';
import { Product } from '@kiosk/microservice.product.models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class InventoryService {
  constructor(
    private _aws: AWSService,
    private _configService: ConfigurationService,
    private _logger: LoggingService,
    private _productService: ProductService,
    private _userSession: UserSessionService,
    private _translate: TranslateService
  ) {}

  public getInventoryForGroup(groupId: string): Observable<KioskInventory[]> {
    return this._configService.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        const request = new KioskDetailQuery();
        request.GroupId = groupId;
        request.CustomerId = this._userSession.filterByCustomerId$.value;
        request.Environment = config.Environment;
        request.PageSize = 100;

        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.KioskQuery).pipe(
          switchMap((res: KioskDetailQueryResponse) => {
            if (res) {
              this._logger.debug(
                `Inventory: Successfully retrieved inventory data for group ${groupId}`
              );

              let groupInventory: KioskInventory[] = [];

              res.KioskDetails.forEach((kiosk: KioskDetail) => {
                if (
                  kiosk.Inventory &&
                  kiosk.Inventory.length > 0 &&
                  JSON.parse(kiosk.Inventory).Locations.length > 0
                ) {
                  let inventory = new KioskInventory();
                  inventory.KioskName = kiosk.KioskName;
                  inventory.KioskToken = kiosk.KioskToken;
                  inventory.Demographics = kiosk.Demographics;
                  inventory.Locations = JSON.parse(kiosk.Inventory).Locations;

                  groupInventory.push(inventory);
                }
              });

              return of(groupInventory);
            } else {
              this._logger.debug(
                `Inventory: Unable to retrieve inventory data for group ${groupId}`
              );

              return of([]);
            }
          })
        );
      })
    );
  }

  public getInventoryHistoryForGroup(
    groupId: string,
    startDate: number,
    endDate: number
  ): Observable<KioskInventory[]> {
    return this._configService.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        const request = new InventoryQuery();
        request.GroupId = groupId;
        request.StartDateTime = startDate;
        request.EndDateTime = endDate;
        request.Environment = config.Environment;
        request.PageSize = 100;
        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.InventoryQuery).pipe(
          switchMap((res: InventoryQueryResponse) => {
            if (res) {
              this._logger.debug(
                `Inventory: Successfully retrieved inventory data for group ${groupId}`
              );

              return of(res.GroupInventory || []);
            } else {
              this._logger.debug(
                `Inventory: Unable to retrieve inventory data for group ${groupId}`
              );

              return of([]);
            }
          })
        );
      })
    );
  }

  public getAggregateInventoryForGroup(
    groupId: string,
    start: number,
    end: number
  ): Observable<BaseReport[]> {
    return this._configService.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        const request = new AggregateReportQuery();
        request.GroupId = groupId;
        // request.ReportType = ReportTypes.LockerUsage;
        request.StartDateTime = start;
        request.EndDateTime = end;
        request.Environment = config.Environment;
        request.PageSize = 100;
        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.ReportingQuery).pipe(
          switchMap((res: AggregateReportResponse) => {
            if (res) {
              this._logger.debug(
                `Inventory: Successfully retrieved inventory data for group ${groupId}`
              );

              return of(res.Items || []);
            } else {
              this._logger.debug(
                `Inventory: Unable to retrieve inventory data for group ${groupId}`
              );

              return of([]);
            }
          })
        );
      })
    );
  }

  public GetInventoryForKiosk(kioskId: string, customerId: string): Observable<KioskInventory> {
    return this._configService.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        const payload = new InventoryQuery();
        payload.ConnectionId = kioskId;
        payload.CustomerId = customerId;
        payload.Environment = config.Environment;

        return this._aws.executeLambda(JSON.stringify(payload), config.Lambdas.InventoryQuery).pipe(
          switchMap((res: InventoryQueryResponse) => {
            if (res) {
              this._logger.debug(
                `Inventory: Successfully retrieved inventory data for ${kioskId}, ${customerId}`
              );

              return of(res.Inventory);
            } else {
              const inventory = new KioskInventory();

              this._logger.debug(
                `Inventory: Unable to retrieve inventory data for ${kioskId}, ${customerId}`
              );

              return of(inventory);
            }
          })
        );
      })
    );
  }

  createModelFromLocation(location: any): Observable<InventoryModel> {
    let model = new InventoryModel();
    model.IsEnabled = location.IsActive;
    model.LastUpdated = new Date(location.LastUpdatedDateUtc);
    model.LocationId = location.LocationId;
    model.UnitId = location.UnitId || location.LockId;

    if (location.InventoryType && location.InventoryType.Name) {
      model.InventoryType = location.InventoryType.Name;
    }

    if (location.ProductIds && location.ProductIds.length > 0 && location.ProductIds[0]) {
      return this._productService
        .getProduct(location.ProductIds[0], this._userSession.filterByCustomerId$.getValue())
        .pipe(
          take(1),
          map(product => {
            model.Product = product;
            return model;
          }),
          catchError((err: any, caught: Observable<InventoryModel>) => {
            model.Product = null;
            return of(model);
          })
        );
    } else {
      return of(model);
    }
  }

  createKioskSummaryReportModel(inventories: KioskInventory[]): KioskInventorySummaryModel[] {
    let models: KioskInventorySummaryModel[] = [];

    inventories.forEach((inventory: KioskInventory) => {
      let model = new KioskInventorySummaryModel();
      model.KioskToken = inventory.KioskToken;
      model.KioskName = inventory.KioskName;
      model.Address = inventory.Demographics
        ? this.demographicToString(inventory.Demographics)
        : '';
      model.Locations = inventory.Locations;

      // get arrays of unique lockers based on InventoryType.Name and LockerMetaData.lockerMode
      const uniqueMode = _.uniqBy(inventory.Locations, (loc: InventoryLocation) => {
        return loc.LocalMetaData && loc.LocalMetaData.length > 0
          ? JSON.parse(loc.LocalMetaData).lockerMode
          : null;
      });

      const uniqueSize = _.uniqBy(inventory.Locations, (loc: InventoryLocation) => {
        return loc.InventoryType.Name;
      });

      let countsHtmlList = '<ul>';

      // add counts of each mode / size combo as a list item for the counts cell
      uniqueSize.forEach((sizeLoc: InventoryLocation) => {
        const size = sizeLoc.InventoryType.Name;

        uniqueMode.forEach((filterLoc: InventoryLocation) => {
          const mode = filterLoc.LocalMetaData
            ? JSON.parse(filterLoc.LocalMetaData).lockerMode
            : '';
          const count = inventory.Locations.filter(loc => {
            const locMode = loc.LocalMetaData ? JSON.parse(loc.LocalMetaData).lockerMode : '';
            const locSize = loc.InventoryType ? loc.InventoryType.Name : '';
            return locMode === mode && locSize === size;
          }).length;

          if (size !== undefined && mode !== undefined && count !== 0) {
            countsHtmlList += `<li>${size} / ${mode} - ${count}</li>`;
          }
        });
      });

      if (countsHtmlList === '<ul>') countsHtmlList = '';
      else countsHtmlList += '</ul>';

      model.LockerCountSummary = countsHtmlList;

      models.push(model);
    });

    return models;
  }

  private demographicToString(demographics: Demographics): string {
    return `
    ${demographics.AddressLine1 || ''} ${demographics.AddressCity || ''},
     ${demographics.AddressRegion || ''} ${demographics.AddressPostalCode || ''}
     `;
  }

  createReportModelFromLocation(
    location: InventoryLocation,
    kioskToken: string,
    kioskName: string,
    kioskDemographics: Demographics
  ): Observable<InventoryReportModel> {
    let model = new InventoryReportModel();
    model.LastUpdated = new Date(location.LastUpdatedDateUtc);
    model.LocationId = location.LocationId;
    model.UnitId = location.UnitId;
    model.KioskToken = kioskToken;
    model.KioskName = kioskName;
    model.InventoryType = location.InventoryType;
    model.Address = kioskDemographics ? this.demographicToString(kioskDemographics) : '';

    if (location.LocalMetaData) {
      const metadata = JSON.parse(location.LocalMetaData);
      model.LockerMode = metadata.lockerMode || '';
      model.IsEnabled = metadata.isEnabled;
    } else {
      model.IsEnabled = false;
    }

    if (location.ProductIds && location.ProductIds.length > 0 && location.ProductIds[0]) {
      return this._productService
        .getProduct(location.ProductIds[0], this._userSession.filterByCustomerId$.getValue())
        .pipe(
          take(1),
          map(product => {
            model.Product = product;
            model.ProductIdName = `${product.ProductId} - ${product.DisplayName}`;
            return model;
          }),
          catchError((err: any, caught: Observable<InventoryReportModel>) => {
            model.Product = null;
            return of(model);
          })
        );
    } else {
      return of(model);
    }
  }

  /** @member gridSettings - settings for kiosk ng2 smart grids */
  gridSettings: any = {
    actions: false,
    columns: {
      UnitId: {
        title: 'Location Number',
        type: 'string',
        editable: false,
        sortDirection: 'asc'
      },
      IsActive: {
        title: 'Active',
        type: 'string',
        editable: false,
        filter: {
          type: 'list',
          config: {
            selectText: '- show all -',
            list: [{ value: 'true', title: 'Yes' }, { value: 'false', title: 'No' }]
          }
        },
        valuePrepareFunction: active => {
          return active ? 'Yes' : 'No';
        }
      },
      LastUpdated: {
        title: 'Last Updated',
        type: 'string',
        editable: false,
        filter: false,
        valuePrepareFunction: value => {
          let date = new Date(value);
          return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
        }
      },
      InventoryType: {
        title: 'Inventory Type',
        type: 'string',
        editable: false,
        filterFunction: (cell: any, search?: string) => {
          if (!search) return true;
          if (cell.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
          return false;
        }
      },
      Product: {
        title: 'Product',
        type: 'html',
        editable: false,
        compareFunction: (direction: any, a: any, b: any) => {
          if (a.DisplayName < b.DisplayName) {
            return -1 * direction;
          }
          if (a.DisplayName > b.DisplayName) {
            return direction;
          }
          return 0;
        },
        filterFunction: (cell: any, search?: string) => {
          let product = cell as Product;
          if (!search) return true;
          if (product.DisplayName.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
          return false;
        },
        valuePrepareFunction: value => {
          let productInfo = value as Product;
          if (!productInfo) {
            return;
          }
          if (productInfo.ImageInfo && productInfo.ImageInfo.ImageData) {
            return `<div><a href="#/pages/product/product-create/${
              productInfo.ProductId
            }" class="row"><div class="col-xs-4"><img src="${
              productInfo.ImageInfo.ImageData
            }" alt="${
              productInfo.ImageInfo.Description
            }"></div><div class="col-xs-offset-2 col-xs-6 display-name">${
              productInfo.DisplayName
            }</div></a></div>`;
          } else {
            return `<div><a href="#/pages/product/product-create/${
              productInfo.ProductId
            }" class="row"><div class="col mx-auto">${productInfo.DisplayName}</div></a></div>`;
          }
        }
      }
    }
  };

  public getGridSettings(): Observable<any> {
    return new Observable(observer => {
      let newGridSettings: any = {};
      Object.assign(newGridSettings, this.gridSettings);
      this._translate.getTranslation(this._translate.currentLang)
        .pipe(
          take(1),
          map(result => {
            if (result && result.Kiosks && result.Kiosks.KioskInventory) {
              if (result.Kiosks.KioskInventory.UnitId) {
                newGridSettings.columns.UnitId.title = result.Kiosks.KioskInventory.UnitId;
              }
              if (result.Kiosks.KioskInventory.IsActive) {
                newGridSettings.columns.IsActive.title = result.Kiosks.KioskInventory.IsActive;
              }
              if (result.Kiosks.KioskInventory.LastUpdated) {
                newGridSettings.columns.LastUpdated.title = result.Kiosks.KioskInventory.LastUpdated;
              }
              if (result.Kiosks.KioskInventory.InventoryType) {
                newGridSettings.columns.InventoryType.title = result.Kiosks.KioskInventory.InventoryType;
              }
              if (result.Kiosks.KioskInventory.Product) {
                newGridSettings.columns.Product.title = result.Kiosks.KioskInventory.Product;
              }
            }
            observer.next(newGridSettings);
          })).subscribe();
    });
  }
}
