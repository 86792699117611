import { ErrorHandler, Injectable } from '@angular/core';

import { LoggingService } from './logging.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(private _logger: LoggingService) {
        super();
    }

    handleError(error): void {
        // supressing this error as it only is thrown in dev mode based on how our spinner states change
        if (error && error.message && error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) return;

        if (error) {
            try {
                this._logger.err("Global Error Handler - unhandled error: ", error);
            } catch(err) {
                if (err) console.log(err);
            }
        }
    }
}