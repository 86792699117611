import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  ChangeDetectorRef,
  ErrorHandler
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthorizationModule } from '../pages/authorization/authorization.module';
import { KnectImageUploadModule } from './components/knect-image-upload/knect-image-upload.module';
import { KnectKioskSummaryTableModule } from './components/knect-kiosk-summary-table/knect-kiosk-summary-table.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from '../services/analytics.service';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigurationService } from '../services/configuration.service';
import { RouteGuardService } from '../services/guard.service';
import { LoggingService } from '../services/logging.service';
import { AWSService } from '../services/aws.service';
import { GlobalErrorHandler } from '../services/global.error.handler';
import { UtilityService } from 'app/services/utility.service';
import { GroupsService } from 'app/services/pages/groups.service';
import { CustomerService } from 'app/services/pages/customer.service';
import { CacheService } from 'app/services/cache.service';
import { TemplateService } from 'app/services/pages/template.service';
import { KioskService } from 'app/services/pages/kiosk.service';
import { UserService } from 'app/services/pages/user.service';
import { UserSessionService } from 'app/services/user-session.service';
import { KioskEventService } from 'app/services/kiosk-event.service';
import { SidebarService } from 'app/services/sidebar.service';
import { InventoryService } from 'app/services/pages/inventory.service';
import { ProductService } from 'app/services/pages/product.service';
import { ChartingService } from 'app/services/charting.service';
import { DashboardService } from 'app/services/pages/dashboard.service';
import { EmailRecordsService } from 'app/services/email-records.service';

const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  // ...NbAuthModule.forRoot({
  //   providers: {
  //     email: {
  //       service: NbDummyAuthProvider,
  //       config: {
  //         delay: 3000,
  //         login: {
  //           rememberMe: true,
  //         },
  //       },
  //     },
  //   },
  // }).providers,
  AnalyticsService,
  ConfigurationService,
  ChartingService,
  AuthenticationService,
  AWSService,
  RouteGuardService,
  LoggingService,
  UtilityService,
  GroupsService,
  CustomerService,
  CacheService,
  TemplateService,
  KioskService,
  KioskEventService,
  UserService,
  UserSessionService,
  InventoryService,
  ProductService,
  SidebarService,
  EmailRecordsService,
  DashboardService,
  ChangeDetectorRef,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  }
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    // NbAuthModule,
    AuthorizationModule,
    KnectImageUploadModule,
    KnectKioskSummaryTableModule
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders> {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS]
    };
  }
}
