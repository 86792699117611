import { Injectable } from '@angular/core';
import { Configuration } from '../models/config.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ConfigurationService {
    private _configuration: Configuration;

    constructor(private _http: HttpClient) { }

    getConfiguration(): Observable<Configuration> {
        if (this._configuration) {
            return of(this._configuration);
        } else {
            return this._http.get<Configuration>('../../assets/json/configuration.json').pipe(take(1),
                switchMap((data: Configuration) => {
                    this._configuration = data;
                    return of(data);
                }));
        }
    }
}
