import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">© Kiosk Information Systems<b><a href="https://kiosk.com" target="_blank"></a></b> {{year}}</span><span>Version: {{version}}</span>
  `,
})
export class FooterComponent implements OnInit {
  public year: string = new Date().getFullYear().toString();

  public version: string;

  ngOnInit(): void {
    let packageJson = require('../../../../../package.json');
    if (packageJson) {
      this.version = packageJson.version;
    }
  }
}
