import { NgModule } from '@angular/core';
import { KnectImageUploadComponent } from './knect-image-upload.component';
import { KnectImageUploadService } from './knect-image-upload.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslationLoaderFactory } from 'app/services/translate/kiosk-translate.loader';
import { HttpClient } from '@angular/common/http';
@NgModule({
  declarations: [KnectImageUploadComponent],
  exports: [KnectImageUploadComponent],
  providers: [KnectImageUploadService],
  imports:[    TranslateModule.forChild({
    loader:{
      provide: TranslateLoader,
      useFactory: TranslationLoaderFactory,
      deps: [HttpClient]
    }
  })]
})
export class KnectImageUploadModule {

}
