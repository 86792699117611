import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MessageType } from '@kiosk/microservice.common.models';
import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import {
  ProductQueryResponse,
  ProductSetQueryResponse,
  CategoryQueryResponse,
  ProductCommandResponse,
  ProductSetCommandResponse,
  CategoryCommandResponse,
  Product,
  ProductSet,
  Category,
  ProductQuery,
  ProductCommand,
  ProductCommandTypes,
  ProductSetQuery,
  ProductSetCommand,
  CategoryQuery,
  CategoryCommand
} from '@kiosk/microservice.product.models';
import { UtilityService } from '../utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap, map, take } from 'rxjs/operators';
import { of, merge } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProductService {
  constructor(
    private _aws: AWSService,
    private _config: ConfigurationService,
    private _util: UtilityService,
    private _sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) { }

  public getProducts(customerId: string): Observable<ProductQueryResponse> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let query = new ProductQuery();
        query.CustomerId = customerId;
        query.Environment = config.Environment;
        return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.ProductQuery)
          .pipe(take(1), map(response => response as ProductQueryResponse));
      }));
  }

  public getProduct(
    productId: string,
    customerId: string,
    allVersions = false
  ): Observable<Product> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {

        // If there is no product Id being passed then just retun null as its a creating a product, not updating
        if (!productId) {
          return of(null);
        }

        let query = new ProductQuery();
        query.CustomerId = customerId;
        query.Environment = config.Environment;
        query.GetAllVersionsOfProduct = allVersions;
        query.ProductId = productId;

        return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.ProductQuery)
          .pipe(take(1),
            map(response => {
              let product = (response as ProductQueryResponse).Products[0];
              return product;
            }));

      }));
  }

  public saveProductData(
    product: Product,
    customerId: string,
    msgType: MessageType
  ): Observable<ProductCommandResponse> {
    return this._config.getConfiguration()
      .pipe(
        take(1),
        switchMap(config => {
          let request = new ProductCommand();
          request.MessageType = msgType;
          request.Environment = config.Environment;
          request.CustomerId = customerId;
          request.Product = product;

          return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.ProductCommand)
            .pipe(take(1), map(response => response as ProductCommandResponse));
        }));
  }

  public updateProductTimeToLive(
    customerId: string,
    product: Product,
    timeToLive: number
  ): Observable<ProductCommandResponse> {
    return this._config.getConfiguration()
      .pipe(
        take(1),
        switchMap(config => {
          product.TimeToLive = timeToLive;

          let request = new ProductCommand();
          request.MessageType = MessageType.Update;
          request.Environment = config.Environment;
          request.CustomerId = customerId;
          request.Product = product;
          request.CommandType = ProductCommandTypes.TimeToLiveUpdate;

          return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.ProductCommand)
            .pipe(take(1), map(response => response as ProductCommandResponse));
        }));
  }

  public getProductSets(customerId: string): Observable<ProductSetQueryResponse> {
    return this._config.getConfiguration()
      .pipe(
        take(1),
        switchMap(config => {
          let query = new ProductSetQuery();
          query.CustomerId = customerId;
          query.Environment = config.Environment;

          return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.ProductSetQuery)
            .pipe(take(1), map(response => response as ProductSetQueryResponse));
        }));
  }

  public getProductSet(
    productSetId: string,
    customerId: string,
    allVersions = false
  ): Observable<ProductSet[]> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let query = new ProductSetQuery();
        query.CustomerId = customerId;
        query.Environment = config.Environment;
        query.GetAllVersionsOfProductSet = allVersions;
        query.ProductSetId = productSetId;

        return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.ProductSetQuery)
          .pipe(take(1), map(response => (response as ProductSetQueryResponse).ProductSets));
      }));
  }

  public saveProductSet(
    productSet: ProductSet,
    customerId: string,
    msgType: MessageType
  ): Observable<ProductSetCommandResponse> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let request = new ProductSetCommand();
        request.MessageType = msgType;
        request.Environment = config.Environment;
        request.CustomerId = customerId;
        request.ProductSet = productSet;

        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.ProductSetCommand)
          .pipe(take(1), map(response => response as ProductSetCommandResponse));
      }));
  }

  public getCategories(customerId: string, categoryId = ''): Observable<CategoryQueryResponse> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let query = new CategoryQuery();
        query.CustomerId = customerId;
        query.Environment = config.Environment;
        if (categoryId !== '') {
          query.CategoryId = categoryId;
        }

        return this._aws.executeLambda(JSON.stringify(query), config.Lambdas.ProductCategoryQuery)
          .pipe(take(1), map(response => response as CategoryQueryResponse));
      }));
  }

  public saveCategory(
    category: Category,
    customerId: string,
    msgType: MessageType
  ): Observable<CategoryCommandResponse> {
    return this._config.getConfiguration().pipe(
      take(1),
      switchMap(config => {
        let request = new CategoryCommand();
        request.MessageType = msgType;
        request.Environment = config.Environment;
        request.CustomerId = customerId;
        request.Category = category;

        return this._aws.executeLambda(JSON.stringify(request), config.Lambdas.ProductCategoryCommand)
          .pipe(take(1), map(response => response as CategoryCommandResponse));
      }));
  }

  public getSummaryGridSetting(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations;
        }
        let newGridSetting: any = {};
        Object.assign(newGridSetting, this.gridSettings);
        newGridSetting.columns.DisplayName.title = result.Products.Products.SmartTable.ProductName;
        newGridSetting.columns.Vendor.title = result.Products.Products.SmartTable.Vendor;
        newGridSetting.columns.UPC.title = result.Products.Products.SmartTable.UPC;
        newGridSetting.columns.Barcode.title = result.Products.Products.SmartTable.Barcode;
        newGridSetting.columns.IsActive.title = result.Products.Products.SmartTable.Active;
        subject.next(newGridSetting);
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;
  }

  public getProductSetSummaryGridSetting(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations;
        }
        let newGridSetting: any = {};
        Object.assign(newGridSetting, this.productSetGridSettings);
        newGridSetting.columns.DisplayName.title = result.Products.ProductSet.SmartTable.ProductSetName;
        newGridSetting.columns.SetDescription.title = result.Products.ProductSet.SmartTable.Description;
        newGridSetting.columns.IsActive.title = result.Products.ProductSet.SmartTable.Active;
        subject.next(newGridSetting);
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;
  }

  public getCategorySummaryGridSetting(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations;
        }
        let newGridSetting: any = {};
        Object.assign(newGridSetting, this.categoryGridSettings);
        newGridSetting.columns.displayName.title = result.Products.ProductCategories.SmartTable.Identifier;
        newGridSetting.columns.defaultName.title = result.Products.ProductCategories.SmartTable.Name;
        newGridSetting.columns.defaultDescription.title = result.Products.ProductCategories.SmartTable.Description;
        subject.next(newGridSetting);
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;   
  }


  // Product Grid Settings
  gridSettings = {
    actions: false,
    columns: {
      DisplayName: {
        title: 'Product Name',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      Vendor: {
        title: 'Vendor',
        type: 'string',
        editable: 'false',
        sort: false,
        compareFunction: this._util.nonCaseCompare
      },
      UPC: {
        title: 'UPC',
        type: 'string',
        editable: 'false',
        sort: false,
        compareFunction: this._util.nonCaseCompare
      },
      Barcode: {
        title: 'Barcode',
        type: 'string',
        editable: 'false',
        sort: false,
        compareFunction: this._util.nonCaseCompare
      },
      IsActive: {
        title: 'Active',
        type: 'string',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => (value ? 'Yes' : 'No')
      },
      ProductId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value =>
          this._sanitizer.bypassSecurityTrustHtml(`
            <div class="col-12 text-center" *ngIf="userPermissions?.has_products_create">
              <a class='table-detail-btn ion-android-open' title="details" href='#/pages/product/product-create/${value}'></a>
            </div>
        `)
      }
    }
  };

  productSetGridSettings = {
    actions: false,
    columns: {
      DisplayName: {
        title: 'Product Set Name',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      SetDescription: {
        title: 'Description',
        type: 'string',
        sort: false,
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      IsActive: {
        title: 'Active',
        type: 'string',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => (value ? 'Yes' : 'No')
      },
      ProductSetId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value =>
          this._sanitizer.bypassSecurityTrustHtml(`
            <div class="col-12 text-center">
              <a class='table-detail-btn ion-android-open' title="details" href='#/pages/product/product-set-create/${value}'></a>
            </div>
        `)
      }
    }
  };

  categoryGridSettings = {
    actions: false,
    columns: {
      displayName: {
        title: 'Identifier',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this._util.nonCaseCompare
      },
      defaultName: {
        title: 'Name',
        type: 'string',
        sort: false,
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      defaultDescription: {
        title: 'Description',
        type: 'string',
        filter: false,
        editable: 'false',
        compareFunction: this._util.nonCaseCompare
      },
      categoryId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value =>
          this._sanitizer.bypassSecurityTrustHtml(`
            <div class="col-12 text-center">
              <a class='table-detail-btn ion-android-open' title="details" href='#/pages/product/category-create/${value}'></a>
            </div>
        `)
      }
    }
  };
}
