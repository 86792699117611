import * as moment from 'moment';
import { KioskApplication } from './business/classes';
import { ConnectionData } from './connectionData.model';
import { Hardware } from './hardware.model';
import { HardwareEvent } from '@kiosk/microservice.systemmetrics.models';
import { PerformanceCounter } from './performanceCounter.model';
import { KioskDetail, ConnectionEvent } from '@kiosk/microservice.kiosk.models';
import { _sanitizeUrl } from '@angular/core/src/sanitization/url_sanitizer';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Injector } from '@angular/core';
import { appInjector } from 'app/pages/shared/app-injector.global';
import { KioskStage } from '@kiosk/microservice.kiosk.models';

export class KioskDetailGridData extends KioskDetail {

    private _sanitizer: DomSanitizer;

    RedirectUri: string;
    Address: string;
    ConnectionData: ConnectionData;
    SystemMetrics: PerformanceCounter[];
    Applications: KioskApplication[];
    Hardware: Hardware[];
    GroupName: string;
    CertificateStatus: string;
    Stage: string;
    KioskTokenLink: string;

    constructor(kiosk?: KioskDetail) {
        super();

        let injector: Injector = appInjector();
        this._sanitizer = injector.get(DomSanitizer);

        if (kiosk) {
            this.KioskName = kiosk.KioskName;
            this.KioskDescription = kiosk.KioskDescription;
            this.KioskToken = kiosk.KioskToken;
            this.KioskTokenLink = kiosk.KioskToken;
            this.SerialNumber = kiosk.SerialNumber;
            this.Address = this.formatAddressData(kiosk);
            this.ConnectionData = this.formatConnectionData(kiosk);
            this.SystemMetrics = this.formatPerformanceCounters(kiosk);
            this.Applications = this.formatApplicationData(kiosk);
            this.Hardware = this.formatHardwareData(kiosk);
            this.Authorization = kiosk.Authorization;
            this.Offset = kiosk.Offset;
            this.CertificateArn = kiosk.CertificateArn;
            this.PreSignedUrl = kiosk.PreSignedUrl;
            this.GroupId = kiosk.GroupId;
            this.CertificateStatus = kiosk.CertificateStatus;
            this.Stage = KioskStage[kiosk.KioskStage];
        }
    }

    verifyIsConnected(): boolean {
        if (this.ConnectionData && this.ConnectionData.State && this.ConnectionData.State.toLowerCase() === 'connected') {
            return true;
        }
        return false;
    }

    formatConnectionData(kiosk: KioskDetail): ConnectionData {
        let connection = new ConnectionData();

        if (kiosk.ConnectionState) {
            let parsedConnection: ConnectionEvent = JSON.parse(kiosk.ConnectionState) as ConnectionEvent;
            let connectionState = new ConnectionData();
            connectionState.State = (parsedConnection.EventType) ? parsedConnection.EventType : 'never';
            connection.State = connectionState.State;
            connection.Timestamp = parsedConnection.Timestamp;
            connection.IsConnected = connectionState.State.toLowerCase() === 'connected' ? true : false;
            connection.ClientInitiatedDisconnect =
                (parsedConnection
                    && parsedConnection.ClientInitiatedDisconnect)
                    ? true
                    : false;
            connection.FormattedTimeStamp = this.formatTimeStamp(connection.Timestamp);
        } else {
            connection.State = 'never';
        }

        return connection;
    }

    formatTimeStamp(unixMsTime: number): string {
        let seconds = unixMsTime / 1000;
        return moment.unix(Math.floor(seconds)).format('MM/DD/YY h:mm a');
    }

    formatApplicationData(kiosk: KioskDetail): KioskApplication[] {
        let formattedApplications = new Array<KioskApplication>();

        if (kiosk.ApplicationState) {
            formattedApplications = JSON.parse(kiosk.ApplicationState) as KioskApplication[];
            formattedApplications.forEach(application => {
                if(application.MetricImage) {
                    let imageHolder: Array<string> = application.MetricImage.split(',');
                    let safeImages: Array<SafeUrl> = new Array();

                    imageHolder.forEach(image => {
                        safeImages.push(this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ image));
                    });
                    application.SafeImages = safeImages;

                    if(!this.verifyIsConnected()) {
                        application.Status = 'Unknown';
                    }
                }
                application.FormattedTimestamp = this.formatTimeStamp(application.ProcessedTimestamp);
            });
        }

        return formattedApplications;
    }

    formatHardwareData(kiosk: KioskDetail): Hardware[] {
        let formattedHardware = new Array<Hardware>();

        if (kiosk.HardwareState) {
            let kioskHardware: HardwareEvent[] = JSON.parse(kiosk.HardwareState) as HardwareEvent[];

            kioskHardware.forEach(hardware => {
                if (hardware.ConfigurationName === 'NA') {
                    return;
                }

                let metadata;

                if (hardware.MetaData && hardware.MetaData !== '{}' && hardware.MetaData !== 'null') {
                    metadata = JSON.parse(hardware.MetaData);

                    if (metadata.Status) metadata.Status = metadata.Status.replace(/ /gi, ', ');
                }

                let newHardware = new Hardware();
                newHardware.InstanceName = hardware.ConfigurationName;
                newHardware.MetricName = hardware.Assembly ? hardware.Assembly : '';
                newHardware.Model = hardware.Model ? hardware.Model : '';
                newHardware.AssemblyVersion = hardware.AssemblyVersion ? hardware.AssemblyVersion : '';
                newHardware.FirmwareVersion = hardware.FirmwareVersion ? hardware.FirmwareVersion : '';
                newHardware.Status = hardware.Status ? hardware.Status : '';
                newHardware.Timestamp = hardware.Timestamp ? hardware.Timestamp : null;
                newHardware.FormattedTimeStamp = this.formatTimeStamp(newHardware.Timestamp);
                newHardware.LastRfid = (metadata && metadata.Rfid) ? metadata.Rfid : '';
                newHardware.NodeStatusString = (metadata && metadata.Status) ? metadata.Status : '';

                formattedHardware.push(newHardware);
            });
        }

        return formattedHardware;
    }

    formatPerformanceCounters(kiosk: KioskDetail): PerformanceCounter[] {
        let performanceCounters = new Array<PerformanceCounter>();

        if (kiosk.PerformanceCounters) {
            let perfCounter = JSON.parse(kiosk.PerformanceCounters);
            for (let counter in perfCounter) {
                if (perfCounter.hasOwnProperty(counter)) {
                    let perf = perfCounter[counter].M;
                    let convertedCounter = new PerformanceCounter();

                    switch (counter) {
                        case 'Thermal Zone Information': {
                            let temp = perf['Temperature'].M['Value'].N;
                            let timeStamp = perf['Temperature'].M['Timestamp'].N;
                            convertedCounter.Timestamp = timeStamp;
                            convertedCounter.FormattedTimeStamp = this.formatTimeStamp(timeStamp);
                            convertedCounter.Name = 'Temperature';
                            convertedCounter.Value = temp * 9 / 5 - 459.67;
                            convertedCounter.DisplayValue = convertedCounter.Value.toPrecision(5) + ` deg F`;
                            performanceCounters.push(convertedCounter);
                            break;
                        }
                        case 'PhysicalDisk': {
                            let disk = perf['% Usage'].M['Value'].N;
                            let timeStamp = perf['% Usage'].M['Timestamp'].N;
                            convertedCounter.Timestamp = timeStamp;
                            convertedCounter.FormattedTimeStamp = this.formatTimeStamp(timeStamp);
                            convertedCounter.Name = 'Physical Disk Space';
                            convertedCounter.Value = Number(disk);
                            convertedCounter.DisplayValue = convertedCounter.Value.toPrecision(4) + `% Used`;
                            performanceCounters.push(convertedCounter);
                            break;
                        }
                        case 'Memory': {
                            let disk = perf['Available MBytes'].M['Value'].N;
                            let timeStamp = perf['Available MBytes'].M['Timestamp'].N;
                            convertedCounter.Timestamp = timeStamp;
                            convertedCounter.FormattedTimeStamp = this.formatTimeStamp(timeStamp);
                            convertedCounter.Name = 'Available MBytes';
                            convertedCounter.Value = Number(disk);
                            convertedCounter.DisplayValue = convertedCounter.Value + ` MB`;
                            performanceCounters.push(convertedCounter);
                            break;
                        }
                        case '.NET CLR Memory': {
                            let committedBytes = perf['# Total committed Bytes'].Value;
                            break;
                        }
                    }
                }
            }
        }

        return performanceCounters;
    }

    formatAddressData(kiosk: KioskDetail): string {
        let fullAddress = null;

        if (!kiosk.Demographics) {
            fullAddress = '';
        } else if (kiosk.Demographics.AddressLine1 && kiosk.Demographics.AddressCity && kiosk.Demographics.AddressCountry) {
            fullAddress = kiosk.Demographics.AddressLine1;
            if (kiosk.Demographics.AddressLine2) {
                fullAddress += ', ' + kiosk.Demographics.AddressLine2;
            }
            fullAddress += ', ' + kiosk.Demographics.AddressCity;
            fullAddress += ', ' + kiosk.Demographics.AddressPostalCode;
            fullAddress += ', ' + kiosk.Demographics.AddressCountry;
        }

        return fullAddress;
    }
}
