import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthComponent } from './authorization.component';
import { LoginComponent } from './login/login.component';
import { routing } from './authorization.routing';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ThemeModule } from '../../@theme/theme.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatCheckboxModule } from '@angular/material';
import { RegistrationService } from '../../services/registration.service';
import { FormHelpersModule } from '../../directives/form-helpers/form-helpers.module';
import { LoginService } from 'app/services/login.service';

@NgModule({
  imports: [CommonModule, FormsModule, ThemeModule, routing, FormHelpersModule, MatCheckboxModule],
  declarations: [
    AuthComponent,
    LoginComponent,
    NewPasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent
  ],
  providers: [LoginService, RegistrationService]
})
export class AuthorizationModule {}
