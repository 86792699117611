import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { UserSessionService } from 'app/services/user-session.service';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { LoggingService } from 'app/services/logging.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { switchMap, tap, take, withLatestFrom } from 'rxjs/operators';
import { SidebarService } from './sidebar.service';
import { timer } from 'rxjs';
import { NbThemeService, NbMediaBreakpoint, NbMediaBreakpointsService } from '@nebular/theme';

@Injectable()
export class RouteGuardService implements CanActivate, CanActivateChild {
  lastRoute: string = '';
  initialNavigate: boolean = true;

  constructor(
    private _authService: AuthenticationService,
    private _userSession: UserSessionService,
    private _router: Router,
    private _logger: LoggingService,
    private _sidebar: SidebarService,
    private _nbTheme: NbThemeService,
    private _nbBreakpoints: NbMediaBreakpointsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.initialNavigate) {
      /** initial auth check done by login process */
      this.initialNavigate = false;
      return Promise.resolve(true);
    } else {
      return new Promise((resolve, reject) => {
        if (state.url === this.lastRoute || state.url.indexOf('/auth') > 0) resolve(true);
        else {
          this.lastRoute = state.url;
          this._userSession.startSpinner(true, null);
          this._authService
            .isSessionValid()
            .pipe(
              switchMap(valid => this._userSession.populateUser()),
              tap(
                () => {},
                err =>
                  resolve(this.handleError('RouteGuard: auth status not verified, routing to auth'))
              ),
              take(1)
            )
            .subscribe(
              user => resolve(this.handleSuccess('RouteGuard: auth status verified, navigating')),
              err =>
                resolve(
                  this.handleError('RouteGuard: error populating user session, routing to auth')
                ),
              () => {
                const lgBp = this._nbBreakpoints.getByName('lg');
                const mdBp = this._nbBreakpoints.getByName('md');
                const smBp = this._nbBreakpoints.getByName('sm');
                const isBp = this._nbBreakpoints.getByName('is');
                const xsBp = this._nbBreakpoints.getByName('xs');

                // handle side bar toggling to compact mode if needed after click navigation
                timer(500)
                  .pipe(
                    take(1),
                    withLatestFrom(this._nbTheme.onMediaQueryChange())
                  )
                  .subscribe(
                    ([timerDone, [bpFrom, bpTo]]: [
                      any,
                      [NbMediaBreakpoint, NbMediaBreakpoint]
                    ]) => {
                      const isCollapsed = this._sidebar.isCollapsed;
                      const shouldBeCollapsed = this._sidebar.shouldBeCollapsed;

                      if (!isCollapsed && shouldBeCollapsed) this._sidebar.collapse();
                      else if (shouldBeCollapsed) this._sidebar.collapse(true);
                      else if (
                        bpTo.width <= xsBp.width ||
                        bpTo.width <= isBp.width ||
                        bpTo.width <= smBp.width ||
                        bpTo.width <= mdBp.width ||
                        bpTo.width <= lgBp.width
                      ) {
                        this._sidebar.collapse();
                      }
                    }
                  );
              }
            );
        }
      });
    }
  }

  handleSuccess(logMsg: string): boolean {
    this._logger.debug(logMsg);
    this._userSession.stopSpinner(true, null);
    return true;
  }

  handleError(errorMsg: string): boolean {
    this._logger.debug(errorMsg);
    this._authService.logout();
    this._router.navigate(['./auth']);
    this._userSession.stopSpinner(true, null);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
