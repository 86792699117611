import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MessageType } from '@kiosk/microservice.common.models';
import {
  Customer,
  CustomersQuery,
  CustomersQueryResponse,
  CustomersCommand,
  CustomersCommandResponse,
  Group
} from '@kiosk/microservice.customers.models';

import { AWSService } from 'app/services/aws.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { LoggingService } from 'app/services/logging.service';
import { UtilityService } from 'app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { of, forkJoin, Subject, merge } from 'rxjs';
import { take, switchMap, tap, mergeMap, catchError, filter, exhaustMap } from 'rxjs/operators';
import { UserSessionService } from '../user-session.service';
import { ProjectedAttributes } from 'app/models/projectedAttributes.model';

@Injectable()
export class CustomerService {
  customerDataUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customerOptions$: Observable<{ id: string; name: string }[]>;
  groupOptions$: Observable<{ id: string; name: string }[]>;
  currentCustomer$: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);

  constructor(
    private aws: AWSService,
    private configService: ConfigurationService,
    private logger: LoggingService,
    private util: UtilityService,
    private sanitizer: DomSanitizer,
    private userSessionService: UserSessionService,
    private _translateService: TranslateService
  ) {
    this.userSessionService.filterByCustomerId$
      .pipe(
        filter(id => id && id.length > 0),
        exhaustMap(custId =>
          forkJoin(
            this.getCustomerData(custId, false, ProjectedAttributes.CustomerDetailAttributes),
            of(custId)
          )
        )
      )
      .subscribe(([customers, custId]: [Customer[], string]) => {
        this.setCurrentCustomer(customers, custId);
      });
  }

  /** logic for if the customer can activate an existing kiosk or create a new one as active */
  public canActivateAnotherKiosk(customer: Customer): boolean {
    let canDetermineLicenseStatus = customer.MaxKioskLicenses && customer.LicensedKiosks;
    if (!canDetermineLicenseStatus || customer.MaxKioskLicenses > customer.LicensedKiosks.length)
      return true;
    if (customer.MaxKioskLicenses > this.getUsedLicenseCount(customer)) {
      return true;
    }
    return false;
  }

  /** counts the number of kiosks with CertificateStatus.toUpperCase() === 'ACTIVE', returns 0 if customer.LicensedKiosks is null */
  public getUsedLicenseCount(customer: Customer): number {
    if (!customer.LicensedKiosks) return 0;
    let activeKioskCount = 0;
    for (let i = 0; i < customer.LicensedKiosks.length; ++i) {
      let curKiosk = customer.LicensedKiosks[i];
      if (curKiosk.CertificateStatus && curKiosk.CertificateStatus.toUpperCase() === 'ACTIVE') {
        ++activeKioskCount;
      }
    }
    return activeKioskCount;
  }

  /** @member customerGridSettings customer ng2 smart grid Settings */
  customerGridSettings: any = {
    actions: false,
    columns: {
      Name: {
        title: 'Name',
        type: 'string',
        editable: 'false',
        sortDirection: 'asc',
        compareFunction: this.util.nonCaseCompare
      },
      IsEnabled: {
        title: 'Enabled',
        type: 'string',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value => (value ? 'Yes' : 'No')
      },
      CustomerId: {
        title: '',
        type: 'html',
        filter: false,
        editable: 'false',
        valuePrepareFunction: value =>
          this.sanitizer.bypassSecurityTrustHtml(`
            <div class="text-right" *ngIf="userPermissions.has_customer_view">
              <a class='table-detail-btn ion-android-open' title="details" href='#/pages/customer/detail/${value}'></a>
            </div>
          `)
      }
    }
  };

  public getCustomerGridSettings(): Observable<any> {
    let subject: Subject<any> = new Subject<any>();

    merge(this._translateService.getTranslation(this._translateService.currentLang), this._translateService.onLangChange.asObservable())
      .subscribe(result => {
        if (result.translations) {
          result = result.translations; //let two types of observable will have same result
        }
        let newGridConfig: any = {};
        Object.assign(newGridConfig, this.customerGridSettings);
        if (result && result.Customers && result.Customers.CustomerSummary) {
          if (result.Customers.CustomerSummary.Name) {
            newGridConfig.columns.Name.title = result.Customers.CustomerSummary.Name;
          }
          if (result.Customers.CustomerSummary.Enabled) {
            newGridConfig.columns.IsEnabled.title =
              result.Customers.CustomerSummary.Enabled;
          }
          subject.next(newGridConfig);
        }
      }, error => {
        subject.error(error);
        subject.complete();
      });

    return subject;
  }

  /** @function getCustomerData
   * - sets observable array of customer objects (this.customers$) to retrieved customers based on passed in customerId
   * - also builds customer select option object array and sets to observable array of customer option objects (this.customerOptions$)
   * - also builds group select option object array and sets to observable array of group option objects (this.groupOptions$)
   * @param {string} customerId customer id string
   * @param {boolean} includeChildren
   * - optional, defaults to true
   * - include child customers as well as specified customer
   * @returns {Observable<Customer[]>} Customer array observable
   */
  getCustomerData(
    customerId: string,
    includeChildren: boolean = true,
    projectedAttributes: string[] = null,
    ignoreAsCurrentCustomer: boolean = false
  ): Observable<Customer[]> {
    return this.configService.getConfiguration().pipe(
      switchMap(config => {
        const payload = new CustomersQuery();
        payload.CustomerId = customerId;
        payload.IncludeChildren = includeChildren;
        payload.Environment = config.Environment;
        if (projectedAttributes) {
          payload.ProjectedAttributes = projectedAttributes;
        }

        return this.aws.executeLambda(JSON.stringify(payload), config.Lambdas.CustomersQuery).pipe(
          switchMap(res => {
            const customers = (res as CustomersQueryResponse).Customers;

            this.logger.debug(`Customer: Successfully retrieved customer data for ${customerId}`);

            return of(customers);
          }),
          tap(
            customers => {
              this.customerOptions$ = this.buildCustomerOptions(customers);
              if (customers.length > 0 && customers[0].Groups) {
                this.groupOptions$ = this.buildGroupOptions(customers[0].Groups);
              }

              if (!ignoreAsCurrentCustomer) {
                this.setCurrentCustomer(customers, customerId);
              }
            },
            err => this.logger.err(`Customer: Error getting customer data for ${customerId}`, err)
          )
        );
      })
    );
  }

  public getGroupsAndChildGroups(customerId: string): Observable<{ id: string; name: string }[]> {
    return this.configService.getConfiguration().pipe(
      switchMap(config => {
        const payload = new CustomersQuery();
        // because of the way the lambda works, need to pass only 'Groups' in ProjectedAttributes to get child customer groups
        payload.ProjectedAttributes = ['Groups'];
        payload.CustomerId = customerId;
        payload.IncludeChildren = true;
        payload.Environment = config.Environment;

        return this.aws.executeLambda(JSON.stringify(payload), config.Lambdas.CustomersQuery).pipe(
          switchMap(res => {
            const customers = (res as CustomersQueryResponse).Customers;

            this.logger.debug(
              `Customer: Successfully retrieved customer data (groups) for ${customerId}`
            );
            let allGroups = new Array<Group>();
            customers.forEach(cust => allGroups.push(...cust.Groups));
            return this.buildGroupOptions(allGroups);
          }),
          catchError(err => {
            this.logger.err(
              `Customer: Error getting customer data (groups) for ${customerId}`,
              err
            );
            return of([]);
          })
        );
      })
    );
  }

  private buildGroupOptions(groups: Group[]): Observable<{ id: string; name: string }[]> {
    let options: { id: string; name: string }[] = [];

    groups.forEach(group => options.push({ id: group.GroupId, name: group.Name }));

    options.sort((a, b) => this.util.nonCaseStringSort(a.name, b.name));

    return of(options);
  }

  private buildCustomerOptions(customers: Customer[]): Observable<{ id: string; name: string }[]> {
    let options = [];

    customers.forEach(customer => options.push({ id: customer.CustomerId, name: customer.Name }));

    options.sort((a, b) => this.util.nonCaseStringSort(a.name, b.name));

    return of(options);
  }

  private setCurrentCustomer(customers: Customer[], customerIdToFind: string): void {
    let currentCustomer = customers.find(c => c.CustomerId === customerIdToFind);
    if (currentCustomer) {
      this.currentCustomer$.next(currentCustomer);
    }
  }

  createCustomer(customer: Customer): Observable<CustomersCommandResponse> {
    this.logger.debug(`Customer: Creating customer ${customer.CustomerId}`);
    this.logger.silly(' => ', customer);
    return this.createOrUpdateCustomer(customer, MessageType.Create);
  }

  updateCustomer(customer: Customer): Observable<CustomersCommandResponse> {
    this.logger.debug(`Customer: Updating customer ${customer.CustomerId}`);
    this.logger.silly(' => ', customer);
    return this.createOrUpdateCustomer(customer, MessageType.Update);
  }

  /**@function createOrUpdateCustomer
   * - creates a new, or updates an existing, user
   * @param {Customer} customer customer to create or update
   * @param {MessageType} messageType type of message (MessageType.Update or MessageType.Create)
   * @returns {Observable<CustomersCommandResponse>} Observable of CustomersCommandResponse
   */
  createOrUpdateCustomer(
    customer: Customer,
    messageType: MessageType
  ): Observable<CustomersCommandResponse> {
    const payload = new CustomersCommand();
    payload.Customer = customer;
    payload.MessageType = messageType;

    return this.configService.getConfiguration().pipe(
      take(1),
      tap((config: any) => (payload.Environment = config.Environment)),
      mergeMap((config: any) =>
        this.aws.executeLambda(JSON.stringify(payload), config.Lambdas.CustomersCommand)
      ),
      tap((res: CustomersCommandResponse) => {
        /** tells customer select dropdown in header to update */
        this.customerDataUpdated$.next(true);
      }),
      switchMap((response: CustomersCommandResponse) => {
        this.logger.debug(
          `Customer: Successfully ${
          payload.MessageType === MessageType.Create ? 'Created' : 'Updated'
          } customer ${response.Customer.CustomerId}`
        );
        return of(response);
      })
    );
  }
}
