import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LoginService } from 'app/services/login.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { RegistrationService } from 'app/services/registration.service';
import { UserSessionService } from 'app/services/user-session.service';
import { LoggingService } from '../../../services/logging.service';
import { switchMap, tap } from 'rxjs/operators';
import { CacheService } from 'app/services/cache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'knect-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoggingIn: boolean = false;
  user: any = {};
  errors: string = '';
  showCaseMsg: boolean = false;
  saveUsername: boolean = false;

  private readonly saveUsernameCheck: string = 'saveUsername';
  private readonly userName: string = "userName";


  constructor(
    private _login: LoginService,
    private _router: Router,
    private _authService: AuthenticationService,
    private _registration: RegistrationService,
    private _userSession: UserSessionService,
    private _logger: LoggingService,
    private _cache: CacheService,
    private _translate: TranslateService
  ) { }

  ngOnInit(): void {
    if(this._cache.get(this.saveUsernameCheck)) {
      this.saveUsername = true;
      this.user.username = this._cache.get(this.userName);
    }
   }

  login(): void {
    this.errors = '';
    this.isLoggingIn = true;
    this.showCaseMsg = false;

    if(this.saveUsername) {
      this._cache.set(this.userName, this.user.username);
      this._cache.set(this.saveUsernameCheck, this.saveUsername);
    } else {
      this._cache.remove(this.userName);
      this._cache.remove(this.saveUsernameCheck);
    }

    this._login.authenticate(this.user.username, this.user.password)
      .then((response) => {
        this.authCallback(response.message, response.result);
      })
      .catch((err) => {
        // show additional message in the possibility of improper username case
        if (err === 'User does not exist.') this.showCaseMsg = true;
        this.errors = err;
        this.isLoggingIn = false;
      });
  }

  authCallback(message: string, result: any): void {
    if (message) {

      // this._router.navigate(['/auth/new-password/' + this.user.username])
      if (message === 'newPasswordRequired') {
        this._router.navigate(['/auth/new-password/' + this.user.username]);
      } else if (message === 'resetPasswordRequired') {
        this._router.navigate(['/auth/reset-password/' + this.user.username]);
      } else {
        this._authService.currentUser = result;

        // Research for Identity integration
        // let creds = this._authService.buildCognitoCreds(result.getIdToken().getJwtToken());
        // AWS.config.credentials = creds;

        // let clientParams:any = {};
        // let sts = new STS({ region: 'us-west-2' });
        // sts.getCallerIdentity(function (err, data) {
        //     console.log("UserLoginService: Successfully set the AWS credentials");
        //     console.log(data);
        // });

        this._authService.isSessionValid()
          .pipe(switchMap(valid => this._userSession.populateUser()), tap(() => {}, err => {
            this._logger.err('Login: auth status not verified', err);
            this.errors = err;
          }))
          .subscribe(
            user => {
              this._logger.debug('Login: auth status verified, navigating');
              this._router.navigate(['./pages/dashboard']);

              if (user.PortalSettings && user.PortalSettings.DefaultLanguage) {
                this._cache.set('UserLanguageSelection', user.PortalSettings.DefaultLanguage);
                this._translate.use(user.PortalSettings.DefaultLanguage);
              }
            },
            err => {
              this._logger.err('Login: error populating user session, routing to auth', err);
              this.errors = err;
            });
      }
    }
  }

  adminTestUser(): void {
    this._registration.adminCreateUser('parmentrout', 'parmentrout@kiosk.com', '2b0e28d0-0112-47ed-aab9-50ab8ba54140')
      .then((value) => {
          console.log(value);
        })
      .catch((error) => {
          console.log(error);
      });
  }
}
