export class Hardware {
    InstanceName: string;
    MetricName: string;
    AssemblyVersion: string;
    FirmwareVersion: string;
    Model: string;
    Status: string;
    Timestamp: number;
    FormattedTimeStamp: string;
    NodeStatusString: string = '';
    LastRfid: string = '';
}
