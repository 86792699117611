import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from 'app/services/registration.service';

@Component({
  selector: 'knect-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  username: string;
  errors: string = '';
  submitInProcess: boolean = false;

  constructor(
    private _regService: RegistrationService,
    private _router: Router) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.submitInProcess = true;

    // this._regService.forgotPassword(this.username).subscribe(
    //   response => {
    //     this.submitInProcess = false;
    //     this._router.navigate([`/auth/reset-password/${this.username}`]);
    //   },
    //   err => {
    //     this.submitInProcess = false;
    //     this.errors = err;
    //   }
    // );

    this._regService.resetPassword(this.username).then(result => {
      this.submitInProcess = false;
      this._router.navigate(['/auth/reset-password/' + this.username]);
    }).catch(err => {
      this.submitInProcess = false;
      this.errors = err.toString();
    });
  }

}
