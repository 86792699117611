import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [{provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true}]
})
export class EqualValidatorDirective implements Validator {
    constructor(@Attribute('validateEqual') public validateEqual: string,
    @Attribute('reverse') public reverse: string) {}

    private get isReverse(): boolean {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true : false;
    }
    validate(control: AbstractControl): {[key: string]: any} {
        let v = control.value;

        let ele = control.root.get(this.validateEqual);

        // value not equal and not reverse
        if (ele && v !== ele.value && !this.isReverse) return { validateEqual: false };

        // reverse
        if (ele && v === ele.value && this.isReverse) {
            delete ele.errors['validateEqual'];
            if (!Object.keys(ele.errors).length) ele.setErrors(null);
        }

        if (ele && v !== ele.value && this.isReverse) {
            ele.setErrors({validateEqual: false});
        }

        return null;
    }
}
