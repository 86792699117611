import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnectKioskSummaryTableComponent } from './knect-kiosk-summary-table.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslationLoaderFactory } from 'app/services/translate/kiosk-translate.loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    Ng2SmartTableModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [KnectKioskSummaryTableComponent],
  exports: [KnectKioskSummaryTableComponent]
})
export class KnectKioskSummaryTableModule {

}
