import { Component, OnInit, Input } from '@angular/core';
import { ProductImage } from '@kiosk/microservice.product.models';
import { KnectImageUploadService } from './knect-image-upload.service';

@Component({
  selector: 'knect-image-upload',
  templateUrl: './knect-image-upload.component.html',
  styleUrls: ['./knect-image-upload.component.scss']
})
export class KnectImageUploadComponent implements OnInit {
  @Input() title: string = '';

  errorMessage: string = '';
  maxImageSize: number = 240000;

  constructor(
    private _imageUploadService: KnectImageUploadService
  ) { }

  ngOnInit(): void {
  }

  onImageUpload(event: any): void {
    const reader = new FileReader();
    const files = event.target.files;

    if (files && files.length > 0 && files[0].type.indexOf('image/') !== -1) {
      const image = files[0];

      if (image.size > this.maxImageSize) {
        this._imageUploadService.setError('Error: Image is too large, must be smaller than 240KB');
        this._imageUploadService.setImage(null);
      } else {
        this._imageUploadService.setError('');
        reader.readAsDataURL(image);
        reader.onload = () => {
          const newImage = new ProductImage();
          newImage.Description = image.name;
          (newImage.ImageData as any) = reader.result;

          this._imageUploadService.setImage(newImage);
        };
      }
    }
  }

}
