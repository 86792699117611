import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from 'aws-sdk';
import { AuthenticationService, AuthCallback } from 'app/services/authentication.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { Credentials } from 'aws-sdk/lib/credentials';
import { LoggingService } from 'app/services/logging.service';

@Injectable()
export class LoginService {

    constructor(private _auth: AuthenticationService,
                private _config: ConfigurationService,
                private _logger: LoggingService) {
                    let pausTest = '';
                }

    authenticate(username: string, password: string): Promise<AuthCallback>  {
        this._logger.debug("Login: starting authentication");

        let callback = new AuthCallback();
        let authenticationData = {
            Username: username,
            Password: password,
        };

        let self = this;

        return new Promise((resolve, reject) => {
            self._config.getConfiguration().subscribe(config => {
                let authenticationDetails = new AuthenticationDetails(authenticationData);
                let poolData = {
                    UserPoolId : config.UserPoolId,
                    ClientId: config.AppClientId
                };

                let userPool = new CognitoUserPool(poolData);
                let userData = {
                    Username: username,
                    Pool: userPool
                };

                let cognitoUser = new CognitoUser(userData);

                cognitoUser.authenticateUser(authenticationDetails, {
                    newPasswordRequired: (userAttributes, requiredAttributes) => {
                        callback.message = 'newPasswordRequired';
                        resolve(callback);
                    },
                    onSuccess: (result) => {
                        callback.message = 'success';
                        callback.result = result;

                        let idTokenJwt = result.getIdToken().getJwtToken();
                        AWS.config.credentials = self._auth.buildCognitoCreds(idTokenJwt, config);
                        AWS.config.region = config.Region;

                        // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                        (AWS.config.credentials as Credentials).refresh((error) => {
                            if (error) {
                                self._logger.err("Login: Error refreshing credentials during login", error);
                            } else {
                                self._logger.debug('Login: Successfully logged in!');
                            }
                        });

                        self._logger.debug('Login: Successfully authenticated cognito user');

                        resolve(callback);
                    },
                    onFailure: (err) => {
                        if (err && err.code === "PasswordResetRequiredException") {
                            self._logger.err('Login: Password reset is required', err);
                            callback.message = 'resetPasswordRequired';
                            resolve(callback);
                        } else {
                            self._logger.err('Login: Failed to authenticate cognito user', err);
                            reject(err.message);
                        }
                    }
                });
            });
        });
    }


}