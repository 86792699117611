import { Demographics } from "@kiosk/microservice.customers.models";
import { KioskDetail } from "@kiosk/microservice.kiosk.models";
import { TaxRate, Cash } from "@kiosk/microservice.common.models";

export class KioskForm {
  public KioskName: string;
  public SerialNumber: string;
  public Description: string;
  public Template: string;
  public Customer: string;
  public CertificateStatus: string;
  public Stage: string;
  public Demographics: Demographics;
  public TaxRates: TaxRate[];

  public GroupId: string;
  public KioskToken: string;
  public ApplicationState: any;
  public Authorization: string;
  public ConnectionState: any;
  public Inventory: string;

  public HardwareState: any;
  public PerformanceCounters: any;
  public CertificateArn: string;
  public InventoryLastUpdated: number;
  public PolicyName: string;
  public CashLevels: Cash[];
  public CashReceived: Cash[];
  public CashDispensed: Cash[];
  public OriginalCashLevels: Cash[];
  public Offset?: number;

  constructor(kiosk: KioskDetail) {
    this.KioskName = kiosk.KioskName;
    this.SerialNumber = kiosk.SerialNumber;
    this.Description = kiosk.KioskDescription;
    this.Template = kiosk.TemplateId;
    this.Customer = kiosk.CustomerId;
    this.CertificateStatus = kiosk.CertificateStatus;
    this.Stage = kiosk.KioskStage;
    this.Demographics = kiosk.Demographics;
    this.TaxRates = kiosk.TaxRates;
    this.GroupId = kiosk.GroupId;
    this.KioskToken = kiosk.KioskToken;
    this.ApplicationState = kiosk.ApplicationState;
    this.ConnectionState = kiosk.ConnectionState;
    this.Authorization = kiosk.Authorization;
    this.Inventory = kiosk.Inventory;
    this.HardwareState = kiosk.HardwareState;
    this.PerformanceCounters = kiosk.PerformanceCounters;
    this.CertificateArn = kiosk.CertificateArn;
    this.InventoryLastUpdated = kiosk.InventoryLastUpdated;
    this.PolicyName = kiosk.PolicyName;
    this.CashLevels = kiosk.CashLevels;
    this.CashReceived = kiosk.CashReceived;
    this.CashDispensed = kiosk.CashDispensed;
    this.OriginalCashLevels = kiosk.OriginalCashLevels;
    this.Offset = kiosk.Offset;
  }
}