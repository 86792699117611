import { Injectable } from '@angular/core';
import { PagingBaseService } from './paging.base.service';
import { 
    EmailRecordsQuery,
    EmailRecordsQueryResponse } from '@kiosk/microservice.emailrecords.models';
import { ConfigurationService } from './configuration.service';
import { LoggingService } from './logging.service';
import { AWSService } from './aws.service';
import { Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { Configuration } from 'app/models/config.model';

@Injectable()
export class EmailRecordsService extends PagingBaseService<EmailRecordsQueryResponse> {
    private readonly pageSize: number = 100;

    constructor(
        protected _config: ConfigurationService,
        protected _logger: LoggingService,
        protected _aws: AWSService
    ) {
        super(_config, _aws, _logger);
    }

    public getEmailRecords = (query: EmailRecordsQuery): Observable<EmailRecordsQueryResponse[]> => {
        return this._config.getConfiguration().pipe(
            take(1),
            switchMap((config: Configuration) => {
                query.Environment = config.Environment;
                let lambdaName = config.Lambdas.EmailRecordsQuery;
                return this.getData(query, lambdaName, this.pageSize);  
            })
        );
    }
}