import { Injectable } from '@angular/core';
import { LoggingService } from 'app/services/logging.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class UtilityService {
  constructor(private _logger: LoggingService) {}

  public timeSpanStringToMinutes(timeSpanString: string): number {
    let spanParts = timeSpanString.split(':'),
      hours = Number(spanParts[0]),
      minutes = Number(spanParts[1]);

    if (hours) minutes += hours * 60;

    this._logger.silly(`Utility: Converted timespan ${timeSpanString} to ${minutes} minutes`);

    return minutes;
  }

  public minutesToTimeSpanString(minutes: number): string {
    let hours: string = '00';

    if (minutes >= 60) {
      hours = this.zeroFill(Math.floor(minutes / 60));
      minutes = minutes % 60;
    }

    let timeString = `${hours}:${this.zeroFill(minutes)}:00`;

    this._logger.silly(`Utility: Converted ${minutes} minutes to timespan ${timeString}`);

    return timeString;
  }

  // quick and dirty clone... change to better later perhaps
  public clone(toClone: any): any {
    return JSON.parse(JSON.stringify(toClone));
  }

  // case insensitive sort function for smart table sorting
  public nonCaseCompare(direction: any, a: string, b: string): number {
    let first = typeof a === 'string' ? a.toLowerCase() : a;
    let second = typeof b === 'string' ? b.toLowerCase() : b;

    if (first < second) return -1 * direction;
    if (first > second) return direction;
    return 0;
  }

  public nonCaseStringSort(a: string, b: string): number {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  }

  // sorts kiosk connection state strings for smart table sorting
  public kioskConnectionCompare(direction: any, a: any, b: any): number {
    let first =
        a.State.toLowerCase() === 'connected'
          ? -1
          : a.State.toLowerCase() === 'disconnected'
          ? 1
          : 0,
      second =
        b.State.toLowerCase() === 'connected'
          ? -1
          : b.State.toLowerCase() === 'disconnected'
          ? 1
          : 0;

    if (first < second) return -1 * direction;
    if (first > second) return direction;
    return 0;
  }

  /** Converts the unix timestamp number to a Date object */
  public unixTimestampToDate(num: number): Date {
    return new Date(num);
  }

  /**
   *
   * @param - date the date to convert
   * @returns - a string in the format M/dd/YYYY hh:mm:ss [AM|PM]
   */
  public dateToString(date: Date): string {
    if (!date) return '';
    return moment(date).format('MM/DD/YYYY HH:mm:ss A');
  }

  /**
   *
   * @param num - the number to convert
   * @returns - a string in the format M/dd/YYYY hh:mm:ss [AM|PM]
   */
  public unixTimestampToDateString(num: number): string {
    if (!num || num === null) return '';
    let date = this.unixTimestampToDate(num);
    return this.dateToString(date);
  }

  /**
   * Converts a single word title case, e.g "ACTIVE" to "Active"
   */
  public wordToTitleCase(str: string): string {
    if (!str || str === null) return '';
    const firstChar = str.charAt(0).toUpperCase();
    const rest = str.substring(1).toLowerCase();
    return firstChar + rest;
  }

  /**
   * Removes '<ul>', '</ul>', '<li>' and '</li>' from the string and replaces the closing
   * </li> tag with a single space
   */
  public removeUlAndLiElementsFromText(str: string): string {
    if (!str) return '';
    return str.replace(/<ul>/gi, '').replace(/<\/ul>/gi, '')
    .replace(/<li>/gi, '').replace(/<\/li>/gi, ' ').trim(); // intentionally adding a space here
  }

  // private methods

  /** returns number as a string with a 0 prefix if needed to maintain two digits
   */
  private zeroFill(number: number): string {
    const numString = number.toString(),
      paddingNeeded = numString.length < 2;

    this._logger.silly(`Utility: Performing zero fill on ${number}`);

    if (paddingNeeded) {
      const paddingAmount = numString.length === 1 ? 1 : 2;
      return new Array(paddingAmount + (/\./.test(numString) ? 2 : 1)).join('0') + numString;
    } else return numString;
  }
}
